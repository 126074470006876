import React from 'react';

import { ProfileDetail } from 'components/blocks/Profile';


const DetailScreen = (props) => {
  return (
    <ProfileDetail/>
  )
};

export default DetailScreen;
