import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton, DateTimeText } from 'components/common';
import { SiteValue, SiteSelect } from 'components/blocks/Site';
import SyncPublicationValue from "../SyncPublicationValue";
import SyncPublicationSteps from '../SyncPublicationSteps';


const SyncPublicationList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, syncPublication }) => {
  const defaultColumnList = ['id', 'name', 'steps', 'date_start', 'date_end', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Статус', 'status', {
      render: (val, item) => (
        <div>
          <SiteValue detail={item.site} asLink={false} isBack={true}/><br/>
          <SyncPublicationValue detail={item} site_id={item?.site?.id} asLink={true} isBack={true}/>
          {item.error_count > 0 && <div style={{marginTop: '5px', fontSize: '12px'}}>Кол-во ошибок: {item.error_count}</div>}
        </div>
      ),
      sorter: true,
    }),
    steps: tableColumn('Этапы', 'steps', {
      render: (val, item) => <SyncPublicationSteps detail={item}/>,
      sorter: false,
      width: 300,
    }),
    error_count: tableColumn('Кол-во ошибок', 'error_count', {
      sorter: false,
    }),
    date: tableColumn('Дата', 'date_start', {
      render: (val, item) => (
        <div>
          <div style={{marginBottom: '5px'}}>
            Начало: <DateTimeText date={val}/>
          </div>
          <div>
            Звершение: <DateTimeText date={item.date_end}/>
          </div>
        </div>
      ),
      sorter: true,
    }),
    date_start: tableColumn('Дата начала', 'date_start', {
      render: (val, item) => <DateTimeText date={val}/>,
      sorter: true,
    }),
    date_end: tableColumn('Дата завершения', 'date_end', {
      render: (val, item) => <DateTimeText date={val}/>,
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.syncPublication}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = syncPublication.filter.activeFilter
    }
    actions.syncPublication.listLoad({ 
      query, activeFilter, locationChange: true, locationState: router.location.state
    });
  }, [syncPublication, actions, router.location.state]);

  return (
    <TableList
      location={router.location}
      model={syncPublication}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}

      filterFields={{
        list: [
          'site', 'status'
        ],
        byName: {
          'site': {
            label: 'Сайт',
            type: 'select_int',
            component: SiteSelect,
            valueComponent: SiteValue,
          },
          'status': {
            label: 'Статус',
            type: 'select',
            options: [
              { label: 'инициализация', value: 'init' },
              { label: 'в очереди', value: 'queued' },
              { label: 'в процессе', value: 'process' },
              { label: 'завершено', value: 'complete' },
              { label: 'ошибка', value: 'error' },
            ]
          },
        }
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  syncPublication: state.syncPublication,
});

const mapDispatch = dispatch => ({
  actions: {
    syncPublication: dispatch.syncPublication,
  }
});

export default connect(mapState, mapDispatch)(SyncPublicationList);
