import React, { Fragment } from 'react';
import { Route } from 'react-router';

import ServerListScreen from './ServerListScreen';
import ServerDetailScreen from './ServerDetailScreen';


const Server = () => {
  return (
    <Fragment>
      <Route exact path="/server" component={ServerListScreen}/>
      <Route exact path="/server/:serverId" component={ServerDetailScreen}/>
      <Route exact path="/server/:serverId/backup" component={ServerDetailScreen}/>
    </Fragment>
  );
}

export default Server;
