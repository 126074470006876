import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Steps, Progress } from "antd";

import { syncPublicationDetailLoad, syncPublicationListLoad } from "store/models/syncPublication";
import { DetailLoadComponent } from 'components/common';

import styles from './value.module.css';

const STATUS_INIT = 'init';
const STATUS_QUEUED = 'queued';
const STATUS_PROCESS = 'process';
const STATUS_COMPLETE = 'complete';
const STATUS_ERROR = 'error';

const STEPS_STATUS = {
  [STATUS_INIT]: 'wait',
  [STATUS_QUEUED]: 'wait',
  [STATUS_PROCESS]: 'process',
  [STATUS_COMPLETE]: 'finish',
  [STATUS_ERROR]: 'error',
}

const STEP_STATUS = {
  [STATUS_INIT]: 'normal',
  [STATUS_PROCESS]: 'active',
  [STATUS_COMPLETE]: 'success',
  [STATUS_ERROR]: 'exception',
}

const TIMEOUT_INTERVAL = 1000;

const Value = ({ id, item }) => {
  if (item && item.id) {
    const status = STEPS_STATUS[item.status];

    return (
      <div className={styles.container}>
        <Steps direction="vertical" size="small" current={item.steps.length-1} status={status}>
          {item.steps.map((step, idx) => {
            const percent = step.count > 0 ? step.completed_count / step.count * 100: 0;
            const stepStatus = STEP_STATUS[step.status];

            return (
              <Steps.Step 
                key={`step{step.id}`} 
                title={step.name} 
                subTitle={`Измен.: ${step.changed_count} Созд.: ${step.created_count} Удал.: ${step.deleted_count}`} 
                description={
                  <div>
                    <Progress 
                      percent={percent} 
                      status={stepStatus} 
                      size="small"
                      format={percent => `${step.completed_count} из ${step.count}`}
                    />
                  </div>
                }
              />
            )
          })}
        </Steps>
      </div>
    );
  }

  return (
    <div className={styles.container}>{id}: -</div>
  );
}

const SyncPublicationSteps = (props) => {
  const [detail, setDetail] = useState({});

  useEffect(() => {
    setDetail(props.detail);
  }, [props.detail]);

  useEffect(() => {
    let isActive = true;

    if (detail && detail.site && detail.status !== STATUS_COMPLETE && detail.status !== STATUS_ERROR) {
      const loadData = () => {
        const activeFilter = {
          site: detail.site.id,
          limit: 1,
        };
        syncPublicationListLoad(props.authToken, { activeFilter: activeFilter})
          .then(({ error, data }) => {
            if (isActive) {
              const item = data && data.results ? data.results[0]: null;
              if (item) {
                setDetail(item)
              }

              if (!item || (item && item.status !== STATUS_COMPLETE && item.status !== STATUS_ERROR)) {
                setTimeout(() => {
                  loadData();
                }, TIMEOUT_INTERVAL);
              }
            }
          });
      }

      loadData();
    }
    return () => {
      isActive = false;
    }
  }, [detail, props.authToken]);

  return (
    <DetailLoadComponent
      apiLoad={syncPublicationDetailLoad}
      {...props}
      detail={detail}
      render={({ id, item, linkPath }) => {
        return <Value id={id} item={item}/>;
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.syncPublication,
});

export default connect(mapState)(SyncPublicationSteps);
