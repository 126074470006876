import React from 'react';
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { Tabs } from 'antd';

const HeaderTabs = ({ basePath, tabs, actions, router, onTabChange }) => {
  const onChange = (key) => {
    if (onTabChange) {
      onTabChange(key);
    } else {
      const url = key === 'detail' ? basePath : `${basePath}/${key}`;
      actions.router.push(url);
    }
  };

  const path = router.location.pathname.replace(basePath, '');
  const activeTab = path === '' ? 'detail' : path.substr(1);

  return (
    <Tabs className="header-tabs" activeKey={activeTab} onChange={onChange}>
      {tabs.map(item =>
        <Tabs.TabPane
          tab={(<div>{item.title} {item.count !== undefined && <span>{item.count}</span>}</div>)}
          key={item.key}
        />
      )}
    </Tabs>
  );
};

const mapState = state => ({
  router: state.router,
});

const mapDispatch = dispatch => ({
  actions: {
    router: {
      push: (payload) => dispatch(push(payload)),
    },
  }
});

export default connect(mapState, mapDispatch)(HeaderTabs);
