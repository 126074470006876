import React from 'react';
import { connect } from 'react-redux';

import { Page, Fieldset } from 'components/layout';
import { ServerList, ServerForm } from 'components/blocks/Server';


const ServerListScreen = ({ history, server, actions }) => {
  return (
    <Page
      title="Серверы"
      modelActions={actions.server}
      breadcrumbs={[
        { title: 'Серверы', path: server.urls.list() },
      ]}
      actions={[
        { mode: 'button', name: 'add', type: 'primary', title: 'Добавить сервер' },
      ]}
    >
      <Fieldset>
        <ServerList/>
      </Fieldset>

      <ServerForm/>
    </Page>
  );
};

const mapState = state => ({
  server: state.server,
});

const mapDispatch = dispatch => ({
  actions: {
    server: dispatch.server,
  }
});

export default connect(mapState, mapDispatch)(ServerListScreen);
