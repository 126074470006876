import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined, } from "@ant-design/icons";

import { Fieldset } from "components/layout";
import { SiteNavList, SiteNavForm } from 'components/blocks/SiteNav';


const SiteNavListScreen = ({ match, actions }) => {
  const siteId = match.params.siteId;

  const navAdd = () => {
    actions.siteNav.showEditForm({ 
      show: true, 
      object: { site: parseInt(siteId) } 
    });
  };

  return (
    <Fieldset>
      <SiteNavList 
        activeFilter={{ site: siteId }}
        topActions={[
          <Button 
            type="primary" 
            icon={<PlusOutlined/>} onClick={navAdd}
          >
            Добавить навигацию
          </Button>
        ]}
      />
      <SiteNavForm/>
    </Fieldset>
  )
};

const mapState = state => ({
  siteNav: state.siteNav,
});

const mapDispatch = dispatch => ({
  actions: {
    siteNav: dispatch.siteNav,
  }
});

export default connect(mapState, mapDispatch)(SiteNavListScreen);
