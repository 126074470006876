import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import { TemplateForm } from 'components/blocks/Template';
import { DistrictForm } from 'components/blocks/District';
import DetailScreen from './DetailScreen';


const TemplateDetailScreen = ({ history, template, actions, match }) => {
  useEffect(() => {
    actions.template.detailLoad({ id: match.params.templateId });
  }, [match.params.templateId, actions.template]);

  const id = match.params.templateId;
  const detail = template.detail || {};

  return (
    <Page
      isLoading={template.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.template}
      breadcrumbs={[
        { title: 'Сайты', path: template.urls.list() },
        { title: detail.name, path: template.urls.detail(id) },
      ]}
      actions={[
        {
          mode: 'group',
          actions: [
            { mode: 'button', name: 'edit' },
            { mode: 'button', name: 'delete' },
          ]
        },
      ]}
      basePath={template.urls.detail(id)}
    >
      <Route exact path="/template/:templateId" component={DetailScreen}/>

      <TemplateForm/>
      <DistrictForm/>
    </Page>
  );
};

const mapState = state => ({
  template: state.template,
});

const mapDispatch = dispatch => ({
  actions: {
    template: dispatch.template,
  }
});

export default connect(mapState, mapDispatch)(TemplateDetailScreen);
