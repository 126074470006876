import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const PasswordChangeForm = (props) => {
  const { showPasswordForm } = props.user;

  const onClose = () => {
    props.actions.user.showPasswordForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.user.update(data);
  };

  return (
    <Form
      title="Смена пароля"
      model={props.user}
      visible={showPasswordForm}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  user: state.user,
});

const mapDispatch = dispatch => ({
  actions: {
    user: dispatch.user,
  }
});

export default connect(mapState, mapDispatch)(PasswordChangeForm);
