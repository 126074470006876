import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import { CityForm } from 'components/blocks/City';
import { DistrictForm } from 'components/blocks/District';
import DetailScreen from './DetailScreen';


const CityDetailScreen = ({ history, city, actions, match }) => {
  useEffect(() => {
    actions.city.detailLoad({ id: match.params.cityId });
  }, [match.params.cityId, actions.city]);

  const id = match.params.cityId;
  const detail = city.detail || {};

  return (
    <Page
      isLoading={city.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.city}
      breadcrumbs={[
        { title: 'Сайты', path: city.urls.list() },
        { title: detail.name, path: city.urls.detail(id) },
      ]}
      actions={[
        {
          mode: 'group',
          actions: [
            { mode: 'button', name: 'edit' },
            { mode: 'button', name: 'delete' },
          ]
        },
      ]}
      basePath={city.urls.detail(id)}
    >
      <Route exact path="/city/:cityId" component={DetailScreen}/>

      <CityForm/>
      <DistrictForm/>
    </Page>
  );
};

const mapState = state => ({
  city: state.city,
});

const mapDispatch = dispatch => ({
  actions: {
    city: dispatch.city,
  }
});

export default connect(mapState, mapDispatch)(CityDetailScreen);
