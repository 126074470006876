import React from 'react';
import { Input } from "antd";

import { FormItem } from 'utils';
import { CitySelect } from 'components/blocks/City';
import { DomainSelect } from 'components/blocks/Domain';
import { TemplateSelect } from 'components/blocks/Template';


const MainFieldSet = (props) => {
  return (
    <div className="form-block">
      <CitySelect {...props} name="city" label="Город" required={true}/>

      <DomainSelect {...props} name="domain" label="Домен" required={true}/>

      <TemplateSelect {...props} name="template" label="Шаблон" required={true}/>

      <FormItem {...props} name="name" label="Название" required={true}>
        <Input size="large"/>
      </FormItem>
    </div>
  );
};

export default MainFieldSet;
