const ProfileChoices = {};

ProfileChoices.NATIONALITY = [
  ['afghan', 'Afghan'],
  ['albanian', 'Albanian'],
  ['algerian', 'Algerian'],
  ['american', 'American'],
  ['andorran', 'Andorran'],
  ['angolan', 'Angolan'],
  ['antiguans', 'Antiguans'],
  ['argentinean', 'Argentinean'],
  ['armenian', 'Armenian'],
  ['australian', 'Australian'],
  ['austrian', 'Austrian'],
  ['azerbaijani', 'Azerbaijani'],
  ['bahamian', 'Bahamian'],
  ['bahraini', 'Bahraini'],
  ['bangladeshi', 'Bangladeshi'],
  ['barbadian', 'Barbadian'],
  ['barbudans', 'Barbudans'],
  ['batswana', 'Batswana'],
  ['belarusian', 'Belarusian'],
  ['belgian', 'Belgian'],
  ['belizean', 'Belizean'],
  ['beninese', 'Beninese'],
  ['bhutanese', 'Bhutanese'],
  ['bolivian', 'Bolivian'],
  ['bosnian', 'Bosnian'],
  ['brazilian', 'Brazilian'],
  ['british', 'British'],
  ['bruneian', 'Bruneian'],
  ['bulgarian', 'Bulgarian'],
  ['burkinabe', 'Burkinabe'],
  ['burmese', 'Burmese'],
  ['burundian', 'Burundian'],
  ['cambodian', 'Cambodian'],
  ['cameroonian', 'Cameroonian'],
  ['canadian', 'Canadian'],
  ['cape', 'Cape'],
  ['verdean', 'Verdean'],
  ['central', 'Central'],
  ['african', 'African'],
  ['chadian', 'Chadian'],
  ['chilean', 'Chilean'],
  ['chinese', 'Chinese'],
  ['colombian', 'Colombian'],
  ['comoran', 'Comoran'],
  ['congolese', 'Congolese'],
  ['costa', 'Costa'],
  ['rican', 'Rican'],
  ['croatian', 'Croatian'],
  ['cuban', 'Cuban'],
  ['cypriot', 'Cypriot'],
  ['czech', 'Czech'],
  ['danish', 'Danish'],
  ['djibouti', 'Djibouti'],
  ['dominican', 'Dominican'],
  ['dutch', 'Dutch'],
  ['east', 'East'],
  ['timorese', 'Timorese'],
  ['ecuadorean', 'Ecuadorean'],
  ['egyptian', 'Egyptian'],
  ['emirian', 'Emirian'],
  ['equatorial', 'Equatorial'],
  ['guinean', 'Guinean'],
  ['eritrean', 'Eritrean'],
  ['estonian', 'Estonian'],
  ['ethiopian', 'Ethiopian'],
  ['fijian', 'Fijian'],
  ['filipino', 'Filipino'],
  ['finnish', 'Finnish'],
  ['french', 'French'],
  ['gabonese', 'Gabonese'],
  ['gambian', 'Gambian'],
  ['georgian', 'Georgian'],
  ['german', 'German'],
  ['ghanaian', 'Ghanaian'],
  ['greek', 'Greek'],
  ['grenadian', 'Grenadian'],
  ['guatemalan', 'Guatemalan'],
  ['guinea-bissauan', 'Guinea-Bissauan'],
  ['guinean', 'Guinean'],
  ['guyanese', 'Guyanese'],
  ['haitian', 'Haitian'],
  ['herzegovinian', 'Herzegovinian'],
  ['honduran', 'Honduran'],
  ['hungarian', 'Hungarian'],
  ['icelander', 'Icelander'],
  ['indian', 'Indian'],
  ['indonesian', 'Indonesian'],
  ['iranian', 'Iranian'],
  ['iraqi', 'Iraqi'],
  ['irish', 'Irish'],
  ['israeli', 'Israeli'],
  ['italian', 'Italian'],
  ['ivorian', 'Ivorian'],
  ['jamaican', 'Jamaican'],
  ['japanese', 'Japanese'],
  ['jordanian', 'Jordanian'],
  ['kazakhstani', 'Kazakhstani'],
  ['kenyan', 'Kenyan'],
  ['kittian', 'Kittian'],
  ['and', 'and'],
  ['nevisian', 'Nevisian'],
  ['kuwaiti', 'Kuwaiti'],
  ['kyrgyz', 'Kyrgyz'],
  ['laotian', 'Laotian'],
  ['latvian', 'Latvian'],
  ['lebanese', 'Lebanese'],
  ['liberian', 'Liberian'],
  ['libyan', 'Libyan'],
  ['liechtensteiner', 'Liechtensteiner'],
  ['lithuanian', 'Lithuanian'],
  ['luxembourger', 'Luxembourger'],
  ['macedonian', 'Macedonian'],
  ['malagasy', 'Malagasy'],
  ['malawian', 'Malawian'],
  ['malaysian', 'Malaysian'],
  ['maldivan', 'Maldivan'],
  ['malian', 'Malian'],
  ['maltese', 'Maltese'],
  ['marshallese', 'Marshallese'],
  ['mauritanian', 'Mauritanian'],
  ['mauritian', 'Mauritian'],
  ['mexican', 'Mexican'],
  ['micronesian', 'Micronesian'],
  ['moldovan', 'Moldovan'],
  ['monacan', 'Monacan'],
  ['mongolian', 'Mongolian'],
  ['moroccan', 'Moroccan'],
  ['mosotho', 'Mosotho'],
  ['motswana', 'Motswana'],
  ['mozambican', 'Mozambican'],
  ['namibian', 'Namibian'],
  ['nauruan', 'Nauruan'],
  ['nepalese', 'Nepalese'],
  ['netherlander', 'Netherlander'],
  ['new', 'New'],
  ['zealander', 'Zealander'],
  ['ni-vanuatu', 'Ni-Vanuatu'],
  ['nicaraguan', 'Nicaraguan'],
  ['nigerian', 'Nigerian'],
  ['nigerien', 'Nigerien'],
  ['north', 'North'],
  ['korean', 'Korean'],
  ['northern', 'Northern'],
  ['irish', 'Irish'],
  ['norwegian', 'Norwegian'],
  ['omani', 'Omani'],
  ['pakistani', 'Pakistani'],
  ['palauan', 'Palauan'],
  ['panamanian', 'Panamanian'],
  ['papua', 'Papua'],
  ['new', 'New'],
  ['guinean', 'Guinean'],
  ['paraguayan', 'Paraguayan'],
  ['peruvian', 'Peruvian'],
  ['polish', 'Polish'],
  ['portuguese', 'Portuguese'],
  ['qatari', 'Qatari'],
  ['romanian', 'Romanian'],
  ['russian', 'Russian'],
  ['rwandan', 'Rwandan'],
  ['saint', 'Saint'],
  ['lucian', 'Lucian'],
  ['salvadoran', 'Salvadoran'],
  ['samoan', 'Samoan'],
  ['san', 'San'],
  ['marinese', 'Marinese'],
  ['sao', 'Sao'],
  ['tomean', 'Tomean'],
  ['saudi', 'Saudi'],
  ['scottish', 'Scottish'],
  ['senegalese', 'Senegalese'],
  ['serbian', 'Serbian'],
  ['seychellois', 'Seychellois'],
  ['sierra', 'Sierra'],
  ['leonean', 'Leonean'],
  ['singaporean', 'Singaporean'],
  ['slovakian', 'Slovakian'],
  ['slovenian', 'Slovenian'],
  ['solomon', 'Solomon'],
  ['islander', 'Islander'],
  ['somali', 'Somali'],
  ['south', 'South'],
  ['african', 'African'],
  ['south', 'South'],
  ['korean', 'Korean'],
  ['spanish', 'Spanish'],
  ['sri', 'Sri'],
  ['lankan', 'Lankan'],
  ['sudanese', 'Sudanese'],
  ['surinamer', 'Surinamer'],
  ['swazi', 'Swazi'],
  ['swedish', 'Swedish'],
  ['swiss', 'Swiss'],
  ['syrian', 'Syrian'],
  ['taiwanese', 'Taiwanese'],
  ['tajik', 'Tajik'],
  ['tanzanian', 'Tanzanian'],
  ['thai', 'Thai'],
  ['togolese', 'Togolese'],
  ['tongan', 'Tongan'],
  ['trinidadian', 'Trinidadian'],
  ['tobagonian', 'Tobagonian'],
  ['tunisian', 'Tunisian'],
  ['turkish', 'Turkish'],
  ['tuvaluan', 'Tuvaluan'],
  ['ugandan', 'Ugandan'],
  ['ukrainian', 'Ukrainian'],
  ['uruguayan', 'Uruguayan'],
  ['uzbekistani', 'Uzbekistani'],
  ['venezuelan', 'Venezuelan'],
  ['vietnamese', 'Vietnamese'],
  ['welsh', 'Welsh'],
  ['yemenite', 'Yemenite'],
  ['zambian', 'Zambian'],
  ['zimbabwean', 'Zimbabwean'],
];

ProfileChoices.GENDER = [
  ['female', 'Женский'],
  ['male', 'Мужской'],
  ['trans', 'Транс'],
];

ProfileChoices.SEXUAL_ORIENTATION = [
  ['heterosexual', 'Heterosexual'],
  ['bisexual', 'Bisexual'],
  ['homosexual', 'Homosexual'],
];

ProfileChoices.MEETING_WITH = [
  ['men', 'Мужчины'],
  ['girls', 'Девушки'],
  ['couples', 'Пары'],
];

ProfileChoices.ETHNICITY = [
  ['slavic', 'Славянская'],
  ['european', 'Европейская'],
  ['arabian', 'Восточная'],
  ['asian', 'Азиатская'],
  ['ebony', 'Африканская'],
  ['indian', 'Индийская'],
  ['caucasian', 'Кавказская'],
  ['latin', 'Латинская'],
  ['mixed', 'Смешанная'],
];

ProfileChoices.BREAST_SIZE = [
  ['A', '1'],
  ['B', '2'],
  ['C', '3'],
  ['D', '4'],
  ['E', '5'],
  ['F', '6'],
  ['G', '6+'],
];

ProfileChoices.BREAST_TYPE = [
  ['Natural', 'Натуральная'],
  ['Silicon', 'Силиконовая'],
];

ProfileChoices.HAIR_COLOR = [
  ['blonde', 'Светлые'],
  ['brown', 'Каштановые'],
  ['black', 'Чёрные'],
  ['red', 'Красные'],
  ['other', 'Другие'],
];

ProfileChoices.EYE_COLOR = [
  ['black', 'Чёрные'],
  ['blue', 'Голубые'],
  ['blue-green', 'Сине-зелёные'],
  ['brown', 'Карие'],
  ['green', 'Зелёные'],
  ['grey', 'Серые'],
  ['hazel', 'Ореховые'],
  ['other', 'Другие'],
];

ProfileChoices.LANGUAGES = [
  ['Albaniana', 'Albaniana'], ['Arabic', 'Arabic'], ['Armenian', 'Armenian'],
  ['Azerbaijani', 'Azerbaijani'], ['Belorussian', 'Belorussian'], ['Bengali', 'Bengali'],
  ['Bislama', 'Bislama'], ['Bulgariana', 'Bulgariana'], ['Burmese', 'Burmese'], ['Catalan', 'Catalan'],
  ['Chichewa', 'Chichewa'], ['Chinese', 'Chinese'], ['Comorian', 'Comorian'], ['Czech', 'Czech'],
  ['Danish', 'Danish'], ['Dari', 'Dari'], ['Dhivehi', 'Dhivehi'], ['Dutch', 'Dutch'],
  ['Dzongkha', 'Dzongkha'], ['English', 'English'], ['Estonian', 'Estonian'], ['Faroese', 'Faroese'],
  ['Fijian', 'Fijian'], ['Finnish', 'Finnish'], ['French', 'French'], ['Georgiana', 'Georgiana'],
  ['German', 'German'], ['Greek', 'Greek'], ['Greenlandic', 'Greenlandic'], ['GuaranГЇВїВЅ', 'GuaranГЇВїВЅ'],
  ['Hebrew', 'Hebrew'], ['Hindi', 'Hindi'], ['Hungarian', 'Hungarian'], ['Icelandic', 'Icelandic'],
  ['Italian', 'Italian'], ['Japanese', 'Japanese'], ['Kazakh', 'Kazakh'], ['Khmer', 'Khmer'],
  ['Kirgiz', 'Kirgiz'], ['Kiribati', 'Kiribati'], ['Kirundi', 'Kirundi'], ['Korean', 'Korean'],
  ['Lao', 'Lao'], ['Latvian', 'Latvian'], ['Lithuanian', 'Lithuanian'], ['Luxembourgish', 'Luxembourgish'],
  ['Macedonian', 'Macedonian'], ['Malagasy', 'Malagasy'], ['Malay', 'Malay'], ['Maltese', 'Maltese'],
  ['Marshallese', 'Marshallese'], ['Mongolian', 'Mongolian'], ['Nauru', 'Nauru'], ['Nepali', 'Nepali'],
  ['Norwegian', 'Norwegian'], ['Palau', 'Palau'], ['Papiamento', 'Papiamento'], ['Pashto', 'Pashto'],
  ['Persian', 'Persian'], ['Polish', 'Polish'], ['Portuguese', 'Portuguese'], ['Romanian', 'Romanian'],
  ['Russian', 'Russian'], ['Rwanda', 'Rwanda'], ['Samoan', 'Samoan'], ['Serbo-Croatian', 'Serbo-Croatian'],
  ['Singali', 'Singali'], ['Slovak', 'Slovak'], ['Slovene', 'Slovene'], ['Somali', 'Somali'],
  ['Sotho', 'Sotho'], ['Spanish', 'Spanish'], ['Swazi', 'Swazi'], ['Swedish', 'Swedish'],
  ['Tadzhik', 'Tadzhik'], ['Thai', 'Thai'], ['Tigrinja', 'Tigrinja'], ['Tongan', 'Tongan'],
  ['Turkish', 'Turkish'], ['Turkmenian', 'Turkmenian'], ['Tuvalu', 'Tuvalu'], ['Ukrainian', 'Ukrainian'],
  ['Uzbek', 'Uzbek'], ['Vietnamese', 'Vietnamese'], ['Wolof', 'Wolof']
];

ProfileChoices.TRAVEL = [
  ['europe', 'Europe'],
  ['worldwide', 'Worldwide'],
];

ProfileChoices.PARAMETERS = [
  { name: 'gender', label: 'Пол' },
  { name: 'age', label: 'Возраст' },
  { name: 'weight', label: 'Вес' },
  { name: 'height', label: 'Рост' },
  { name: 'ethnicity', label: 'Внешность' },
  { name: 'hair_color', label: 'Волосы' },
  { name: 'breast_type', label: 'Тип груди' },
  { name: 'breast_size', label: 'Размер груди' },
];

ProfileChoices.CONTACTS = [
  { name: 'phone', label: 'Телефон' },
  { name: 'whatsapp', label: 'Whatsapp' },
  { name: 'telegram', label: 'Telegram' },
  { name: 'skype', label: 'Skype' },
  { name: 'viber', label: 'Viber' },
  { name: 'website_url', label: 'Сайт' },
];

ProfileChoices.getParametetsValue = (parameters, name) => {
  const value = parameters[name] || '';
  let valueDisplay = value;
  const choices = {
    'gender': ProfileChoices.GENDER,
    'nationality': ProfileChoices.NATIONALITY,
    'ethnicity': ProfileChoices.ETHNICITY,
    'orientation': ProfileChoices.SEXUAL_ORIENTATION,
    'meeting': ProfileChoices.MEETING_WITH,
    'breast_size': ProfileChoices.BREAST_SIZE,
    'breast_type': ProfileChoices.BREAST_TYPE,
    'hair_color': ProfileChoices.HAIR_COLOR,
    'eye_color': ProfileChoices.EYE_COLOR,
    'travel': ProfileChoices.TRAVEL,
    'languages': ProfileChoices.LANGUAGES,
  };
  if (choices[name]) {
    let values = [];
    if (Array.isArray(value)) {
      values = value.reduce((result, v) => {
        result = result.concat(choices[name].filter(item => item[0] === v));
        return result;
      }, []);
    } else {
      values = choices[name].filter(item => item[0] === value);
    }

    valueDisplay = values.map(v => v[1]).join(', ');
  }

  const getSuffix = {
    'height': 'cm',
    'weight': 'kg',
  };
  let suffix = getSuffix[name] || '';
  return valueDisplay + suffix;
};

export default ProfileChoices;
