import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import { SeoTemplateCollectionPageForm } from 'components/blocks/SeoTemplateCollectionPage';
import DetailScreen from './DetailScreen';


const SeoTemplateCollectionDetailScreen = ({ history, seoTemplateCollectionPage, seoTemplateCollectionPhoto, actions, match }) => {
  const type = match.params.seoType;
  const id = match.params.collectionId;

  useEffect(() => {
    if (type === 'photo') {
      actions.seoTemplateCollectionPhoto.detailLoad({ id: id });
    } else {
      actions.seoTemplateCollectionPage.detailLoad({ id: id });
    }
    
  }, [type, id, actions.seoTemplateCollectionPage, actions.seoTemplateCollectionPhoto]);

  const model = (type === 'photo') ? seoTemplateCollectionPhoto: seoTemplateCollectionPage;
  const modelActions = (type === 'photo') ? actions.seoTemplateCollectionPhoto: actions.seoTemplateCollectionPage;

  const detail = model.detail || {};
  
  return (
    <Page
      isLoading={model.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.tpl_path}
      modelActions={modelActions}
      breadcrumbs={[
        { title: 'Шаблоны', path: model.urls.list(type) },
        { title: detail.tpl_path, path: model.urls.detail(type, id) },
      ]}
      actions={[
        {
          mode: 'group',
          actions: [
            { mode: 'button', name: 'edit' },
            { mode: 'button', name: 'delete' },
          ]
        },
      ]}
      basePath={model.urls.detail(type, id)}
    >
      <Route exact path="/seocollection/:seoType/:collectionId" component={DetailScreen}/>

      <SeoTemplateCollectionPageForm/>
    </Page>
  );
};

const mapState = state => ({
  seoTemplateCollectionPage: state.seoTemplateCollectionPage,
  seoTemplateCollectionPhoto: state.seoTemplateCollectionPhoto,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplateCollectionPage: dispatch.seoTemplateCollectionPage,
    seoTemplateCollectionPhoto: dispatch.seoTemplateCollectionPhoto,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplateCollectionDetailScreen);
