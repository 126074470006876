import React from 'react';
import { connect } from 'react-redux';

import { Page, Fieldset } from 'components/layout';
import { DomainList, DomainForm } from 'components/blocks/Domain';


const DomainListScreen = ({ history, domain, actions }) => {
  return (
    <Page
      title="Домены"
      modelActions={actions.domain}
      breadcrumbs={[
        { title: 'Домены', path: domain.urls.list() },
      ]}
      actions={[
        { mode: 'button', name: 'add', type: 'primary', title: 'Добавить домен' },
      ]}
    >
      <Fieldset>
        <DomainList/>
      </Fieldset>

      <DomainForm/>
    </Page>
  );
};

const mapState = state => ({
  domain: state.domain,
});

const mapDispatch = dispatch => ({
  actions: {
    domain: dispatch.domain,
  }
});

export default connect(mapState, mapDispatch)(DomainListScreen);
