import React  from 'react';
import classNames from "classnames";

import styles from './type.module.css';

const TYPE_PAGE = 'page';
const TYPE_PAGE_PROFILES = 'page_profiles';
const TYPE_PROFILE = 'profile';
const TYPE_SERVICE = 'service';
const TYPE_DISTRICT = 'district';
const TYPE_PAGE_ARTICLES = 'page_articles';
const TYPE_ARTICLE = 'article';

const TYPE = {
  [TYPE_PAGE]: 'страница',
  [TYPE_PAGE_PROFILES]: 'страница c анкетами',
  [TYPE_PROFILE]: 'анкета',
  [TYPE_SERVICE]: 'услуга',
  [TYPE_DISTRICT]: 'район',
  [TYPE_PAGE_ARTICLES]: 'список статей',
  [TYPE_ARTICLE]: 'статья',
};

const PageType = ({ value, type, className, size }) => {
  const _type = value || type;

  const cx = classNames([
    styles.type,
    styles[`s${_type}`],
    size && styles[`size--${size}`],
    className,
  ]);

  return (
    <div className={cx}>{TYPE[_type] || _type}</div>
  );
};

PageType.TYPE_PAGE = TYPE_PAGE;
PageType.TYPE_PAGE_PROFILES = TYPE_PAGE_PROFILES;
PageType.TYPE_PROFILE = TYPE_PROFILE;
PageType.TYPE_SERVICE = TYPE_SERVICE;
PageType.TYPE_DISTRICT = TYPE_DISTRICT;
PageType.TYPE_PAGE_ARTICLES = TYPE_PAGE_ARTICLES;
PageType.TYPE_ARTICLE = TYPE_ARTICLE;
PageType.TYPE = TYPE;

export default PageType;
