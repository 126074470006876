import { BASE_URL } from "config";
import { api, getHeaders, loadStates, loadStatus } from "utils";
import * as base from '../base';


const endpoints = {
  current: `${BASE_URL}/api/v1/user/current/`,
  list: `${BASE_URL}/api/v1/user/`,
  detail: (id) => `${BASE_URL}/api/v1/user/${id}/`,
};

export const userCurrentLoad = (token) => api.Load('get', endpoints.current, null, {
  headers: getHeaders(token),
});
export const userListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const userDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const userUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const userDelete = (token, data) => api.Delete(endpoints.detail, token, data);


export const user = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    current: null,
    currentLoadStatus: loadStatus(loadStates.notAsked),
    showPasswordForm: false,

    title: 'Пользователи',
    urls: {
      list: () => `/user`,
      detail: (id) => `/user/${id}`,
    },
  },
  reducers: {
    currentLoadRequest(state, payload) {
      return {
        ...state,
        currentLoadStatus: loadStatus(loadStates.loading),
      }
    },
    currentLoadComplete(state, payload) {
      return {
        ...state,
        currentLoadStatus: loadStatus(loadStates.loaded),
        current: payload,
      }
    },
    currentLoadError(state, payload) {
      return {
        ...state,
        currentLoadStatus: loadStatus(loadStates.failed, payload),
      }
    },

    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,

    showPasswordForm(state, payload) {
      return {
        ...state,
        showPasswordForm: payload.show,
        editObject: payload.object,
        editPreventLoadDetail: payload.preventLoadDetail,
      };
    },
  },
  effects: dispatch => ({
    async currentLoad(payload, rootState) {
      await base.asyncRequest(dispatch, payload, rootState, userCurrentLoad, {
        request: dispatch.user.currentLoadRequest,
        complete: dispatch.user.currentLoadComplete,
        error: dispatch.user.currentLoadError,
      });
    },

    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'user', userListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'user', userDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'user', userUpdate,
        async (data) => {
          //dispatch.user.showPasswordForm({ show: false });
          const currentUser = rootState.user.current;
          if (data.id === currentUser.id) {
            await dispatch.user.currentLoad();
          }
        });
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'user', userDelete);
    },
  }),
}