import React, { Fragment } from 'react';
import { Route } from 'react-router';

import UserListScreen from './UserListScreen';
import UserDetailScreen from './UserDetailScreen';


const User = () => {
  return (
    <Fragment>
      <Route exact path="/user" component={UserListScreen}/>
      <Route exact path="/user/:userId" component={UserDetailScreen}/>
    </Fragment>
  );
}

export default  User;
