import React from 'react';

import { Fieldset } from "components/layout";
import { SeoExtLinkPageList } from 'components/blocks/SeoExtLinkPage';


export default ({ match }) => {
  const seoExtDomainId = match.params.seoExtDomainId;

  return (
    <Fieldset>
      <SeoExtLinkPageList activeFilter={{ ext_link__ext_domain: seoExtDomainId }} />
    </Fieldset>
  )
};
