import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList } from 'components/common';
import { PageValue, PageType } from 'components/blocks/Page'


const SeoExtLinkPageList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, seoExtLinkPage }) => {
  const defaultColumnList = ['page', 'title', 'type'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    page: tableColumn('Путь', 'page', {
      render: (val, item) => <PageValue detail={item.page} asLink={true} isBack={true} />,
    }),
    title: tableColumn('Тайтл', 'title', {
      render: (val, item) => item.page.title,
    }),
    type: tableColumn('Тип', 'type', {
      render: (val, item) => <PageType value={item.page.type}/>,
      width: 200,
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = seoExtLinkPage.filter.activeFilter
    }
    actions.seoExtLinkPage.listLoad({ query, activeFilter, locationChange: true });
  }, [seoExtLinkPage, actions]);

  return (
    <TableList
      location={router.location}
      model={seoExtLinkPage}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  seoExtLinkPage: state.seoExtLinkPage,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtLinkPage: dispatch.seoExtLinkPage,
  }
});

export default connect(mapState, mapDispatch)(SeoExtLinkPageList);
