import React from 'react';
import { Input } from "antd";

import { FormItem } from 'utils';
import { ServiceGroupSelect } from 'components/blocks/Service';


const MainFieldSet = (props) => {
  return (
    <div className="form-block">
      <ServiceGroupSelect {...props} name="group" label="Группа" required={true}/>

      <FormItem {...props} name="name" label="Название" required={true}>
        <Input size="large"/>
      </FormItem>

      <FormItem {...props} name="slug" label="Путь" required={true}>
        <Input size="large"/>
      </FormItem>

      <FormItem {...props} name="sort" label="Сортировка" required={true}>
        <Input size="large"/>
      </FormItem>
    </div>
  );
};

export default MainFieldSet;
