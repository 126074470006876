import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, OnOff } from 'components/common';


const SeoTemplateCollectionPageDetail = ({ seoTemplateCollectionPage, actions }) => {
  const detail = seoTemplateCollectionPage.detail || {};

  const itemUpdate = (data) => {
    actions.seoTemplateCollectionPage.update(data);
  };

  return (
    <Row gutter={16}>
      <Col span={24} style={{marginBottom: 30}}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Активно">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={seoTemplateCollectionPage.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>

            <Descriptions.Item label="Тип" span={24}>{detail.name}</Descriptions.Item>
            <Descriptions.Item label="Путь">{detail.tpl_path}</Descriptions.Item>

            <Descriptions.Item label="Тайтл">{detail.tpl_title}</Descriptions.Item>
            <Descriptions.Item label="Дескрипшн">{detail.tpl_description}</Descriptions.Item>

            <Descriptions.Item label="H1">{detail.tpl_h1}</Descriptions.Item>
            <Descriptions.Item label="H2">{detail.tpl_h2}</Descriptions.Item>
            <Descriptions.Item label="Текст">{detail.tpl_text}</Descriptions.Item>
            <Descriptions.Item label="Текст для ссылок">{detail.tpl_link_text}</Descriptions.Item>
            <Descriptions.Item label="Текст для ссылок2">{detail.tpl_link_text2}</Descriptions.Item>
            <Descriptions.Item label="Текст для ссылок3">{detail.tpl_link_text3}</Descriptions.Item>
            <Descriptions.Item label="Текст для ссылок4">{detail.tpl_link_text4}</Descriptions.Item>
            <Descriptions.Item label="Текст для ссылок5">{detail.tpl_link_text5}</Descriptions.Item>

          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  seoTemplateCollectionPage: state.seoTemplateCollectionPage,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplateCollectionPage: dispatch.seoTemplateCollectionPage,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplateCollectionPageDetail);
