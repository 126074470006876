import React from 'react';

import { Fieldset } from "components/layout";
import { SeoExtLinkAnkorList } from 'components/blocks/SeoExtLinkAnkor';


export default ({ match }) => {
  const seoExtDomainId = match.params.seoExtDomainId;

  return (
    <Fieldset>
      <SeoExtLinkAnkorList activeFilter={{ ext_link__ext_domain: seoExtDomainId }}/>
    </Fieldset>
  )
};
