import React from 'react';
import { connect } from 'react-redux';

import { Page, Fieldset } from 'components/layout';
import { SeoExtDomainList, SeoExtDomainForm } from 'components/blocks/SeoExtDomain';


const SeoExtDomainListScreen = ({ history, seoExtDomain, actions }) => {
  return (
    <Page
      title="Внешние ссылки: домены"
      modelActions={actions.seoExtDomain}
      breadcrumbs={[
        { title: 'Внешние ссылки: домены', path: seoExtDomain.urls.list() },
      ]}
    >
      <Fieldset>
        <SeoExtDomainList/>
      </Fieldset>

      <SeoExtDomainForm/>
    </Page>
  );
};

const mapState = state => ({
  seoExtDomain: state.seoExtDomain,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtDomain: dispatch.seoExtDomain,
  }
});

export default connect(mapState, mapDispatch)(SeoExtDomainListScreen);
