import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SiteSyncForm = (props) => {
  const { showEditForm } = props.siteSync;

  const onClose = () => {
    props.actions.siteSync.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.siteSync.update(data);
  };

  return (
    <Form
      title="Редактирование сайта для синхр."
      name="siteSyncForm"
      model={props.siteSync}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject, city: editObject.city &&  editObject.city.id})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  siteSync: state.siteSync,
});

const mapDispatch = dispatch => ({
  actions: {
    siteSync: dispatch.siteSync,
  }
});

export default connect(mapState, mapDispatch)(SiteSyncForm);

