import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SeoTemplatePageImportForm = (props) => {
  const { showImportForm } = props.seoTemplatePage;

  const onClose = () => {
    props.actions.seoTemplatePage.showImportForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.seoTemplatePage.import(data);
  };

  return (
    <Form
      title="Копирование шаблонов из коллекции"
      name="seoTemplatePageImportForm"
      model={props.seoTemplatePage}
      visible={showImportForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject, count: 40})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  seoTemplatePage: state.seoTemplatePage,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplatePage: dispatch.seoTemplatePage,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplatePageImportForm);

