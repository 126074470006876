import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const ServiceGroupForm = (props) => {
  const { showEditForm } = props.serviceGroup;

  const onClose = () => {
    props.actions.serviceGroup.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.serviceGroup.update(data);
  };

  return (
    <Form
      title="Редактирование группы услуг"
      name="serviceGroupForm"
      model={props.serviceGroup}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  serviceGroup: state.serviceGroup,
});

const mapDispatch = dispatch => ({
  actions: {
    serviceGroup: dispatch.serviceGroup,
  }
});

export default connect(mapState, mapDispatch)(ServiceGroupForm);

