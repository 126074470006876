import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from "connected-react-router";
import { Menu, Dropdown, Avatar } from 'antd';
import { SettingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

import { getFullNameOrEmail } from 'utils/helpers';
import styles from './user_panel.module.css';


class HeaderUserPanel extends Component {
  handleMenuClick = ({ key }) => {
    const { dispatch, actions } = this.props;
    if (key === 'logout') {
      actions.auth.logout();
    } else {
      dispatch(push(`/${key}`));
    }
  };

  render() {
    const { currentUser } = this.props;

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="setting">
          <SettingOutlined/>
          <span>Настройки</span>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="logout">
          <LogoutOutlined/>
          <span>Выход</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <div className={styles.action}>
          <Avatar
            size="small"
            className={styles.avatar}
            src={currentUser.photo}
            icon={<UserOutlined/>}
            alt="avatar"
          />
          <span className={styles.name}>{getFullNameOrEmail(currentUser)}</span>
        </div>
      </Dropdown>
    )
  }
}


const mapState = state => ({
  currentUser: state.user.current,
});

const mapDispatch = dispatch => ({
  actions: {
    auth: dispatch.auth,
  }
});

export default connect(mapState, mapDispatch)(HeaderUserPanel);
