import { loadStates, loadStatus, deleteItem } from 'utils';


export const DeleteState = {
  deleteStatus: loadStatus(loadStates.notAsked),
};

export const DeleteReducers = {
  deleteRequest(state, payload) {
    return {
      ...state,
      deleteStatus: loadStatus(loadStates.loading),
    };
  },

  deleteComplete(state, payload) {
    const { list, byId } = deleteItem(payload.id, state.list.slice(), Object.assign({}, state.byId));

    return {
      ...state,
      deleteStatus: loadStatus(loadStates.loaded),
      list: list,
      byId: byId,
    };
  },

  deleteError(state, payload) {
    return {
      ...state,
      deleteStatus: loadStatus(loadStates.failed, payload),
    }
  },
}
