import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions } from 'components/common';


const TemplateDetail = ({ template, actions }) => {
  const detail = template.detail || {};

  return (
    <Row gutter={16}>
      <Col span={16} style={{marginBottom: 30}}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Название" span={24}>{detail.name}</Descriptions.Item>
            <Descriptions.Item label="Шаблон">
            <a href={detail.tpl_file} target="_blank" rel="noopener noreferrer">{detail.tpl_file_name}</a>
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  template: state.template,
});

const mapDispatch = dispatch => ({
  actions: {
    template: dispatch.template,
    district: dispatch.district,
  }
});

export default connect(mapState, mapDispatch)(TemplateDetail);
