import React  from 'react';
import classNames from "classnames";

import styles from './type.module.css';

const TYPE_PROFILE = 'profile';
const TYPE_SERVICE = 'service';
const TYPE_DISTRICT = 'district';


const TYPE = {
  [TYPE_PROFILE]: 'шаблон анкеты',
  [TYPE_SERVICE]: 'шаблон услуги',
  [TYPE_DISTRICT]: 'шаблон района',
};

const SeoTemplatePageType = ({ value, type, className, size }) => {
  const _type = value || type;

  const cx = classNames([
    styles.type,
    styles[`s${_type}`],
    size && styles[`size--${size}`],
    className,
  ]);

  return (
    <div className={cx}>{TYPE[_type] || _type}</div>
  );
};

SeoTemplatePageType.TYPE_PROFILE = TYPE_PROFILE;
SeoTemplatePageType.TYPE_SERVICE = TYPE_SERVICE;
SeoTemplatePageType.TYPE_DISTRICT = TYPE_DISTRICT;
SeoTemplatePageType.TYPE = TYPE;

export default SeoTemplatePageType;
