import React, { Fragment } from 'react';
import { Route } from 'react-router';

import SiteListScreen from './SiteListScreen';
import SiteDetailScreen from './SiteDetailScreen';


const Site = () => {
  return (
    <Fragment>
      <Route exact path="/site" component={SiteListScreen}/>
      <Route exact path="/site/:siteId" component={SiteDetailScreen}/>
      <Route exact path="/site/:siteId/domain" component={SiteDetailScreen}/>
      <Route exact path="/site/:siteId/nav" component={SiteDetailScreen}/>
      <Route exact path="/site/:siteId/seo" component={SiteDetailScreen}/>
      <Route exact path="/site/:siteId/seo/:seoType" component={SiteDetailScreen}/>
      <Route exact path="/site/:siteId/seo/tpl/:seoTemplatePageId" component={SiteDetailScreen}/>
      <Route exact path="/site/:siteId/page" component={SiteDetailScreen}/>
      <Route exact path="/site/:siteId/page/:pageId" component={SiteDetailScreen}/>
      <Route exact path="/site/:siteId/profile" component={SiteDetailScreen}/>
      <Route exact path="/site/:siteId/publication" component={SiteDetailScreen}/>
    </Fragment>
  );
}

export default Site;
