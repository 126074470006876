import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions } from 'components/common';
import { SeoExtLinkList } from 'components/blocks/SeoExtLink'


const SeoExtDomainDetail = ({ authToken, seoExtDomain, actions }) => {
  const detail = seoExtDomain.detail || {};

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Домен" span={24}>{detail?.domain}</Descriptions.Item>
            <Descriptions.Item label="Кол-во сайтов" span={8}>{detail?.sites_count}</Descriptions.Item>
            <Descriptions.Item label="Кол-во шаблонов страниц" span={8}>{detail?.template_pages_count}</Descriptions.Item>
            <Descriptions.Item label="Кол-во страниц" span={8}>{detail?.pages_count}</Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Ссылки">
          {detail?.id && <SeoExtLinkList activeFilter={{ ext_domain: detail.id }} />}
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  seoExtDomain: state.seoExtDomain,
  authToken: state.auth.token,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtDomain: dispatch.seoExtDomain,
  }
});

export default connect(mapState, mapDispatch)(SeoExtDomainDetail);
