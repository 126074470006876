import React from 'react';
import { Row, Col, Input } from "antd";

import { FormItem } from 'utils';
import { PhotoUpload } from 'components/common';


const ConfigFieldSet = (props) => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <div className="form-block">
          <FormItem {...props} name="config.meta" label="Meta" required={false}>
            <Input.TextArea autosize={{ minRows: 3 }}/>
          </FormItem>

          <FormItem {...props} name="config.scripts_header" label="Скрипты header" required={false}>
            <Input.TextArea autosize={{ minRows: 3 }}/>
          </FormItem>

          <FormItem {...props} name="config.scripts_footer" label="Скрипты footer" required={false}>
            <Input.TextArea autosize={{ minRows: 3 }}/>
          </FormItem>

          <FormItem {...props} name="config.metrics" label="Метрика" required={false}>
            <Input.TextArea autosize={{ minRows: 3 }}/>
          </FormItem>

          <FormItem {...props} name="config.robots" label="Robots.txt" required={false}>
            <Input.TextArea autosize={{ minRows: 3 }}/>
          </FormItem>
        </div>
      </Col>

      <Col span={8}>
        <div className="form-block" style={{ minHeight: '300px' }}>
          <FormItem {...props} name="config.logo" label="Логотип" required={false} valuePropName="fileList">
            <PhotoUpload title="Загрузить логотип" mode='single'/>
          </FormItem>
        </div>

        <div className="form-block" style={{ minHeight: '300px' }}>
          <FormItem {...props} name="config.favicon" label="Favicon" required={false} valuePropName="fileList">
            <PhotoUpload title="Загрузить favicon" mode='single'/>
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default ConfigFieldSet;
