import { push } from "connected-react-router";

import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/sync/import/`,
  detail: (id) => `${BASE_URL}/api/v1/sync/import/${id}/`,
};

export const syncImportListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const syncImportDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const syncImportDelete = (token, data) => api.Delete(endpoints.detail, token, data);


export const syncImport = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Импорты',
    urls: {
      list: (siteSyncId) => `/siteSync/${siteSyncId}/imports`,
      detail: (siteSyncId, id) => `/siteSync/${siteSyncId}/imports/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'syncImport', syncImportListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'syncImport', syncImportDetailLoad,
        async (data) => {
          if (!payload.preventLoadDetail) {
            dispatch(push(rootState['syncImport'].urls.detail(data.site, data.id)));
          } else {
            const filter = rootState['syncImport'].filter;
            await dispatch['syncImport'].listLoad(filter);
          }
        }
      )
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'syncImport', syncImportDelete,
        async (data) => {
          dispatch(push(rootState['syncImport'].urls.list(data.site)));
          const filter = rootState['syncImport'].filter;
          await dispatch['syncImport'].listLoad(filter);
        }
      );
    },
  }),
}