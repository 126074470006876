import React, { useCallback, useState } from 'react';
import { Switch } from "antd";
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton, DateTimeText } from 'components/common';
import ServerValue from "../ServerValue";


const ServerList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, server }) => {
  const defaultColumnList = ['id', 'name', 'ip_address', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Название', 'name', {
      render: (val, item) => <ServerValue detail={item} asLink={true} isBack={true}/>,
      sorter: true,
    }),
    ip_address: tableColumn('IP адрес', 'ip_address', {
      sorter: true,
    }),
    backup_active: tableColumn('Бэкап активен', 'backup_active', {
      sorter: true,
      width: 150,
      render: (val, item) =>
        <div>
          <Switch
            style={{ marginTop: '5px', minWidth: '62px' }}
            checkedChildren="вкл" unCheckedChildren="выкл"
            checked={item.backup_active}
            loading={server.updateStatus.isLoading && itemIdForUpdate === item.id}
            onClick={() => onBackupActiveOnOff(item)}
          />
        </div>,
    }),
    last_backup: tableColumn('Последний бэкап', 'last_backup', {
      render: (val, item) => {
        const obj = val || {};
        return (
          <div>
            <DateTimeText date={obj.snapshot_date}/>
          </div>
        )
      }
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.server}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = server.filter.activeFilter
    }
    actions.server.listLoad({ query, activeFilter, locationChange: true });
  }, [server, actions]);

  const onBackupActiveOnOff = (item) => {
    const data = { id: item.id, backup_active: !item.backup_active, preventLoadDetail: true };
    setItemIdForUpdate(item.id);
    actions.server.update(data);
  };

  return (
    <TableList
      location={router.location}
      model={server}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  server: state.server,
});

const mapDispatch = dispatch => ({
  actions: {
    server: dispatch.server,
  }
});

export default connect(mapState, mapDispatch)(ServerList);
