import React, { useState, useEffect } from 'react';
import { Input, Select, Row, Col } from "antd";

import { FormItem } from 'utils';
import { PhotoUpload } from 'components/common';
import { CitySelect } from 'components/blocks/City';
import { DistrictTransferField } from 'components/blocks/District';
import { SiteTransferField } from 'components/blocks/Site';


const MainFieldSet = (props) => {
  const { form, errorData, profile } = props;
  const itemProps = { form, errorData };

  const editObject = profile.editObject || {};
  const [ cityId, setCityId ] = useState(editObject.city && editObject.city.id);

  useEffect(() => {
    setCityId(editObject.city && editObject.city.id);
  }, [editObject.city]);

  const onCityChange = (val) => {
    setCityId(val);
  };

  return (
    <Row gutter={32}>
      <Col span={16}>
        <div className="form-block">
          <FormItem {...itemProps} name="status" label="Статус" required={true}>
            <Select style={{ width: '100%' }} placeholder="Выберите">
              <Select.Option value={0}>Новая</Select.Option>
              <Select.Option value={1}>На проверке</Select.Option>
              <Select.Option value={3}>Проверена</Select.Option>
              <Select.Option value={4}>Отклонена</Select.Option>
              <Select.Option value={5}>Забанена</Select.Option>
            </Select>
          </FormItem>

          <FormItem {...itemProps} name="name" label="Имя" required={true}>
            <Input size="large"/>
          </FormItem>

          <FormItem {...itemProps} name="slug" label="Путь" required={true}>
            <Input size="large"/>
          </FormItem>

          <FormItem {...itemProps} name="text" label="Описание" required={false}>
            <Input.TextArea size="large" rows={10}/>
          </FormItem>
        </div>

        <div className="form-block">
          <CitySelect 
            {...itemProps} 
            name="city" 
            label="Город" 
            required={true}
            onChange={onCityChange}
          />

          <DistrictTransferField 
            {...itemProps} 
            name="districts" 
            label="Районы" 
            required={false}
            filter={{ city: cityId }}
          />

          <SiteTransferField 
            {...itemProps} 
            name="sites" 
            label="Сайты" 
            required={true}
            filter={{ city: cityId }}
          />
        </div>
      </Col>

      <Col span={8}>
        <div className="form-block">
          <FormItem {...itemProps} name="photos" required={false} valuePropName="fileList">
            <PhotoUpload title="Загрузить фото"/>
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
