import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { PageDetail } from 'components/blocks/Page';


const PageDetailScreen = ({ match, actions }) => {
  useEffect(() => {
    actions.page.detailLoad({ id: match.params.pageId });
  }, [match.params.pageId, actions.page]);

  return (
    <PageDetail />
  )
};

const mapState = state => ({
  page: state.page,
});

const mapDispatch = dispatch => ({
  actions: {
    page: dispatch.page,
  }
});

export default connect(mapState, mapDispatch)(PageDetailScreen);
