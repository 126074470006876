import React from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';

import { serviceListLoad } from "store/models/service";
import { FormItem } from "utils";
import { ListLoadComponent } from 'components/common';


const ServiceSelect = (props) => {
  return (
    <ListLoadComponent
      apiLoad={serviceListLoad}
      {...props}
      render={({ items }) => {
        const { onChange, disabled, router, authToken, model, dispatch, ...otherProps } = props;
        return (
          <FormItem {...otherProps}>
            <Select
              style={{ width: 200 }}
              onChange={onChange}
              showSearch={true}
              optionFilterProp="children"
              filterOption={(input, option) => {
                const children = option.props.children;
                return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              disabled={disabled}
            >
              {!props.required && <Select.Option value={null}>---</Select.Option>}
              {items.map(item => (
                <Select.Option key={`service${item.id}`} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        );
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.service,
});

export default connect(mapState)(ServiceSelect);
