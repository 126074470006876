import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions } from 'components/common';


const SeoTemplateCollectionPhotoDetail = ({ seoTemplateCollectionPhoto, actions }) => {
  const detail = seoTemplateCollectionPhoto.detail || {};

  return (
    <Row gutter={16}>
      <Col span={24} style={{marginBottom: 30}}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Путь">{detail.tpl_path}</Descriptions.Item>
            <Descriptions.Item label="Alt">{detail.tpl_alt}</Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  seoTemplateCollectionPhoto: state.seoTemplateCollectionPhoto,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplateCollectionPhoto: dispatch.seoTemplateCollectionPhoto,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplateCollectionPhotoDetail);
