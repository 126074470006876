import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/seo/extlinktemplatepage/`,
};

export const seoExtLinkTemplatePageListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);

export const seoExtLinkTemplatePage = {
  state: {
    ...base.ListState,

    title: 'Внешние ссылки: шаблоны страниц',
    urls: {
      list: (seoExtDomain) => `/extlink/${seoExtDomain}/templatepages`,
    },
  },
  reducers: {
    ...base.ListReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'seoExtLinkTemplatePage', seoExtLinkTemplatePageListLoad)
    },
  }),
}