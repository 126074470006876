import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions } from 'components/common';


const SeoExtLinkDetail = ({ authToken, seoExtLink, actions }) => {
  const detail = seoExtLink.detail || {};

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Ссылка" span={24}>{detail?.url}</Descriptions.Item>
            <Descriptions.Item label="Кол-во сайтов" span={8}>{detail?.sites_count}</Descriptions.Item>
            <Descriptions.Item label="Кол-во шаблонов страниц" span={8}>{detail?.template_pages_count}</Descriptions.Item>
            <Descriptions.Item label="Кол-во страниц" span={8}>{detail?.pages_count}</Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  seoExtLink: state.seoExtLink,
  authToken: state.auth.token,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtLink: dispatch.seoExtLink,
  }
});

export default connect(mapState, mapDispatch)(SeoExtLinkDetail);
