import React, { useEffect, useState } from 'react';

import { dictIsEqual } from "utils";
import { Spin } from "antd";


const DetailLoadComponent = ({ authToken, apiLoad, value, detailId, detail, render, getDetailUrl, asLink, isBack, router }) => {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);

  const [id, setId] = useState(null);
  const [linkPath, setLinkPath] = useState(null);

  const _id = value || detailId;

  useEffect(() => {
    let _isSubscribed = true;
    if (detail) {
      setId(detail.id);
      setItem(detail);
    } else if (_id) {
      setId(_id);
      const detailLoad = () => {
        setLoading(true);
        apiLoad(authToken, { id: _id })
          .then(({ error, data }) => {
            if (_isSubscribed) {
              setLoading(false);
              if (!error) {
                setId(data.id);
                setItem(data);
              }
            }
          })
      };
      detailLoad();
    }
    return () => (_isSubscribed = false)
  }, [_id, detail, asLink, authToken, apiLoad]);

  useEffect(() => {
    if (asLink) {
      let state = { slideIn: true };
      if (isBack) {
        state.backUrl = router.location.pathname;
        state.backSearch = router.location.search
      }
      const path = { pathname: getDetailUrl(id, item), state: state };
      if (!dictIsEqual(linkPath, path)) {
        setLinkPath(path);
      }
    }
  }, [asLink, router.location, isBack, id, item, linkPath, getDetailUrl]);

  return (
    <Spin spinning={loading}>
      {render({ id, item, linkPath })}
    </Spin>
  );
};

export default DetailLoadComponent;