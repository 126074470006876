import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions } from 'components/common';


const SeoTemplatePageDetail = ({ authToken, seoTemplatePage, actions }) => {
  const detail = seoTemplatePage.detail || {};

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Путь" span={24}>{detail?.tpl_path}</Descriptions.Item>
            <Descriptions.Item label="Тайтл" span={24}>{detail?.tpl_title}</Descriptions.Item>
            <Descriptions.Item label="Дескрипшен" span={24}>{detail?.tpl_description}</Descriptions.Item>
            <Descriptions.Item label="H1" span={12}>{detail?.tpl_h1}</Descriptions.Item>
            <Descriptions.Item label="H2" span={12}>{detail?.tpl_h1}</Descriptions.Item>
            <Descriptions.Item label="Описание" span={24}>{detail?.tpl_text}</Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Ссылки">
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Текст для ссылок" span={8}>{detail?.tpl_link_text}</Descriptions.Item>
            <Descriptions.Item label="Текст для ссылок 2" span={8}>{detail?.tpl_link_text2}</Descriptions.Item>
            <Descriptions.Item label="Текст для ссылок 3" span={8}>{detail?.tpl_link_text3}</Descriptions.Item>
            <Descriptions.Item label="Текст для ссылок 4" span={8}>{detail?.tpl_link_text4}</Descriptions.Item>
            <Descriptions.Item label="Текст для ссылок 5" span={8}>{detail?.tpl_link_text5}</Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  seoTemplatePage: state.seoTemplatePage,
  authToken: state.auth.token,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplatePage: dispatch.seoTemplatePage,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplatePageDetail);
