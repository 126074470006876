import { push } from "connected-react-router";

import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/seo/page/`,
  detail: (id) => `${BASE_URL}/api/v1/seo/page/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/seo/page/${id}/history/`,
};

export const pageListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const pageDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const pageUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const pageDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const pageHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });


export const page = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Навигация',
    urls: {
      list: (siteId) => `/site/${siteId}/page`,
      detail: (siteId, id) => `/site/${siteId}/page/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'page', pageListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'page', pageDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'page', pageUpdate,
        async (data) => {
          if (payload.redirectAfterSave) {
            var win = window.open( payload.redirectAfterSave, '_blank');
            win.focus();
          } else {
            dispatch['page'].showEditForm({ show: false });
            const filter = rootState['page'].filter;
            await dispatch['page'].listLoad(filter);
          }
        }
      );
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'page', pageDelete,
        async (data) => {
          dispatch(push(rootState['page'].urls.list(data.site)));
          const filter = rootState['page'].filter;
          await dispatch['page'].listLoad(filter);
        }
      );
    },
  }),
}