import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { Page, Fieldset } from 'components/layout';
import { SeoTemplateCollectionPageList, SeoTemplateCollectionPageForm } from 'components/blocks/SeoTemplateCollectionPage';
import { SeoTemplateCollectionPhotoList, SeoTemplateCollectionPhotoForm } from 'components/blocks/SeoTemplateCollectionPhoto';


const SeoTemplateCollectionListScreen = ({ history, match, seoTemplateCollectionPage, seoTemplateCollectionPhoto, actions }) => {
  const type = match.params.seoType || 'profile';

  const seoAdd = () => {
    if (type === 'photo') {
      actions.seoTemplateCollectionPhoto.showEditForm({ 
        show: true,
      });
    } else {
      actions.seoTemplateCollectionPage.showEditForm({ 
        show: true, 
        object: { type: type } 
      });
    }
  };

  const topActions = [
    <Button type="primary" icon={<PlusOutlined/>} onClick={seoAdd}>
      Добавить шаблон
    </Button>
  ];

  const renderList = () => {
    switch(type) {
      case 'photo':
        return (
          <SeoTemplateCollectionPhotoList 
            topActions={topActions}
          />
        );
      default:
        return (
          <SeoTemplateCollectionPageList 
            activeFilter={{ type: type }}
            topActions={topActions}
          />
        )
    }
  }
  
  return (
    <Page
      title="SEO-шаблоны"
      breadcrumbs={[
        { title: 'SEO-шаблоны', path: '/seocollection' },
      ]}
      basePath="/seocollection"
      tabs={[
        { title: 'Шаблоны анкет', key: 'profile' },
        { title: 'Шаблоны услуг', key: 'service' },
        { title: 'Шаблоны районов', key: 'district' },
        { title: 'Шаблоны картинок', key: 'photo' },
      ]}
    >
      <Fieldset>
        {renderList()}
      </Fieldset>

      <SeoTemplateCollectionPageForm/>
      <SeoTemplateCollectionPhotoForm/>
    </Page>
  );
};

const mapState = state => ({
  seoTemplateCollectionPage: state.seoTemplateCollectionPage,
  seoTemplateCollectionPhoto: state.seoTemplateCollectionPhoto,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplateCollectionPage: dispatch.seoTemplateCollectionPage,
    seoTemplateCollectionPhoto: dispatch.seoTemplateCollectionPhoto,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplateCollectionListScreen);
