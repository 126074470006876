import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined, } from "@ant-design/icons";

import { Fieldset } from "components/layout";
import { PageList, PageForm } from 'components/blocks/Page';


const PageListScreen = ({ match, actions }) => {
  const siteId = match.params.siteId;

  const pageAdd = () => {
    actions.page.showEditForm({
      show: true,
      object: { site: parseInt(siteId) }
    });
  };

  return (
    <Fieldset>
      <PageList
        activeFilter={{ site: siteId }}
        topActions={[
          <Button
            type="primary"
            icon={<PlusOutlined />} onClick={pageAdd}
          >
            Добавить страницу
          </Button>
        ]}
      />

      <PageForm />
    </Fieldset>
  )
};

const mapState = state => ({
  page: state.page,
});

const mapDispatch = dispatch => ({
  actions: {
    page: dispatch.page,
  }
});

export default connect(mapState, mapDispatch)(PageListScreen);
