import React from 'react';
import { Transfer } from 'antd';
import { connect } from 'react-redux';

import { siteListLoad } from "store/models/site";
import { FormItem } from "utils";
import { ListLoadComponent } from 'components/common';


const SiteTransferField = (props) => {
  return (
    <ListLoadComponent
      apiLoad={siteListLoad}
      filter={{ simple: true }}
      {...props}
      render={({ items }) => {
        const { onChange, disabled, router, authToken, model, dispatch, ...otherProps } = props;
        const dataSource = items.map(item => {
          item.key = item.id;
          return item;
        });
        return (
          <FormItem {...otherProps} valuePropName='targetKeys'>
            <Transfer
              dataSource={dataSource}
              showSearch
              listStyle={{
                width: 250,
                height: 300,
              }}
              render={item => `${item.domain_domain}`}
            />
          </FormItem>
        );
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.site,
});

export default connect(mapState)(SiteTransferField);
