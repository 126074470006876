import React from 'react';
import { Input } from "antd";

import { FormItem } from 'utils';
import { CitySelect } from 'components/blocks/City';


const MainFieldSet = (props) => {
  return (
    <div className="form-block">
      <CitySelect {...props} name="city" label="город" required={true}/>

      <FormItem {...props} name="name" label="Название" required={true}>
        <Input size="large"/>
      </FormItem>

      <FormItem {...props} name="slug" label="Путь" required={true}>
        <Input size="large"/>
      </FormItem>
    </div>
  );
};

export default MainFieldSet;
