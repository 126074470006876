import React from 'react';

import { CityDetail } from 'components/blocks/City';


const DetailScreen = (props) => {
  return (
    <CityDetail/>
  )
};

export default DetailScreen;
