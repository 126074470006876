import React  from 'react';

import { ProfileValue } from 'components/blocks/Profile';


const PageObjectValue = ({ type, obj }) => {
  if (!obj) return null;

  switch (type) {
    case 'profile':
      return (
      <div style={{marginTop: '5px'}}>
        <ProfileValue detail={obj} asLink={true} isBack={true} simple={true}/>
      </div>
    );
    case 'service':
      return null;
    case 'district':
      return null;
    case 'article':
      return null;
    default:
      return null;
  }
};


export default PageObjectValue;
