import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { getUrl, sortArrayOfObjects } from 'utils';
import { Fieldset } from 'components/layout';
import { Descriptions, Props, DateTimeText, OnOff } from 'components/common';
import { UserValue } from 'components/blocks/User';
import { CityValue } from 'components/blocks/City';
import { DistrictValue } from 'components/blocks/District';
import { SiteValue } from 'components/blocks/Site';
import { SiteSyncValue } from 'components/blocks/SiteSync';
import ProfileStatus from "../ProfileStatus";
import ProfilePublishStatus from "../ProfilePublishStatus";
import ProfileChoices from "../ProfileChoices";
import styles from './detail.module.css';


const ProfileDetail = ({ profile, actions }) => {
  const detail = profile.detail || {};
  const sites = useMemo(
    () => sortArrayOfObjects(detail.sites || [], 'domain_domain'), 
    [detail.sites]
  );
  const districts = useMemo(
    () => sortArrayOfObjects(detail.districts || [], 'name'), 
    [detail.districts]
  );
  const photos = detail.photos || [];

  const itemUpdate = (data) => {
    actions.profile.update(data);
  };

  return (
    <Row gutter={16}>
      <Col span={16} style={{marginBottom: 30}}>
        <Fieldset>
          <Descriptions column={3} gutter={[16, 16]}>
            <Descriptions.Item label="Имя" span={12}>{detail.name}</Descriptions.Item>
            <Descriptions.Item label="User" span={12}>
              <UserValue detail={detail.user} asLink={true} isBack={true}/>
            </Descriptions.Item>
            <Descriptions.Item label="Параметры" span={12}>
              <Props 
                className={styles.props}
                choices={ProfileChoices.PARAMETERS} 
                getParametetsValue={ProfileChoices.getParametetsValue}
                info={detail.parameters || {}} 
                column={2}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Контакты" span={12}>
              <Props 
                className={styles.props}
                choices={ProfileChoices.CONTACTS} 
                getParametetsValue={ProfileChoices.getParametetsValue}
                info={detail.contacts || {}} 
                column={1}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Описание" span={24}>{detail.text}</Descriptions.Item>
            <Descriptions.Item label="Статус">
              <ProfileStatus status={detail.status} isActive={detail.is_active}/>
            </Descriptions.Item>
            <Descriptions.Item label="Статус публикации">
              <ProfilePublishStatus isActive={detail.is_active}/>
            </Descriptions.Item>
            <Descriptions.Item label="Включена">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_on"
                updateStatus={profile.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Дата создания">
              <DateTimeText date={detail.date_created}/>
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения">
              <DateTimeText date={detail.date_changed}/>
            </Descriptions.Item>
            <Descriptions.Item label="Дата поднятия">
              <DateTimeText date={detail.date_up}/>
            </Descriptions.Item>
            <Descriptions.Item label="Активна до">
              <DateTimeText date={detail.date_active}/>
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Сайты">
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Внешний сайт">
              {detail.site_sync ? 
                <SiteSyncValue detailId={detail.site_sync} asLink={true} isBack={true}/>
                :
                '-'
              }
            </Descriptions.Item>
            <Descriptions.Item label="Сайты">
              {sites.map(site => (
                <div key={`site${site.id}`}>
                  <SiteValue detail={site} asLink={true} isBack={true}/>
                </div>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Города и районы">
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Город">
              <CityValue detail={detail.city} asLink={true} isBack={true}/>
            </Descriptions.Item>
            <Descriptions.Item label="Районы">
              <div className={styles.distrcits}>
                {districts.map(district => (
                  <div key={`district${district.id}`}>
                    <DistrictValue detail={district} asLink={true} isBack={true}/>
                  </div>
                ))}
              </div>
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8} style={{marginBottom: 30}}>
        <Fieldset>
          {photos.length > 0 &&
            <div className={styles.photos}>
              {photos.map(p => (
                <div key={`photo${p.id}`} className={styles.photo}>
                    <div>
                      <img src={getUrl(p.url)} alt=""/>
                    </div>
                </div>
              ))}
            </div>
          }
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  profile: state.profile,
});

const mapDispatch = dispatch => ({
  actions: {
    profile: dispatch.profile,
    district: dispatch.district,
  }
});

export default connect(mapState, mapDispatch)(ProfileDetail);
