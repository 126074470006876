import React, { useState } from 'react';
import classNames from "classnames";
import { Modal } from 'antd';

import { DateTimeText } from 'components/common';

import styles from './value.module.css';

const STATUS_INIT = 'init';
const STATUS_QUEUED = 'queued';
const STATUS_PROCESS = 'process';
const STATUS_COMPLETE = 'complete';
const STATUS_ERROR = 'error';

const STATUS = {
  [STATUS_INIT]: 'инициализация',
  [STATUS_QUEUED]: 'в очереди',
  [STATUS_PROCESS]: 'в процессе',
  [STATUS_COMPLETE]: 'завершено',
  [STATUS_ERROR]: 'ошибка',
};

const SitePublicationStatus = ({ site }) => {
  const isPublishing = site.is_publishing;
  const dateStart = site.publish_start_date;
  const dateSuccess = site.publish_sucess_date;
  const errorDate = site.publish_error_date;
  const lastError = site.publish_last_error;

  let cls = [styles.container];
  let status = '-';
  let date = dateSuccess;

  if (isPublishing) {
    status = STATUS_PROCESS;
    date = dateStart;

  } else if (errorDate) {
    status = STATUS_ERROR;
    date = errorDate;
  } else {
    status = STATUS_COMPLETE;
    date = dateSuccess;
  }

  cls.push(styles[`status--${status}`]);

  const [statusVisible, setStatusVisible] = useState(false);

  const showStatus = () => {
    setStatusVisible(true);
  }

  return (
    <>
      <div className={classNames(cls)} onClick={showStatus}>
        <DateTimeText className={styles.date} date={date} />
        <span className={styles.status}>{STATUS[status] || status}</span>
      </div>

      <Modal
        width={800}
        visible={statusVisible}
        title={`Статус публикации на сайте ${site?.domain?.domain}`}
        onCancel={() => setStatusVisible(false)}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className={styles.prop}>
          <div className={styles.propLabel}>Дата последней успешной выгрузки: </div>
          <div className={styles.propValue}><DateTimeText date={dateStart} /></div>
        </div>
        <div className={styles.prop}>
          <div className={styles.propLabel}>Дата начала: </div>
          <div className={styles.propValue}><DateTimeText date={dateStart} /></div>
        </div>

        {errorDate && <div className={styles.prop}>
          <div className={styles.propLabel}>Дата ошибки: </div>
          <div className={styles.propValue}><DateTimeText date={errorDate} /></div>
        </div>}

        {status === STATUS_ERROR && lastError && <div className={styles.error}>{lastError}</div>}
      </Modal>
    </>
  )
};

export default SitePublicationStatus;
