import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SeoTemplateCollectionPageForm = (props) => {
  const { showEditForm } = props.seoTemplateCollectionPage;

  const onClose = () => {
    props.actions.seoTemplateCollectionPage.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.seoTemplateCollectionPage.update(data);
  };

  return (
    <Form
      title="Редактирование шаблона"
      name="seoTemplateCollectionPageForm"
      model={props.seoTemplateCollectionPage}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  seoTemplateCollectionPage: state.seoTemplateCollectionPage,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplateCollectionPage: dispatch.seoTemplateCollectionPage,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplateCollectionPageForm);

