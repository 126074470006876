import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SeoTemplateCollectionPhotoForm = (props) => {
  const { showEditForm } = props.seoTemplateCollectionPhoto;

  const onClose = () => {
    props.actions.seoTemplateCollectionPhoto.showEditForm({ show: false });
  };

  const onSubmit = data => {
    data.type = 'profile';
    props.actions.seoTemplateCollectionPhoto.update(data);
  };

  return (
    <Form
      title="Редактирование шаблона"
      name="seoTemplateCollectionPhotoForm"
      model={props.seoTemplateCollectionPhoto}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  seoTemplateCollectionPhoto: state.seoTemplateCollectionPhoto,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplateCollectionPhoto: dispatch.seoTemplateCollectionPhoto,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplateCollectionPhotoForm);

