import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList } from 'components/common';


const SeoExtLinkAnkorList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, seoExtLinkAnkor }) => {
  const defaultColumnList = ['ankor'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    ankor: tableColumn('Анкор', 'ankor', {
      sorter: true,
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = seoExtLinkAnkor.filter.activeFilter
    }
    actions.seoExtLinkAnkor.listLoad({ query, activeFilter, locationChange: true });
  }, [seoExtLinkAnkor, actions]);

  return (
    <TableList
      location={router.location}
      model={seoExtLinkAnkor}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  seoExtLinkAnkor: state.seoExtLinkAnkor,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtLinkAnkor: dispatch.seoExtLinkAnkor,
  }
});

export default connect(mapState, mapDispatch)(SeoExtLinkAnkorList);
