import React from 'react';
import { connect } from 'react-redux';

import { Page, Fieldset } from 'components/layout';
import { ProfileList, ProfileForm } from 'components/blocks/Profile';


const ProfileListScreen = ({ history, profile, actions }) => {
  const info = profile.info || {};

  const path = history.location.pathname.replace('/profile', '');
  const activeStatus = path.startsWith('/status') ? path.replace('/status/', ''): null;
  
  let activeFilter = {};
  if (activeStatus) {
    activeFilter.status = activeStatus;
  }

  return (
    <Page
      title="Анкеты"
      modelActions={actions.profile}
      breadcrumbs={[
        { title: 'Анкеты', path: profile.urls.list() },
      ]}
      actions={[
        { mode: 'button', name: 'add', type: 'primary', title: 'Добавить анкету' },
      ]}
      basePath="/profile"
      tabs={[
        { title: 'Все', count: info['all'] || 0, key: 'detail' },
        { title: 'Новые', count: info['0'] || 0, key: 'status/0' },
        { title: 'На проверке', count: info['1'] || 0, key: 'status/1' },
        { title: 'Проверенные', count: info['3'] || 0, key: 'status/3' },
        { title: 'Отклонённые', count: info['4'] || 0, key: 'status/4' },
        { title: 'Забаненные', count: info['5'] || 0, key: 'status/5' },
      ]}
    >
      <Fieldset>
        <ProfileList activeFilter={activeFilter}/>
      </Fieldset>

      <ProfileForm/>
    </Page>
  );
};

const mapState = state => ({
  profile: state.profile,
});

const mapDispatch = dispatch => ({
  actions: {
    profile: dispatch.profile,
  }
});

export default connect(mapState, mapDispatch)(ProfileListScreen);
