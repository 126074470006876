import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from "classnames";

import { syncPublicationDetailLoad, syncPublicationListLoad } from "store/models/syncPublication";
import { DetailLoadComponent, DateTimeText } from 'components/common';

import styles from './value.module.css';

const STATUS_INIT = 'init';
const STATUS_QUEUED = 'queued';
const STATUS_PROCESS = 'process';
const STATUS_COMPLETE = 'complete';
const STATUS_ERROR = 'error';

const STATUS = {
  [STATUS_INIT]: 'инициализация',
  [STATUS_QUEUED]: 'в очереди',
  [STATUS_PROCESS]: 'в процессе',
  [STATUS_COMPLETE]: 'завершено',
  [STATUS_ERROR]: 'ошибка',
};

const TIMEOUT_INTERVAL = 1000;

const Value = ({ id, item, date_synced }) => {
  let cls, dateStart, status;
  cls = [styles.container];
  if (item && item.id) {
    dateStart = item.date_start;
    status = item.status;
    cls.push(styles[`status--${status}`]);

    const lastStep = item.steps.length > 0 ? item.steps[item.steps.length - 1] : null;

    return (
      <div className={classNames(cls)}>
        <DateTimeText className={styles.date} date={dateStart} />
        <span className={styles.status}>{STATUS[status] || status}</span>
        {status === 'process' && lastStep && <span className={styles.step}>&nbsp;- {lastStep.name}</span>}
      </div>
    );
  }

  return (
    <div className={classNames(cls)}>{id}: -</div>
  );
}

const SyncPublicationValue = (props) => {
  const [detail, setDetail] = useState({});

  useEffect(() => {
    setDetail(props.detail);
  }, [props.detail]);

  const loadData = useCallback((isActive) => {
    if (!props.site_id) return;
    const activeFilter = {
      site: props.site_id,
      limit: 1,
    };
    //console.log('loadData', activeFilter);
    syncPublicationListLoad(props.authToken, { activeFilter: activeFilter })
      .then(({ error, data }) => {
        if (isActive) {
          const item = data && data.results ? data.results[0] : null;
          if (item) {
            setDetail(item)
          }

          if (!item || (item && item.status !== STATUS_COMPLETE && item.status !== STATUS_ERROR)) {
            setTimeout(() => {
              loadData(isActive);
            }, TIMEOUT_INTERVAL);
          }
        }
      });
  }, [props.authToken, props.site_id]);

  useEffect(() => {
    let isActive = true;
    loadData(isActive);
    return () => {
      isActive = false;
    }
  }, [detail?.site?.id, loadData]);

  return (
    <DetailLoadComponent
      apiLoad={syncPublicationDetailLoad}
      {...props}
      detail={detail}
      getDetailUrl={(id, item) => props.model.urls.detail(id)}
      render={({ id, item, linkPath }) => {
        if (props.asLink && linkPath) {
          return <Link to={linkPath}><Value id={id} item={item} date_synced={props.date_synced} /></Link>;
        }

        return <Value id={id} item={item} date_synced={props.date_synced} />;
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.syncPublication,
});

export default connect(mapState)(SyncPublicationValue);
