import React from 'react';
import { Upload, Button } from "antd";
import { UploadOutlined } from '@ant-design/icons';

import { FormItem } from "utils";


const SimpleFileField = (props) => {
  const beforeUpload = (file, fileList) => {
    return false;
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    return e && e.fileList.slice(-1);
  };

  return (
    <FormItem 
      valuePropName="fileList" 
      getValueFromEvent={normFile}
      {...props}
    >
      <Upload beforeUpload={beforeUpload}>
        <Button>
            <UploadOutlined/> Click to upload
        </Button>
      </Upload>
    </FormItem>
  );
};

SimpleFileField.getFileList = (fileObj, fileName) => {
  return fileObj ? [{
    uid: '1',
    name: fileName,
    status: 'done',
    url: fileObj,
  }]: [];
};

SimpleFileField.getOriginFileObj = fileList => {
  if (Array.isArray(fileList) && fileList.length > 0) {
    return fileList[0].originFileObj;
  }
  return null;
};

export default SimpleFileField;
