import React from 'react';
import { connect } from 'react-redux';

import { Page, Fieldset } from 'components/layout';
import { SyncPublicationList } from 'components/blocks/SyncPublication';


const SyncPublicationListScreen = ({ history, syncPublication, actions }) => {
  return (
    <Page
      title="Публикации"
      modelActions={actions.syncPublication}
      breadcrumbs={[
        { title: 'Публикации', path: syncPublication.urls.list() },
      ]}
    >
      <Fieldset>
        <SyncPublicationList/>
      </Fieldset>
    </Page>
  );
};

const mapState = state => ({
  syncPublication: state.syncPublication,
});

const mapDispatch = dispatch => ({
  actions: {
    syncPublication: dispatch.syncPublication,
  }
});

export default connect(mapState, mapDispatch)(SyncPublicationListScreen);
