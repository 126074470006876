import React from 'react';

import { Fieldset } from "components/layout";
import { DomainList } from 'components/blocks/Domain';


const DomainListScreen = ({ match }) => {
  const siteId = match.params.siteId;

  return (
    <Fieldset>
      <DomainList activeFilter={{ site: siteId }}/>
    </Fieldset>
  )
};

export default DomainListScreen;
