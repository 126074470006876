import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SeoTemplatePhotoForm = (props) => {
  const { showEditForm } = props.seoTemplatePhoto;

  const onClose = () => {
    props.actions.seoTemplatePhoto.showEditForm({ show: false });
  };

  const onSubmit = data => {
    data.type = 'profile';
    props.actions.seoTemplatePhoto.update(data);
  };

  return (
    <Form
      title="Редактирование шаблона"
      name="seoTemplatePhotoForm"
      model={props.seoTemplatePhoto}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  seoTemplatePhoto: state.seoTemplatePhoto,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplatePhoto: dispatch.seoTemplatePhoto,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplatePhotoForm);

