import React from 'react';
import { connect } from 'react-redux';

import { Page, Fieldset } from 'components/layout';
import { SiteList, SiteForm } from 'components/blocks/Site';


const SiteListScreen = ({ history, site, actions }) => {
  return (
    <Page
      title="Сайты"
      modelActions={actions.site}
      breadcrumbs={[
        { title: 'Сайты', path: site.urls.list() },
      ]}
      actions={[
        { mode: 'button', name: 'add', type: 'primary', title: 'Добавить сайт' },
      ]}
    >
      <Fieldset>
        <SiteList/>
      </Fieldset>

      <SiteForm/>
    </Page>
  );
};

const mapState = state => ({
  site: state.site,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(SiteListScreen);
