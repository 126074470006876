import { loadStates, loadStatus } from 'utils';


export const EditState = {
  showEditForm: false,
  editObject: null,
  editPreventLoadDetail: false,
  editAdditional: null,

  updateStatus: loadStatus(loadStates.notAsked),
};

export const EditReducers = {
  showEditForm(state, payload) {
    return {
      ...state,
      showEditForm: payload.show,
      editObject: payload.object,
      editPreventLoadDetail: payload.preventLoadDetail,
      editAdditional: payload.additional,
    };
  },

  updateRequest(state, payload) {
    return {
      ...state,
      updateStatus: loadStatus(loadStates.loading),
    };
  },

  updateComplete(state, payload) {
    return {
      ...state,
      updateStatus: loadStatus(loadStates.loaded),
      detail: payload,
    };
  },

  updateError(state, payload) {
    return {
      ...state,
      updateStatus: loadStatus(loadStates.failed, payload),
    }
  },
}
