import React from 'react';
import { connect } from 'react-redux';

import { Page, Fieldset } from 'components/layout';
import { CityList, CityForm } from 'components/blocks/City';


const CityListScreen = ({ history, city, actions }) => {
  return (
    <Page
      title="Города"
      modelActions={actions.city}
      breadcrumbs={[
        { title: 'Города', path: city.urls.list() },
      ]}
      actions={[
        { mode: 'button', name: 'add', type: 'primary', title: 'Добавить город' },
      ]}
    >
      <Fieldset>
        <CityList/>
      </Fieldset>

      <CityForm/>
    </Page>
  );
};

const mapState = state => ({
  city: state.city,
});

const mapDispatch = dispatch => ({
  actions: {
    city: dispatch.city,
  }
});

export default connect(mapState, mapDispatch)(CityListScreen);
