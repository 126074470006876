import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { pageDetailLoad } from "store/models/page";
import { DetailLoadComponent } from 'components/common';


const PageValue = (props) => {
  return (
    <DetailLoadComponent
      apiLoad={pageDetailLoad}
      {...props}
      getDetailUrl={(id, item) => props.model.urls.detail(item?.site_id, id)}
      render={({ id, item, linkPath }) => {
        const value = item?.path || id;

        return (
          <Fragment>
            {props.asLink && linkPath ?
              <Link to={linkPath}>{value}</Link> :
              <span>{value}</span>}
          </Fragment>
        );
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.page,
});

export default connect(mapState)(PageValue);
