import React, { Fragment } from 'react';
import { Route } from 'react-router';

import ServiceListScreen from './ServiceListScreen';


const Service = () => {
  return (
    <Fragment>
      <Route exact path="/service" component={ServiceListScreen}/>
    </Fragment>
  );
}

export default Service;
