import React from 'react';
import { Input, Checkbox } from "antd";

import { FormItem } from 'utils';
import { Fieldset } from 'components/layout';
import { DomainSelect } from 'components/blocks/Domain';


const MainFieldSet = (props) => {
  const { hiddenDomain, ...otherProps } = props;

  return (
    <div>
      <Fieldset className="form-block">
        <DomainSelect {...otherProps} name="domain" label="Главный домен" required={true}/>

        <FormItem {...otherProps} name="hidden_domain" label="Скрытый домен" required={true}>
          <Input size="large"/>
        </FormItem>

        <FormItem {...otherProps} name="is_main" label="Основной" required={false} valuePropName="checked">
          <Checkbox/>
        </FormItem>
      </Fieldset>
    </div>
  );
};

export default MainFieldSet;
