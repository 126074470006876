import React, { Fragment } from 'react';
import { Route } from 'react-router';

import SyncPublicationListScreen from './SyncPublicationListScreen';
import SyncPublicationDetailScreen from './SyncPublicationDetailScreen';


const SyncPublication = () => {
  return (
    <Fragment>
      <Route exact path="/publication" component={SyncPublicationListScreen}/>
      <Route exact path="/publication/:syncPublicationId" component={SyncPublicationDetailScreen}/>
    </Fragment>
  );
}

export default SyncPublication;
