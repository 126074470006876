import React from 'react';
import { Input } from "antd";

import { FormItem } from 'utils';


export default (props) => {
  return (
    <div className="form-block">
      <FormItem {...props} name="domain" label="Домен" required={true}>
        <Input size="large"/>
      </FormItem>
    </div>
  );
};
