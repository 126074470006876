import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const DomainForm = (props) => {
  const { showEditForm } = props.domain;

  const onClose = () => {
    props.actions.domain.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.domain.update(data);
  };

  return (
    <Form
      title="Редактирование домена"
      name="domainForm"
      model={props.domain}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject, server: editObject.server &&  editObject.server.id})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  domain: state.domain,
});

const mapDispatch = dispatch => ({
  actions: {
    domain: dispatch.domain,
  }
});

export default connect(mapState, mapDispatch)(DomainForm);
