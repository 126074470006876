import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SeoTemplatePageForm = (props) => {
  const { showEditForm } = props.seoTemplatePage;

  const onClose = () => {
    props.actions.seoTemplatePage.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.seoTemplatePage.update(data);
  };

  return (
    <Form
      title="Редактирование шаблона"
      name="seoTemplatePageForm"
      model={props.seoTemplatePage}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  seoTemplatePage: state.seoTemplatePage,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplatePage: dispatch.seoTemplatePage,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplatePageForm);

