import React from 'react';
import { Input, Checkbox } from "antd";

import { FormItem } from 'utils';
import { CitySelect } from 'components/blocks/City';


const MainFieldSet = (props) => {
  const { siteSync, ...otherProps } = props;
  return (
    <div className="form-block">
      <CitySelect {...otherProps} name="city" label="город" required={true}/>

      <FormItem {...otherProps} name="url" label="Сайт" required={true}>
        <Input size="large"/>
      </FormItem>

      <FormItem {...otherProps} name="api_token" label="API токен" required={false}>
        <Input size="large"/>
      </FormItem>

      <FormItem {...otherProps} name="is_active" label="Активен" required={false} valuePropName="checked">
        <Checkbox/>
      </FormItem>
    </div>
  );
};

export default MainFieldSet;
