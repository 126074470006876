import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import { ProfileForm } from 'components/blocks/Profile';
import { DistrictForm } from 'components/blocks/District';
import DetailScreen from './DetailScreen';


const ProfileDetailScreen = ({ history, profile, actions, match }) => {
  useEffect(() => {
    actions.profile.detailLoad({ id: match.params.profileId });
  }, [match.params.profileId, actions.profile]);

  const id = match.params.profileId;
  const detail = profile.detail || {};

  return (
    <Page
      isLoading={profile.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.profile}
      breadcrumbs={[
        { title: 'Анкеты', path: profile.urls.list() },
        { title: detail.name, path: profile.urls.detail(id) },
      ]}
      actions={[
        {
          mode: 'group',
          actions: [
            { mode: 'button', name: 'edit' },
            { mode: 'button', name: 'delete' },
          ]
        },
      ]}
      basePath={profile.urls.detail(id)}
    >
      <Route exact path="/profile/:profileId" component={DetailScreen}/>

      <ProfileForm/>
      <DistrictForm/>
    </Page>
  );
};

const mapState = state => ({
  profile: state.profile,
});

const mapDispatch = dispatch => ({
  actions: {
    profile: dispatch.profile,
  }
});

export default connect(mapState, mapDispatch)(ProfileDetailScreen);
