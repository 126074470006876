import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import { SeoExtDomainForm } from 'components/blocks/SeoExtDomain';
import { SeoExtLinkForm } from 'components/blocks/SeoExtLink';
import DetailScreen from './DetailScreen';
import SiteListScreen from './SiteListScreen';
import TemplatePageListScreen from './TemplatePageListScreen'
import PageListScreen from './PageListScreen'
import AnkorListScreen from './AnkorListScreen'


const SeoExtDomainDetailScreen = ({ history, seoExtDomain, actions, match }) => {
  useEffect(() => {
    actions.seoExtDomain.detailLoad({ id: match.params.seoExtDomainId });
  }, [match.params.seoExtDomainId, actions.seoExtDomain]);

  const id = match.params.seoExtDomainId;
  const detail = seoExtDomain.detail || {};

  return (
    <Page
      isLoading={seoExtDomain.loadDetailStatus.isLoading || seoExtDomain.updateStatus.isLoading}
      detail={detail}
      title={detail.domain}
      modelActions={actions.seoExtDomain}
      breadcrumbs={[
        { title: 'Внешние ссылки', path: seoExtDomain.urls.list() },
        { title: detail.domain, path: seoExtDomain.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [
            { mode: 'button', name: 'delete' },
          ]
        },
      ]}
      basePath={seoExtDomain.urls.detail(id)}
      tabs={[
        { title: 'Основное', key: 'detail' },
        { title: `Сайты ${detail?.sites_count || 0}`, key: 'sites' },
        { title: `Шабоны страниц ${detail?.template_pages_count || 0}`, key: 'templatepages' },
        { title: `Страницы ${detail?.pages_count || 0}`, key: 'pages' },
        { title: 'Анкоры', key: 'ankors' },
      ]}
    >
      <Route exact path="/extlink/:seoExtDomainId" component={DetailScreen} />
      <Route exact path="/extlink/:seoExtDomainId/sites" component={SiteListScreen} />
      <Route exact path="/extlink/:seoExtDomainId/templatepages" component={TemplatePageListScreen} />
      <Route exact path="/extlink/:seoExtDomainId/pages" component={PageListScreen} />
      <Route exact path="/extlink/:seoExtDomainId/ankors" component={AnkorListScreen} />

      <SeoExtDomainForm />
      <SeoExtLinkForm />
    </Page>
  );
};

const mapState = state => ({
  seoExtDomain: state.seoExtDomain,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtDomain: dispatch.seoExtDomain,
  }
});

export default connect(mapState, mapDispatch)(SeoExtDomainDetailScreen);
