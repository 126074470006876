import React from 'react';
import { connect } from 'react-redux';

import { Page, Fieldset } from 'components/layout';
import { TemplateList, TemplateForm } from 'components/blocks/Template';


const TemplateListScreen = ({ history, template, actions }) => {
  return (
    <Page
      title="Шаблоны"
      modelActions={actions.template}
      breadcrumbs={[
        { title: 'Шаблоны', path: template.urls.list() },
      ]}
      actions={[
        { mode: 'button', name: 'add', type: 'primary', title: 'Добавить шаблон' },
      ]}
    >
      <Fieldset>
        <TemplateList/>
      </Fieldset>

      <TemplateForm/>
    </Page>
  );
};

const mapState = state => ({
  template: state.template,
});

const mapDispatch = dispatch => ({
  actions: {
    template: dispatch.template,
  }
});

export default connect(mapState, mapDispatch)(TemplateListScreen);
