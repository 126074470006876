import { BASE_URL } from "config";
import { api, getHeaders } from "utils";
import * as base from '../base';
import { push } from "connected-react-router";


const endpoints = {
  list: `${BASE_URL}/api/v1/domain/hidden/`,
  detail: (id) => `${BASE_URL}/api/v1/domain/hidden/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/domain/hidden/${id}/history/`,
  makeMain: (id) => `${BASE_URL}/api/v1/domain/hidden/${id}/make_main/`,
};

export const hiddenDomainListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const hiddenDomainDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const hiddenDomainUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const hiddenDomainDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const hiddenDomainHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });
export const hiddenDomainMakeMain = (token, data) => api.Load('put', endpoints.makeMain(data.id), {}, {
  headers: getHeaders(token)
});


export const hiddenDomain = {
  state: {
    ...base.ListState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Скрытые домены',
    urls: {
      list: (domainId) => `/domain/${domainId}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'hiddenDomain', hiddenDomainListLoad)
    },

    async update(payload, rootState) {
      await base.asyncRequest(dispatch, payload, rootState, hiddenDomainUpdate, {
        request: dispatch['hiddenDomain'].updateRequest,
        complete: dispatch['hiddenDomain'].updateComplete,
        error: dispatch['hiddenDomain'].updateError,
      }, async (data) => {
        dispatch['hiddenDomain'].showEditForm({ show: false });
        
        const filter = rootState['hiddenDomain'].filter;
        await dispatch['hiddenDomain'].listLoad(filter);
        dispatch(push(rootState['hiddenDomain'].urls.list(data.domain)));
      });
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'hiddenDomain', hiddenDomainDelete);
    },

    async makeMain(payload, rootState) {
      await base.asyncRequest(dispatch, payload, rootState, hiddenDomainMakeMain, {
        request: dispatch['hiddenDomain'].updateRequest,
        complete: dispatch['hiddenDomain'].updateComplete,
        error: dispatch['hiddenDomain'].updateError,
      }, async (data) => {
        dispatch['hiddenDomain'].showEditForm({ show: false });

        const filter = rootState['hiddenDomain'].filter;
        await dispatch['hiddenDomain'].listLoad(filter);
        dispatch(push(rootState['hiddenDomain'].urls.list(data.domain)));
      });
    },
  }),
}