import React from 'react';
import { connect } from 'react-redux';
import { Button, Tabs } from 'antd';
import { PlusOutlined, CopyOutlined } from "@ant-design/icons";

import { Fieldset } from "components/layout";
import { SeoTemplatePageList, SeoTemplatePageForm, SeoTemplatePageImportForm } from 'components/blocks/SeoTemplatePage';
import { SeoTemplatePhotoList, SeoTemplatePhotoForm, SeoTemplatePhotoImportForm } from 'components/blocks/SeoTemplatePhoto';


const SeoTemplatePageListScreen = ({ history, match, actions }) => {
  const siteId = match.params.siteId;
  const type = match.params.seoType || 'profile';

  const seoCopy = () => {
    if (type === 'photo') {
      actions.seoTemplatePhoto.showImportForm({
        show: true,
        object: { site: parseInt(siteId), type: type }
      });
    } else {
      actions.seoTemplatePage.showImportForm({
        show: true,
        object: { site: parseInt(siteId), type: type }
      });
    }
  };

  const seoAdd = () => {
    if (type === 'photo') {
      actions.seoTemplatePhoto.showEditForm({
        show: true,
        object: { site: parseInt(siteId) }
      });
    } else {
      actions.seoTemplatePage.showEditForm({
        show: true,
        object: { site: parseInt(siteId), type: type }
      });
    }
  };

  const onTabChange = (key) => {
    history.push(`/site/${siteId}/seo/${key}`);
  };

  const topActions = [
    <Button type="primary" icon={<CopyOutlined />} onClick={seoCopy}>
      Копировать из коллекции
    </Button>,
    <Button type="primary" icon={<PlusOutlined />} onClick={seoAdd}>
      Добавить шаблон
    </Button>
  ];

  const renderList = () => {
    switch (type) {
      case 'photo':
        return (
          <SeoTemplatePhotoList
            activeFilter={{ site: siteId }}
            topActions={topActions}
          />
        );
      default:
        return (
          <SeoTemplatePageList
            activeFilter={{ site: siteId, type: type }}
            topActions={topActions}
          />
        )
    }
  }

  return (
    <Fieldset>
      <Tabs className="tabs" activeKey={type} onChange={onTabChange}>
        <Tabs.TabPane tab="Шаблоны анкет" key="profile" />
        <Tabs.TabPane tab="Шаблоны услуг" key="service" />
        <Tabs.TabPane tab="Шаблоны районов" key="district" />
        <Tabs.TabPane tab="Шаблоны картинок" key="photo" />
      </Tabs>

      {renderList()}

      <SeoTemplatePageForm />
      <SeoTemplatePhotoForm />
      <SeoTemplatePageImportForm />
      <SeoTemplatePhotoImportForm />
    </Fieldset>
  )
};

const mapState = state => ({
  seoTemplatePage: state.seoTemplatePage,
  seoTemplatePhoto: state.seoTemplatePhoto,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplatePage: dispatch.seoTemplatePage,
    seoTemplatePhoto: dispatch.seoTemplatePhoto,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplatePageListScreen);
