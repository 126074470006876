import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import * as serviceWorker from './serviceWorker';
import store, { history } from 'store/config';
import App from './app';

import 'moment/locale/ru';
import 'antd/dist/antd.css';
import 'assets/css/index.css';


function render(Component) {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Component/>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    );
}

render(App);


if (module.hot) {
    module.hot.accept('./app', () => {
        const NextApp = require('./app').default;
        render(NextApp);
    })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
