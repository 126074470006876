import React, { Fragment } from 'react';
import { Route } from 'react-router';

import SeoExtDomainListScreen from './SeoExtDomainListScreen';
import SeoExtDomainDetailScreen from './SeoExtDomainDetailScreen';


export default () => {
  return (
    <Fragment>
      <Route exact path="/extlink" component={SeoExtDomainListScreen}/>
      <Route exact path="/extlink/:seoExtDomainId" component={SeoExtDomainDetailScreen}/>
      <Route exact path="/extlink/:seoExtDomainId/sites" component={SeoExtDomainDetailScreen}/>
      <Route exact path="/extlink/:seoExtDomainId/templatepages" component={SeoExtDomainDetailScreen}/>
      <Route exact path="/extlink/:seoExtDomainId/pages" component={SeoExtDomainDetailScreen}/>
      <Route exact path="/extlink/:seoExtDomainId/ankors" component={SeoExtDomainDetailScreen}/>
    </Fragment>
  );
}
