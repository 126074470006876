import React, { useCallback, useState } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton, DateTimeText, Props, OnOff } from 'components/common';
import { CityValue, CitySelect } from 'components/blocks/City';
import { SiteValue, SiteSelect } from 'components/blocks/Site';
import { UserValue, UserSelect } from 'components/blocks/User';
import { SiteSyncValue } from 'components/blocks/SiteSync';
import ProfileValue from "../ProfileValue";
import ProfileStatus from "../ProfileStatus";
import ProfilePublishStatus from "../ProfilePublishStatus";
import ProfileChoices from "../ProfileChoices";
import styles from './list.module.css';


const ProfileList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, profile }) => {
  const defaultColumnList = ['id', 'name', 'parameters', 'sites', 'status', 'date_up', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }

  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);

  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 69,
    }),
    name: tableColumn('Имя', 'name', {
      render: (val, item) => <ProfileValue detail={item} asLink={true} isBack={true}/>,
      sorter: true,
      width: 200,
    }),
    parameters: tableColumn('Параметры', 'parameters', {
      render: (val, item) => (
        <Props 
          className={styles.props}
          choices={ProfileChoices.PARAMETERS} 
          getParametetsValue={ProfileChoices.getParametetsValue}
          info={val} 
          column={1}
        />
      ),
      sorter: false,
      width: 200,
    }),
    sites: tableColumn('Сайты', 'sites', {
      render: (val, item) => (
        <div className={styles.sites}>
          <div className={styles.sitesCity}>
            <span className={styles.sitesCityLabel}>Город:</span>
            <CityValue detail={item.city} asLink={true} isBack={true}/>
          </div>
          <div className={styles.sitesList}>
          <span className={styles.sitesListLabel}>Сайты:</span>
            {item.sites.map(site => (
              <div key={`site${site.id}`}>
                <SiteValue detail={site} asLink={true} isBack={true}/>
              </div>
            ))} 
          </div>
          <div className={styles.sitesExternalSite}>
            <span className={styles.sitesExternalSiteLabel}>Внешний сайт:</span>
            {item.site_sync ? 
              <SiteSyncValue detailId={item.site_sync} asLink={true} isBack={true}/>
              : 
              '-'
            }
          </div>  
        </div>
      ),
      sorter: true,
    }),
    status: tableColumn('Статус', 'status', {
      render: (val, item) => (
        <div>
          <ProfileStatus className={styles.status} status={val}/>
          <ProfilePublishStatus className={styles.status} isActive={item.is_active}/>
          <OnOff
            item={item}
            updateItemId={itemIdForUpdate}
            param="is_on"
            updateStatus={profile.updateStatus}
            preventLoadDetail={true}
            onChange={data => {
              setItemIdForUpdate(data.id);
              actions.profile.update(data);
            }}
          />
        </div>
      ),
      sorter: true,
      width: 120,
    }),
    date_up: tableColumn('Дата поднятия', 'date_up', {
      render: (val, item) => <DateTimeText className={styles.date} date={val}/>,
      sorter: true,
      width: 112,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      width: 200,
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.profile}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
          buttonActions={[{ name: 'copy', size: 'small' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = profile.filter.activeFilter
    }
    actions.profile.listLoad({ query, activeFilter, locationChange: true });
  }, [profile, actions]);

  return (
    <TableList
      location={router.location}
      model={profile}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      filterFields={{
        list: [
          'is_active', 'is_verified', 'has_video', 'status', 'sites', 'city', 'user',
          'prices.in_hour', 'prices.in_hour2', 'prices.in_night', 'prices.in_express',
          'prices.out_hour', 'prices.out_hour2', 'prices.out_night', 'prices.out_express',
          'parameters.gender', 'parameters.ethnicity', 'parameters.age', 'parameters.weight', 'parameters.height',
          'parameters.breast_type', 'parameters.breast_size', 'parameters.hair_color'
        ],
        byName: {
          'user': {
            label: 'User',
            type: 'select_int',
            component: UserSelect,
            valueComponent: UserValue,
          },
          'city': {
            label: 'Город',
            type: 'select_int',
            component: CitySelect,
            valueComponent: CityValue,
          },
          'sites': {
            label: 'Сайт',
            type: 'select_int',
            component: SiteSelect,
            valueComponent: SiteValue,
          },
          'status': {
            label: 'Статус',
            type: 'select',
            valueComponent: ProfileStatus,
            options: [
              { label: 'новая', value: '0' },
              { label: 'на проверке', value: '1' },
              { label: 'проверена', value: '3' },
              { label: 'отклонена', value: '4' },
              { label: 'забанена', value: '5' },
            ]
          },
          'is_active': {
            label: 'Опубликована',
            type: 'bool',
          },
          'is_verified': {
            label: 'Проверенные фото',
            type: 'bool',
          },
          'has_video': {
            label: 'С видео',
            type: 'bool',
          },
          'prices.in_hour': {
            label: 'Апартаменты час',
            type: 'checkbox',
            options: [
              { label: '0-1500', value: '(0-1500)' },
              { label: '1501-2000', value: '(1501-2000)' },
              { label: '2001-2500', value: '(2001-2500)' },
              { label: '2501-3000', value: '(2501-3000)' },
              { label: '3001-', value: '(3001-)' },
            ]
          },
          'prices.in_hour2': {
            label: 'Апартаменты 2 часа',
            type: 'checkbox',
            options: [
              { label: '0-4000', value: '(0-4000)' },
              { label: '4001-6000', value: '(4001-6000)' },
              { label: '6001-', value: '(6001-)' },
            ]
          },
          'prices.in_night': {
            label: 'Апартаменты ночь',
            type: 'checkbox',
            options: [
              { label: '0-10000', value: '(0-10000)' },
              { label: '10001-20000', value: '(10001-20000)' },
              { label: '20001-', value: '(20001-)' },
            ]
          },
          'prices.in_express': {
            label: 'Апартаменты экспресс',
            type: 'checkbox',
            options: [
              { label: '0-1500', value: '(0-1500)' },
              { label: '1501-2500', value: '(1501-2500)' },
              { label: '2501-', value: '(2501-)' },
            ]
          },
          'prices.out_hour': {
            label: 'Выезд час',
            type: 'checkbox',
            options: [
              { label: '0-1500', value: '(0-1500)' },
              { label: '1501-2000', value: '(1501-2000)' },
              { label: '2001-2500', value: '(2001-2500)' },
              { label: '2501-3000', value: '(2501-3000)' },
              { label: '3001-', value: '(3001-)' },
            ]
          },
          'prices.out_hour2': {
            label: 'Выезд 2 часа',
            type: 'checkbox',
            options: [
              { label: '0-4000', value: '(0-4000)' },
              { label: '4001-6000', value: '(4001-6000)' },
              { label: '6001-', value: '(6001-)' },
            ]
          },
          'prices.out_night': {
            label: 'Выезд ночь',
            type: 'checkbox',
            options: [
              { label: '0-10000', value: '(0-10000)' },
              { label: '10001-20000', value: '(10001-20000)' },
              { label: '20001-', value: '(20001-)' },
            ]
          },
          'prices.out_express': {
            label: 'Выезд экспресс',
            type: 'checkbox',
            options: [
              { label: '0-1500', value: '(0-1500)' },
              { label: '1501-2500', value: '(1501-2500)' },
              { label: '2501-', value: '(2501-)' },
            ]
          },

          'parameters.gender': {
            label: 'Пол',
            type: 'checkbox',
            options: [
              { label: 'Женский', value: 'female' },
              { label: 'Мужской', value: 'male' },
              { label: 'Транс', value: 'trans' },
            ]
          },
          'parameters.ethnicity': {
            label: 'Внешность',
            type: 'checkbox',
            options: [
              { label: 'Славянский', value: 'slavic' },
              { label: 'Европейский', value: 'european' },
              { label: 'Восточный', value: 'arabian' },
              { label: 'Азиатский', value: 'asian' },
              { label: 'Африканский', value: 'ebony' },
              { label: 'Индийский', value: 'indian' },
              { label: 'Кавказский', value: 'caucasian' },
              { label: 'Латинский', value: 'latin' },
              { label: 'Смешанный', value: 'mixed' },
            ]
          },
          'parameters.age': {
            label: 'Возраст',
            type: 'checkbox',
            options: [
              { label: '18-20', value: '(18-20)' },
              { label: '21-25', value: '(21-25)' },
              { label: '26-29', value: '(26-29)' },
              { label: '30-39', value: '(30-39)' },
              { label: '> 40', value: '(40-)' },
            ]
          },
          'parameters.weight': {
            label: 'Вес',
            type: 'checkbox',
            options: [
              { label: '< 45', value: '(-45)' },
              { label: '46-50', value: '(46-50)' },
              { label: '51-55', value: '(51-55)' },
              { label: '56-60', value: '(56-60)' },
              { label: '61-65', value: '(61-65)' },
              { label: '66-70', value: '(66-70)' },
              { label: '> 71', value: '(71-)' },
            ]
          },
          'parameters.height': {
            label: 'Рост',
            type: 'checkbox',
            options: [
              { label: '< 155', value: '(-155)' },
              { label: '156-160', value: '(156-160)' },
              { label: '161-165', value: '(161-165)' },
              { label: '166-170', value: '(166-170)' },
              { label: '171-175', value: '(171-175)' },
              { label: '176-180', value: '(176-180)' },
              { label: '> 181', value: '(181-)' },
            ]
          },
          'parameters.breast_type': {
            label: 'Тип груди',
            type: 'checkbox',
            options: [
              { label: 'Натуральная', value: 'Natural' },
              { label: 'Силиконовая', value: 'Silicon' },
            ]
          },
          'parameters.breast_size': {
            label: 'Размер груди',
            type: 'checkbox',
            options: [
              { label: '1', value: 'A' },
              { label: '2', value: 'B' },
              { label: '3', value: 'C' },
              { label: '4', value: 'D' },
              { label: '5', value: 'E' },
              { label: '6', value: 'F' },
              { label: '7+', value: 'G' },
            ]
          },
          'parameters.hair_color': {
            label: 'Цвет волос',
            type: 'checkbox',
            options: [
              { label: 'Светлые', value: 'blonde' },
              { label: 'Каштановые', value: 'brown' },
              { label: 'Чёрные', value: 'black' },
              { label: 'Красные', value: 'red' },
              { label: 'Другие', value: 'other' },
            ]
          },
        }
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  profile: state.profile,
});

const mapDispatch = dispatch => ({
  actions: {
    profile: dispatch.profile,
  }
});

export default connect(mapState, mapDispatch)(ProfileList);
