import React, { useCallback, useState } from 'react';
import { Switch } from "antd";
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton, DateTimeText } from 'components/common';
import UserValue from "../UserValue";


const UserList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, user }) => {
  const defaultColumnList = ['id', 'email', 'date_joined', 'is_active', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const columnByName = {
      id: tableColumn('ID', 'id', {
        sorter: true,
        width: 59,
      }),
      email: tableColumn('E-mail', 'email', {
        render: (val, item) => <UserValue detail={item} asLink={true} isBack={true}/>,
        sorter: true,
      }),
      date_joined: tableColumn('Дата регистрации', 'date_joined', {
        render: (val, item) => <DateTimeText date={val}/>,
        sorter: true,
        width: 150,
      }),
      is_active: tableColumn('Активен', 'is_active', {
        sorter: true,
        width: 150,
        render: (val, item) =>
          <div>
            <Switch
              style={{ marginTop: '5px', minWidth: '62px' }}
              checkedChildren="вкл" unCheckedChildren="выкл"
              checked={item.is_active}
              loading={user.updateStatus.isLoading && itemIdForUpdate === item.id}
              onClick={() => onUserOnOff(item)}
            />
          </div>,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            detail={item}
            modelActions={actions.user}
            actions={[{ name: 'edit' }, { name: 'delete' }]}
          />
        ),
      }),
    };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = user.filter.activeFilter
    }
    actions.user.listLoad({ query, activeFilter, locationChange: true });
  }, [user, actions]);

  const onUserOnOff = (item) => {
    const data = { id: item.id, is_active: !item.is_active, preventLoadDetail: true };
    setItemIdForUpdate(item.id);
    actions.user.update(data);
  };

  return (
    <TableList
      location={router.location}
      model={user}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      filterFields={{
        list: [
          'is_active',
        ],
        byName: {
          'is_active': {
            label: 'Активно',
            type: 'bool',
          },
        }
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  user: state.user,
});

const mapDispatch = dispatch => ({
  actions: {
    user: dispatch.user,
  }
});

export default connect(mapState, mapDispatch)(UserList);
