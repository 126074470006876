import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import { SiteForm } from 'components/blocks/Site';
import DetailScreen from './DetailScreen';
import DomainListScreen from './DomainListScreen';
import SiteNavListScreen from './SiteNavListScreen';
import ProfileListScreen from './ProfileListScreen';
import SeoTemplatePageListScreen from './SeoTemplatePageListScreen';
import SeoTemplatePageDetailScreen from './SeoTemplatePageDetailScreen';
import PageListScreen from './PageListScreen';
import PageDetailScreen from './PageDetailScreen';


const SiteDetailScreen = ({ history, site, actions, match }) => {
  useEffect(() => {
    actions.site.detailLoad({ id: match.params.siteId });
  }, [match.params.siteId, actions.site]);

  const id = match.params.siteId;
  const detail = site.detail || {};

  return (
    <Page
      isLoading={site.loadDetailStatus.isLoading || site.updateStatus.isLoading}
      detail={detail}
      title={detail.domain_domain}
      modelActions={actions.site}
      breadcrumbs={[
        { title: 'Сайты', path: site.urls.list() },
        { title: detail.domain_domain, path: site.urls.detail(id) },
      ]}
      actionByName={{
        publish: (modelActions, item) => ({
          title: 'Опубликовать',
          action: () => actions.site.publish(item),
        }),
      }}
      actions={[
        { mode: 'button', name: 'publish' },
        {
          mode: 'group',
          actions: [
            { mode: 'button', name: 'edit' },
            { mode: 'button', name: 'delete' },
          ]
        },
      ]}
      basePath={site.urls.detail(id)}
      tabs={[
        { title: 'Основное', key: 'detail' },
        { title: 'Домены', key: 'domain' },
        { title: 'Навигация', key: 'nav' },
        { title: 'Seo', key: 'seo' },
        { title: 'Страницы', key: 'page' },
        { title: 'Анкеты', key: 'profile' },
        { title: 'Статьи', key: 'article' },
      ]}
    >
      <Route exact path="/site/:siteId" component={DetailScreen} />
      <Route exact path="/site/:siteId/domain" component={DomainListScreen} />
      <Route exact path="/site/:siteId/nav" component={SiteNavListScreen} />
      <Route exact path="/site/:siteId/seo" component={SeoTemplatePageListScreen} />
      <Route exact path="/site/:siteId/seo/:seoType" component={SeoTemplatePageListScreen} />
      <Route exact path="/site/:siteId/seo/tpl/:seoTemplatePageId" component={SeoTemplatePageDetailScreen} />
      <Route exact path="/site/:siteId/page" component={PageListScreen} />
      <Route exact path="/site/:siteId/page/:pageId" component={PageDetailScreen} />
      <Route exact path="/site/:siteId/profile" component={ProfileListScreen} />

      <SiteForm />
    </Page>
  );
};

const mapState = state => ({
  site: state.site,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(SiteDetailScreen);
