import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import { DomainForm } from 'components/blocks/Domain';
import DetailScreen from './DetailScreen';


const DomainDetailScreen = ({ history, domain, actions, match }) => {
  useEffect(() => {
    actions.domain.detailLoad({ id: match.params.domainId });
  }, [match.params.domainId, actions.domain]);

  const id = match.params.domainId;
  const detail = domain.detail || {};

  return (
    <Page
      isLoading={domain.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.domain}
      modelActions={actions.domain}
      breadcrumbs={[
        { title: 'Домены', path: domain.urls.list() },
        { title: detail.domain, path: domain.urls.detail(id) },
      ]}
      actions={[
        {
          mode: 'group',
          actions: [
            { mode: 'button', name: 'edit' },
            { mode: 'button', name: 'delete' },
          ]
        },
      ]}
      basePath={domain.urls.detail(id)}
    >
      <Route exact path="/domain/:domainId" component={DetailScreen}/>

      <DomainForm/>
    </Page>
  );
};

const mapState = state => ({
  domain: state.domain,
});

const mapDispatch = dispatch => ({
  actions: {
    domain: dispatch.domain,
  }
});

export default connect(mapState, mapDispatch)(DomainDetailScreen);
