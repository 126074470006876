import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

import { tableColumn } from "utils";
import { TableList, ActionButton, DateTimeText } from 'components/common';
import PageType from '../PageType';
import PageValue from '../PageValue';
import PageObjectValue from '../PageObjectValue';


const PageList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, page }) => {
  const defaultColumnList = ['path', 'title', 'type', 'seo_template', 'date_changed', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    path: tableColumn('Путь', 'path', {
      render: (val, item) => <PageValue detail={item} asLink={true} isBack={true} />,
      sorter: true,
    }),
    title: tableColumn('Тайтл', 'title', {
      sorter: true,
    }),
    type: tableColumn('Тип', 'type', {
      render: (val, item) => (
        <div>
          <PageType value={val}/>

          <PageObjectValue type={val} obj={item.obj}/>
        </div>
      ),
      sorter: true,
      width: 200,
    }),
    seo_template: tableColumn('Шаблон', 'seo_template', {
      render: (val, item) => {
        if (item.seo_template_off) {
          return <CloseCircleFilled style={{color: '#ff0000'}}/>;
        }
        if (item.seo_template) {
          return <CheckCircleFilled style={{color: '#00ff00'}}/>;
        }
        return '-';
      },
      sorter: true,
    }),
    date_changed: tableColumn('Дата изменения', 'date_changed', {
      render: (val, item) => <DateTimeText date={val}/>,
      sorter: true,
      width: 112,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.page}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = page.filter.activeFilter
    }
    actions.page.listLoad({ 
      query, activeFilter, locationChange: true, locationState: router.location.state
    });
  }, [page, actions, router.location.state]);

  return (
    <TableList
      location={router.location}
      model={page}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      filterFields={{
        list: [
          'type',
        ],
        byName: {
          'type': {
            label: 'Тип',
            type: 'select',
            valueComponent: PageType,
            options: Object.keys(PageType.TYPE).map(v => ({ label: PageType.TYPE[v], value: v })),
          },
        }
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  page: state.page,
});

const mapDispatch = dispatch => ({
  actions: {
    page: dispatch.page,
  }
});

export default connect(mapState, mapDispatch)(PageList);
