import React from 'react';
import { Switch, Route } from 'react-router';
import { Layout } from 'antd';

import { Navigation } from 'components/layout';
import DashboardScreen from 'screens/Dashboard';
import UserScreen from 'screens/User';
import ServerScreen from 'screens/Server';
import DomainScreen from 'screens/Domain';
import SiteScreen from 'screens/Site';
import CityScreen from 'screens/City';
import TemplateScreen from 'screens/Template';
import ServiceScreen from 'screens/Service';
import ProfileScreen from 'screens/Profile';
import SiteSyncScreen from 'screens/SiteSync';
import SyncPublicationScreen from 'screens/SyncPublication';
import SeoTemplateCollectionScreen from 'screens/SeoTemplateCollection';
import SeoExtDomainScreen from 'screens/SeoExtDomain';


const MainScreen = () => {
  return (
    <Layout>
      <Layout.Sider
        breakpoint="lg"
        collapsedWidth="0"
        className="app-layout-sider"
      >
        <div className="logo"/>
        <Navigation/>
      </Layout.Sider>
      <Layout className="app-layout">
        <Switch>
          <Route exact path="/" component={DashboardScreen}/>

          <Route exact path="/user" component={UserScreen}/>
          <Route path="/user/:userId" component={UserScreen}/>

          <Route exact path="/server" component={ServerScreen}/>
          <Route path="/server/:serverId" component={ServerScreen}/>

          <Route exact path="/domain" component={DomainScreen}/>
          <Route path="/domain/:domainId" component={DomainScreen}/>

          <Route exact path="/site" component={SiteScreen}/>
          <Route path="/site/:siteId" component={SiteScreen}/>

          <Route exact path="/city" component={CityScreen}/>
          <Route path="/city/:cityId" component={CityScreen}/>

          <Route exact path="/template" component={TemplateScreen}/>
          <Route path="/template/:templateId" component={TemplateScreen}/>

          <Route exact path="/service" component={ServiceScreen}/>

          <Route exact path="/profile" component={ProfileScreen}/>
          <Route path="/profile/status/:status" component={ProfileScreen}/>
          <Route path="/profile/:profileId" component={ProfileScreen}/>

          <Route exact path="/siteSync" component={SiteSyncScreen}/>
          <Route path="/siteSync/:siteSyncId" component={SiteSyncScreen}/>

          <Route exact path="/publication" component={SyncPublicationScreen}/>
          <Route path="/publication/:syncPublicationId" component={SyncPublicationScreen}/>

          <Route exact path="/seocollection" component={SeoTemplateCollectionScreen}/>
          <Route exact path="/seocollection/:seoType" component={SeoTemplateCollectionScreen}/>
          <Route path="/seocollection/:seoType/:collectionId" component={SeoTemplateCollectionScreen}/>

          <Route exact path="/extlink" component={SeoExtDomainScreen}/>
          <Route path="/extlink/:seoExtDomainId" component={SeoExtDomainScreen}/>
        </Switch>
      </Layout>
    </Layout>
  );
};

export default MainScreen;
