import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";
import { LockOutlined } from "@ant-design/icons";

import { Page } from 'components/layout';
import { UserForm, PasswordChangeForm } from 'components/blocks/User';
import DetailScreen from './DetailScreen';


const UserDetailScreen = ({ history, user, actions, match }) => {
  useEffect(() => {
    actions.user.detailLoad({ id: match.params.userId });
  }, [match.params.userId, actions.user]);

  const id = match.params.userId;
  const detail = user.detail || {};

  return (
    <Page
      isLoading={user.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.email}
      modelActions={actions.user}
      breadcrumbs={[
        { title: 'Пользователи', path: user.urls.list() },
        { title: detail.email, path: user.urls.detail(id) },
      ]}
      actions={[
        {
          mode: 'group',
          actions: [
            { mode: 'button', name: 'edit' },
            { mode: 'button', name: 'changePassword' },
            { mode: 'button', name: 'delete' },
          ]
        },
      ]}
      actionByName={{
        changePassword: (modelActions, item) => ({
          title: 'Сменить пароль',
          icon: <LockOutlined/>,
          action: () => actions.user.showPasswordForm({ show: true, object: item }),
        }),
      }}
      basePath={user.urls.detail(id)}
    >
      <Route exact path="/user/:userId" component={DetailScreen}/>

      <UserForm/>
      <PasswordChangeForm/>
    </Page>
  );
};

const mapState = state => ({
  user: state.user,
});

const mapDispatch = dispatch => ({
  actions: {
    user: dispatch.user,
  }
});

export default connect(mapState, mapDispatch)(UserDetailScreen);
