import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SeoExtDomainForm = (props) => {
  const { showEditForm } = props.seoExtDomain;

  const onClose = () => {
    props.actions.seoExtDomain.showEditForm({ show: false });
  };

  const onSubmit = data => {
    console.log(data);
    props.actions.seoExtDomain.update(data);
  };

  return (
    <Form
      width={960}
      title="Редактирование внешнего домена"
      name="seoExtDomainForm"
      model={props.seoExtDomain}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({
        ...editObject, 
      })}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  seoExtDomain: state.seoExtDomain,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtDomain: dispatch.seoExtDomain,
  }
});

export default connect(mapState, mapDispatch)(SeoExtDomainForm);

