import React from 'react';
import { connect } from 'react-redux';

import { Page, Fieldset } from 'components/layout';
import { SiteSyncList, SiteSyncForm } from 'components/blocks/SiteSync';


const SiteSyncListScreen = ({ history, siteSync, actions }) => {
  return (
    <Page
      title="Синхронизации"
      modelActions={actions.siteSync}
      breadcrumbs={[
        { title: 'Синхронизации', path: siteSync.urls.list() },
      ]}
      actions={[
        { mode: 'button', name: 'add', type: 'primary', title: 'Добавить сайт' },
      ]}
    >
      <Fieldset>
        <SiteSyncList/>
      </Fieldset>

      <SiteSyncForm/>
    </Page>
  );
};

const mapState = state => ({
  siteSync: state.siteSync,
});

const mapDispatch = dispatch => ({
  actions: {
    siteSync: dispatch.siteSync,
  }
});

export default connect(mapState, mapDispatch)(SiteSyncListScreen);
