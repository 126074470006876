import { push } from "connected-react-router";

import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/service/`,
  detail: (id) => `${BASE_URL}/api/v1/service/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/service/${id}/history/`,
};

export const serviceListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const serviceDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const serviceUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const serviceDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const serviceHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });


export const service = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Услуги',
    urls: {
      list: () => `/service`,
      detail: () => `/service`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'service', serviceListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'service', serviceDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'service', serviceUpdate,
        async (data) => {
          dispatch['service'].showEditForm({ show: false });
          const filter = rootState['serviceGroup'].filter;
          await dispatch['serviceGroup'].listLoad(filter);
        }
      );
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'service', serviceDelete,
        async (data) => {
          dispatch(push(rootState['service'].urls.list()));
          const filter = rootState['serviceGroup'].filter;
          await dispatch['serviceGroup'].listLoad(filter);
        }
      );
    },
  }),
}