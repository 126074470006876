import { push } from "connected-react-router";

import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/district/`,
  detail: (id) => `${BASE_URL}/api/v1/district/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/district/${id}/history/`,
};

export const districtListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const districtDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const districtUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const districtDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const districtHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });


export const district = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Города',
    urls: {
      list: (cityId) => `/city/${cityId}`,
      detail: (cityId, id) => `/city/${cityId}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'district', districtListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'district', districtDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'district', districtUpdate,
        async (data) => {
          dispatch['district'].showEditForm({ show: false });
          const filter = rootState['district'].filter;
          await dispatch['district'].listLoad(filter);
        }
      );
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'district', districtDelete,
        async (data) => {
          dispatch(push(rootState['district'].urls.list(data.city)));
          const filter = rootState['district'].filter;
          await dispatch['district'].listLoad(filter);
        }
      );
    },
  }),
}