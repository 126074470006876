import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from "connected-react-router";
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { GlobalOutlined, ClusterOutlined, UserOutlined } from '@ant-design/icons';

import './styles.css';


class Navigation extends Component {
  /*navigate = ({ item, key, keyPath }) => {
    if (key === 'dashboard') {
      this.props.actions.router.push(`/`);
    } else {
      this.props.actions.router.push(`/${key}`);
    }
  };*/

  getCurrentRootNav() {
    const { pathname } = this.props.router.location;
    return pathname.split('/')[1] || 'dashboard';
  }

  render() {
    const currentRootNav = this.getCurrentRootNav();

    return (
      <div className="navigation">
        <div className="navigation-inner">
          <Menu
            theme="dark"
            mode="inline"
            inlineIndent={16}
            defaultOpenKeys={[currentRootNav]}
            defaultSelectedKeys={[currentRootNav]}
            selectedKeys={[currentRootNav]}
          //onClick={this.navigate}
          >
            <Menu.ItemGroup title="Сайты">
              <Menu.Item key="site">
                <ClusterOutlined />
                <Link to="/site">Сайты</Link>
              </Menu.Item>

              <Menu.Item key="domain">
                <GlobalOutlined />
                <Link to="/domain">Домены</Link>
              </Menu.Item>

              <Menu.Item key="service">
                <GlobalOutlined />
                <Link to="/service">Услуги</Link>
              </Menu.Item>

              <Menu.Item key="profile">
                <GlobalOutlined />
                <Link to="/profile">Анкеты</Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup title="Синхронизации">
              <Menu.Item key="siteSync">
                <GlobalOutlined />
                <Link to="/siteSync">Синхронизации</Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup title="Настройки">
              <Menu.Item key="seocollection">
                <UserOutlined />
                <Link to="/seocollection">Коллекция seo-шаблонов</Link>
              </Menu.Item>

              <Menu.Item key="extlink">
                <UserOutlined />
                <Link to="/extlink">Внешние ссылки</Link>
              </Menu.Item>

              <Menu.Item key="city">
                <UserOutlined />
                <Link to="/city">Города</Link>
              </Menu.Item>

              <Menu.Item key="server">
                <ClusterOutlined />
                <Link to="/server">Серверы</Link>
              </Menu.Item>

              <Menu.Item key="template">
                <ClusterOutlined />
                <Link to="/template">Шаблоны</Link>
              </Menu.Item>

              <Menu.Item key="user">
                <UserOutlined />
                <Link to="/user">Пользователи</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  router: state.router,
});

const mapDispatch = dispatch => ({
  actions: {
    router: {
      push: (payload) => dispatch(push(payload)),
    },
  }
});

export default connect(mapState, mapDispatch)(Navigation);
