import React, { Fragment } from 'react';
import { InputNumber, Select } from "antd";

import { FormItem } from 'utils';
import { SiteSelect } from 'components/blocks/Site';
import SeoTemplatePageType from '../../SeoTemplatePageType';


const MainFieldSet = (props) => {
  const { form, seoTemplatePage, ...otherProps } = props;

  return (
    <Fragment>
      <div className="form-block">
        <SiteSelect {...otherProps} name="site" label="сайт" required={true} disabled={true}/>

        <FormItem {...otherProps} name="type" label="Тип" required={true}>
          <Select style={{ width: '100%' }} disabled={true}>
            {Object.keys(SeoTemplatePageType.TYPE).map(key => (
              <Select.Option key={`type${key}`} value={key}>
                {SeoTemplatePageType.TYPE[key]}
              </Select.Option>
            ))}
            
          </Select>
        </FormItem>

        <FormItem {...otherProps} name="count" label="Количество" required={true}>
          <InputNumber size="large"/>
        </FormItem>
      </div>
    </Fragment>
  );
};

export default MainFieldSet;
