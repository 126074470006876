import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import { SiteSyncForm } from 'components/blocks/SiteSync';
import DetailScreen from './DetailScreen';


const SiteSyncDetailScreen = ({ history, siteSync, actions, match }) => {
  useEffect(() => {
    actions.siteSync.detailLoad({ id: match.params.siteSyncId });
  }, [match.params.siteSyncId, actions.siteSync]);

  const id = match.params.siteSyncId;
  const detail = siteSync.detail || {};

  return (
    <Page
      isLoading={siteSync.loadDetailStatus.isLoading}
      detail={detail}
      title={detail?.city?.name}
      modelActions={actions.siteSync}
      breadcrumbs={[
        { title: 'Синхронизации', path: siteSync.urls.list() },
        { title: detail?.city?.name, path: siteSync.urls.detail(id) },
      ]}
      actions={[
        {
          mode: 'group',
          actions: [
            { mode: 'button', name: 'edit' },
            { mode: 'button', name: 'delete' },
          ]
        },
      ]}
      basePath={siteSync.urls.detail(id)}
      /*tabs={[
        { title: 'Основное', key: 'detail' },
        { title: 'Импорты', key: 'imports' },
      ]}*/
    >
      <Route exact path="/siteSync/:siteSyncId" component={DetailScreen}/>

      <SiteSyncForm/>
    </Page>
  );
};

const mapState = state => ({
  siteSync: state.siteSync,
});

const mapDispatch = dispatch => ({
  actions: {
    siteSync: dispatch.siteSync,
  }
});

export default connect(mapState, mapDispatch)(SiteSyncDetailScreen);
