import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/seo/collection/photo/`,
  detail: (id) => `${BASE_URL}/api/v1/seo/collection/photo/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/seo/collection/photo/${id}/history/`,
};

export const seoTemplateCollectionPhotoListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const seoTemplateCollectionPhotoDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const seoTemplateCollectionPhotoUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const seoTemplateCollectionPhotoDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const seoTemplateCollectionPhotoHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });


export const seoTemplateCollectionPhoto = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Коллекция шаблонов',
    urls: {
      list: () => `/seocollection/photo`,
      detail: (id) => `/seocollection/photo/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'seoTemplateCollectionPhoto', seoTemplateCollectionPhotoListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'seoTemplateCollectionPhoto', seoTemplateCollectionPhotoDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'seoTemplateCollectionPhoto', seoTemplateCollectionPhotoUpdate);
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'seoTemplateCollectionPhoto', seoTemplateCollectionPhotoDelete);
    },
  }),
}