import React from 'react';

import { ServerDetail } from 'components/blocks/Server';


const DetailScreen = (props) => {
  return (
    <ServerDetail/>
  )
};

export default DetailScreen;
