import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/profile/`,
  detail: (id) => `${BASE_URL}/api/v1/profile/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/profile/${id}/history/`,
};

export const profileListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const profileDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const profileUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const profileDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const profileHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });


export const profile = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Профили',
    urls: {
      list: () => `/profile`,
      detail: (id) => `/profile/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'profile', profileListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'profile', profileDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'profile', profileUpdate);
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'profile', profileDelete);
    },
  }),
}