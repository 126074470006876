import React, { useMemo, useState } from 'react';
import { Table } from 'antd';

import { getColumns } from "utils";


const TableListSimple = (props) => {
  const { title, dataSource, columnList, columnByName } = props;

  const { orderField, setOrderField } = useState('');

  const columns = useMemo(
    () => getColumns(columnByName, columnList, orderField),
    [columnByName, columnList, orderField]
  );

  const onChange = (pagination, filters, sorter, extra) => {
    let orderField = sorter.field;
    if (sorter.order === 'descend') {
      orderField = '-' + orderField;
    }
    setOrderField(orderField);
  };

  return (
    <Table
      size="small"
      title={title}
      columns={columns}
      dataSource={dataSource}
      rowKey="id"
      pagination={false}
      onChange={onChange}
      {...props}
    />
  )
};

export default TableListSimple;
