import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, OnOff } from 'components/common';
import { CityValue } from 'components/blocks/City';
import SiteSyncStatus from '../SiteSyncStatus'
import SiteSyncError from '../SiteSyncError'


const SiteSyncDetail = ({ siteSync, actions }) => {
  const detail = siteSync.detail || {};

  const itemUpdate = (data) => {
    actions.siteSync.update(data);
  };

  return (
    <Row gutter={16}>
      <Col span={16} style={{ marginBottom: 30 }}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Сайт" span={24}>
              {detail.url}
            </Descriptions.Item>
            <Descriptions.Item label="API токен" span={24}>
              {detail.api_token}
            </Descriptions.Item>
            <Descriptions.Item label="Город">
              <CityValue detail={detail.city} asLink={true} isBack={true} />
            </Descriptions.Item>
            <Descriptions.Item label="Активен">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={siteSync.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8} style={{ marginBottom: 30 }}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item span={24} label="Последняя синхронизация">
              <SiteSyncStatus detail={detail} />
            </Descriptions.Item>

            <Descriptions.Item span={24} label="Последняя ошибка">
              <SiteSyncError detail={detail} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  siteSync: state.siteSync,
});

const mapDispatch = dispatch => ({
  actions: {
    siteSync: dispatch.siteSync,
  }
});

export default connect(mapState, mapDispatch)(SiteSyncDetail);
