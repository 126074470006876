import { push } from "connected-react-router";

import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/site/nav/`,
  detail: (id) => `${BASE_URL}/api/v1/site/nav/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/site/nav/${id}/history/`,
};

export const siteNavListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const siteNavUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const siteNavDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const siteNavHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });


export const siteNav = {
  state: {
    ...base.ListState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Навигация',
    urls: {
      list: (siteId) => `/site/${siteId}/nav`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'siteNav', siteNavListLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'siteNav', siteNavUpdate,
        async (data) => {
          dispatch['siteNav'].showEditForm({ show: false });
          const filter = rootState['siteNav'].filter;
          await dispatch['siteNav'].listLoad(filter);
        }
      );
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'siteNav', siteNavDelete,
        async (data) => {
          dispatch(push(rootState['siteNav'].urls.list(data.site)));
          const filter = rootState['siteNav'].filter;
          await dispatch['siteNav'].listLoad(filter);
        }
      );
    },
  }),
}