import { push } from "connected-react-router";

import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/seo/template/photo/`,
  detail: (id) => `${BASE_URL}/api/v1/seo/template/photo/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/seo/template/photo/${id}/history/`,

  import: `${BASE_URL}/api/v1/seo/template/photo/import/`,
};

export const seoTemplatePhotoListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const seoTemplatePhotoUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const seoTemplatePhotoDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const seoTemplatePhotoHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });
export const seoTemplatePhotoImport = (token, data) => api.Update(endpoints.import, endpoints.import, token, data);


export const seoTemplatePhoto = {
  state: {
    ...base.ListState,
    ...base.EditState,
    ...base.DeleteState,

    showImportForm: false,

    title: 'Шаблоны фото',
    urls: {
      list: (siteId) => `/site/${siteId}/seo/photo`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,

    showImportForm(state, payload) {
      return {
        ...state,
        showImportForm: payload.show,
        editObject: payload.object,
        editPreventLoadDetail: payload.preventLoadDetail,
      };
    },
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'seoTemplatePhoto', seoTemplatePhotoListLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'seoTemplatePhoto', seoTemplatePhotoUpdate,
        async (data) => {
          dispatch['seoTemplatePhoto'].showEditForm({ show: false });
          const filter = rootState['seoTemplatePhoto'].filter;
          await dispatch['seoTemplatePhoto'].listLoad(filter);
        }
      );
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'seoTemplatePhoto', seoTemplatePhotoDelete,
        async (data) => {
          dispatch(push(rootState['seoTemplatePhoto'].urls.list(data.site)));
          const filter = rootState['seoTemplatePhoto'].filter;
          await dispatch['seoTemplatePhoto'].listLoad(filter);
        }
      );
    },

    async import(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'seoTemplatePhoto', seoTemplatePhotoImport,
        async (data) => {
          dispatch['seoTemplatePhoto'].showImportForm({ show: false });
          const filter = rootState['seoTemplatePhoto'].filter;
          await dispatch['seoTemplatePhoto'].listLoad(filter);
        }
      );
    },
  }),
}