import React  from 'react';
import classNames from "classnames";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

import styles from './status.module.css';

const ProfilePublishStatus = ({ isActive, className, size }) => {
  const cx = classNames([
    styles.status,
    isActive && styles.active,
    size && styles[`size--${size}`],
    className,
  ]);

  if (isActive) {
    return (
      <div className={cx}><CheckCircleFilled/> <span>Опубликована</span></div>
    )
  }

  return (
    <div className={cx}><CloseCircleFilled/> <span>Не опубликована</span></div>
  );
};

export default ProfilePublishStatus;
