import React from 'react';
import classNames from 'classnames';

import styles from './error.module.css';


const Error = ({ className, errors }) => {
  if (!errors) return null;

  const cx = classNames([
    styles.error,
    className,
  ]);

  return (
    <div className={cx}>{errors}</div>
  );
};

export default Error;
