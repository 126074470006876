import React, { useState, useEffect } from 'react';
import { Drawer, Form, Checkbox, Radio, Select } from "antd";
import { CloseOutlined, FilterOutlined } from "@ant-design/icons";

import { FormItem, getUrlSearch, convertFormData } from "utils";

import './filter.css';


const getInstanceFromQueryParams = (fields, params) => {
  return fields.list.reduce((result, fieldName) => {
    let value = params[fieldName];
    if (value === undefined) {
      value = params;
      fieldName.split('.').forEach(n => {
          value = value && value[n];
      });
    }

    const field = fields.byName[fieldName];
    const fieldType = field && field.type;


    switch (fieldType) {
      case 'bool':
        if (!value) {
          value = '';
        }
        break;
      case 'int':
        value = parseInt(value);
        break;
      case 'checkbox':
      case 'radio':
      case 'select':
        if (!Array.isArray(value)) {
          value = [value];
        }
        break;
      case 'select_int':
        if (Array.isArray(value)) {
          value = value.map(v => parseInt(v));
        } else if (value) {
          value = [parseInt(value)];
        }
        break;
      default:
        break
    }
    result[fieldName] = value;
    return result;
  }, {});
};


const Filter = ({ fields, data, onLoadData }) => {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState(null);
  const [instance, setInstance] = useState({});

  const onToggle = () => {
    setVisible(!visible);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (onLoadData) {
      let params = Object.assign({}, data.filter.params);      
      const allValues2 = convertFormData(allValues);

      Object.keys(allValues2).forEach(fieldName => {
        let value = allValues2[fieldName];
        if (value !== undefined) {
          params[fieldName] = value;
        }
      });

      const query = '?' + getUrlSearch(params);
      onLoadData({ query, activeFilter: data.activeFilter });
    }
  };

  useEffect(() => {
    if (visible && query !== data.filter.query) {
      setQuery(data.filter.query);
      const inst = getInstanceFromQueryParams(fields, data.filter.params);
      setInstance(inst)
    } else if (visible) {
      form.setFieldsValue(instance);
    }
  }, [form, visible, instance, query, fields, data.filter.query, data.filter.params]);

  return (
    <Drawer
      title="Фильтры"
      visible={visible}
      width={300}
      onClose={onToggle}
      placement="right"
      destroyOnClose={true}
      forceRender={true}
      handler={
        <div className="filter-handle" onClick={onToggle}>
          {visible ? <CloseOutlined/> : <FilterOutlined/>}
        </div>
      }
      style={{
        zIndex: 999,
      }}
    >
      <div className="filter-content">
        <Form form={form} onValuesChange={onValuesChange} layout="vertical">
          {fields.list.map(name => {
            const field = fields.byName[name];
            if (field.component) {
              return (
                <field.component
                  key={name}
                  name={name}
                  label={field.label}
                  activeFilter={field.activeFilter}
                />
              )
            }
            switch (field.type) {
              case 'bool':
                return (
                  <FormItem
                    key={name}
                    name={name}
                    label={field.label}
                  >
                    <Radio.Group initialValue="" buttonStyle="solid">
                      <Radio.Button value="">Все</Radio.Button>
                      <Radio.Button value="true">Да</Radio.Button>
                      <Radio.Button value="false">Нет</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                );
              case 'checkbox':
                return (
                  <FormItem
                    key={name}
                    name={name}
                    label={field.label}
                  >
                    <Checkbox.Group options={field.options}/>
                  </FormItem>
                );
              case 'radio':
                return (
                  <FormItem
                    key={name}
                    form={form}
                    instance={instance}
                    name={name}
                    label={field.label}
                  >
                    <Radio.Group options={field.options} />
                  </FormItem>
                );
              case 'select':
                return (
                  <FormItem
                    key={name}
                    form={form}
                    instance={instance}
                    name={name}
                    label={field.label}
                  >
                    <Select>
                      <Select.Option value={null}>---</Select.Option>
                      {field.options.map(opt => (
                        <Select.Option key={`opt${opt.value}`} value={opt.value}>
                          {opt.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                );
              default:
                return ''

            }
          })}
        </Form>
      </div>
    </Drawer>
  );
};

export default Filter;
