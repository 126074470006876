import React, { Fragment } from 'react';
import { Route } from 'react-router';

import ProfileListScreen from './ProfileListScreen';
import ProfileDetailScreen from './ProfileDetailScreen';


const Profile = () => {
  return (
    <Fragment>
      <Route exact path="/profile" component={ProfileListScreen}/>
      <Route exact path="/profile/status/:status" component={ProfileListScreen}/>
      <Route exact path="/profile/:profileId" component={ProfileDetailScreen}/>
    </Fragment>
  );
}

export default Profile;
