import React, { useCallback, useState } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton, OnOff } from 'components/common';
import SeoTemplatePageType from '../SeoTemplatePageType';
import SeoTemplatePageValue from '../SeoTemplatePageValue';


const SeoTemplatePageList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, seoTemplatePage }) => {
  const defaultColumnList = ['tpl_path', 'tpl_title', 'type', 'links', 'is_active', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    tpl_path: tableColumn('Путь', 'tpl_path', {
      render: (val, item) => <SeoTemplatePageValue detail={item} asLink={true} isBack={true} />,
      sorter: true,
    }),
    tpl_title: tableColumn('Тайтл', 'tpl_title', {
      sorter: true,
    }),
    type: tableColumn('Тип', 'type', {
      render: (val, item) =><SeoTemplatePageType value={item.type}/>,
      sorter: true,
      width: 200,
    }),
    links: tableColumn('Ссылки', 'links', {
      render: (val, item) => (
        <div>
          <div style={{marginBottom: '10px', paddingBottom: '10px', borderBottom: '1px solid #ccc'}}>
            <a target="_blank" rel="noopener noreferrer" href={`/site/${item.site}/page?seo_template=${item.id}`}>Страниц: {item.pages.length}</a>
          </div>
        </div>
      ),
    }),
    is_active: tableColumn('Активно', 'is_active', {
      sorter: false,
      width: 100,
      render: (val, item) => (
        <OnOff
          item={item}
          updateItemId={itemIdForUpdate}
          param="is_active"
          updateStatus={seoTemplatePage.updateStatus}
          preventLoadDetail={true}
          onChange={data => {
            setItemIdForUpdate(data.id);
            actions.seoTemplatePage.update(data);
          }}
        />
      ),
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      width: 200,
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.seoTemplatePage}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
          buttonActions={[{ name: 'copy', size: 'small' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = seoTemplatePage.filter.activeFilter
    }
    actions.seoTemplatePage.listLoad({ 
      query, activeFilter, locationChange: true, locationState: router.location.state
    });
  }, [seoTemplatePage, actions, router.location.state]);

  return (
    <TableList
      location={router.location}
      model={seoTemplatePage}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      filterFields={{
        list: [
          'is_active', 'has_link',
        ],
        byName: {
          'is_active': {
            label: 'Активно',
            type: 'bool',
          },
          'has_link': {
            label: 'Есть ссылки',
            type: 'bool',
          },
        }
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  seoTemplatePage: state.seoTemplatePage,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplatePage: dispatch.seoTemplatePage,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplatePageList);
