import React from 'react';
import { connect } from "react-redux";
import { Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { Fieldset } from "components/layout";
import { ProfileList, ProfileForm } from 'components/blocks/Profile';


const ProfileListScreen = ({ match, profile, actions }) => {
  const siteId = match.params.siteId;

  const hasFilters = Object.keys(profile.filter.params).length > 0;

  const addPage = () => {
    actions.page.showEditForm({
      show: true,
      object: {
        site: parseInt(siteId),
        type: 'page_profiles',
        profile_filter: profile.filter.query,
      },
      additional: { redirectAfterSave: true },
    });
  };

  return (
    <Fieldset>
      <ProfileList
        activeFilter={{ sites: siteId }}
        topActions={hasFilters && [
          <Button
            type="primary"
            icon={<PlusOutlined />} onClick={addPage}
          >
            Создать страницу
          </Button>
        ]}
      />

      <ProfileForm />
    </Fieldset>
  )
};

const mapState = state => ({
  router: state.router,
  profile: state.profile,
});

const mapDispatch = dispatch => ({
  actions: {
    page: dispatch.page,
  }
});

export default connect(mapState, mapDispatch)(ProfileListScreen);
