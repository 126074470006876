import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton } from 'components/common';
import { PageValue } from 'components/blocks/Page';
import SiteNavType from '../SiteNavType';


const SiteNavList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, siteNav }) => {
  const defaultColumnList = ['id', 'name', 'page', 'type', 'sort', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Название', 'name', {
      sorter: true,
    }),
    page: tableColumn('Страница', 'page', {
      render: (val, item) => <PageValue detail={val} asLink={true} isBack={true} />,
    }),
    type: tableColumn('Тип', 'type', {
      render: (val, item) => <SiteNavType value={val}/>,
      sorter: true,
    }),
    sort: tableColumn('Сортировка', 'sort', {
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.siteNav}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = siteNav.filter.activeFilter
    }
    actions.siteNav.listLoad({ 
      query, activeFilter, locationChange: true, locationState: router.location.state
    });
  }, [siteNav, actions, router.location.state]);

  return (
    <TableList
      location={router.location}
      model={siteNav}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  siteNav: state.siteNav,
});

const mapDispatch = dispatch => ({
  actions: {
    siteNav: dispatch.siteNav,
  }
});

export default connect(mapState, mapDispatch)(SiteNavList);
