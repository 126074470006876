import React from 'react'
import classNames from "classnames"

import { DateTimeText } from 'components/common'

import styles from './value.module.css'


const SiteSyncError = ({ detail }) => {
  const errorDate = detail?.sync_error_date;
  if (!errorDate) return '-';
  
  let cls = [styles.container];

  return (
    <div className={classNames(cls)}>
      <DateTimeText className={styles.date} date={errorDate} />
      <span className={styles.status}>{detail?.sync_last_error}</span>
    </div>
  );
};

export default SiteSyncError
