import React from 'react';
import { UserOutlined, FileImageOutlined, CheckCircleFilled } from "@ant-design/icons";
import classNames from 'classnames';

import { getUrl } from 'utils';
import './photo.css';


const Photo = (props) => {
  const { className, style, innerStyle, width, height, src, isAvatar, isVerified } = props;

  const cx = classNames([
    'photo',
    className,
  ]);

  const emptyCx = classNames([
    'photo-empty',
    isAvatar && 'photo-empty--avatar',
  ]);

  const newStyle = Object.assign({
    width: `${width || 100}px`,
    height: `${height || 100}px`,
  }, style || {});

  const newInnerStyle = Object.assign({
    backgroundImage: src ? `url(${getUrl(src)})` : 'none',
  }, innerStyle || {});

  return (
    <div className={cx} style={newStyle}>
      <div className="photo-inner" style={newInnerStyle}>
        {!src &&
        <div className={emptyCx}>
          {isAvatar ? <UserOutlined/>: <FileImageOutlined/>}
        </div>}
        {isVerified && <CheckCircleFilled className="photo-verified"/>}
      </div>
    </div>
  );
};

export default Photo;
