import React from 'react';

import { SeoTemplateCollectionPageDetail } from 'components/blocks/SeoTemplateCollectionPage';
import { SeoTemplateCollectionPhotoDetail } from 'components/blocks/SeoTemplateCollectionPhoto';


const DetailScreen = ({ match }) => {
  const type = match.params.seoType || 'profile';

  const renderDetail = () => {
    switch(type) {
      case 'photo':
        return (
          <SeoTemplateCollectionPhotoDetail/>
        );
      default:
        return (
          <SeoTemplateCollectionPageDetail />
        )
    }
  }

  return renderDetail()
};

export default DetailScreen;
