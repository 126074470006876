import React from 'react';
import { Input } from "antd";

import { FormItem } from 'utils';
import ProfileChoices from '../../ProfileChoices';


const ContactsFieldSet = (props) => {
  const { form, errorData } = props;
  const itemProps = { form, errorData };

  return (
    <div className="form-block">
      {ProfileChoices.CONTACTS.map(item => (
        <FormItem 
          key={item['name']} 
          {...itemProps} 
          name={`contacts.${item['name']}`} 
          label={item['label']} 
          required={false}
        >
          <Input size="large"/>
        </FormItem>
      ))}
    </div>
  );
};

export default ContactsFieldSet;
