import React from 'react';

import { Page } from 'components/layout';


const Dashboard = () => {
  return (
    <Page
      title="Рабочий стол"
      breadcrumbs={[]}
    />
  )
}

export default Dashboard;
