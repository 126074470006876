import React from 'react'
import classNames from "classnames"

import { DateTimeText } from 'components/common'

import styles from './value.module.css'


const SiteSyncStatus = ({ detail }) => {
  const isSyncing = detail?.is_syncing;
  let cls = [styles.container];
  if (isSyncing) {
    cls.push(styles[`status--progress`]);
  } else {
    cls.push(styles[`status--complete`]);
  }

  return (
    <div className={classNames(cls)}>
      <DateTimeText className={styles.date} date={detail?.sync_sucess_date} />
      <span className={styles.status}>{isSyncing ? 'в процессе': 'завершено'}</span>
    </div>
  );
};

export default SiteSyncStatus
