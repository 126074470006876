import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const DistrictForm = (props) => {
  const { showEditForm } = props.district;

  const onClose = () => {
    props.actions.district.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.district.update(data);
  };

  return (
    <Form
      title="Редактирование района"
      name="districtForm"
      model={props.district}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  district: state.district,
});

const mapDispatch = dispatch => ({
  actions: {
    district: dispatch.district,
  }
});

export default connect(mapState, mapDispatch)(DistrictForm);

