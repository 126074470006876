import React from 'react';

import { Fieldset } from "components/layout";
import { SeoExtLinkSiteList } from 'components/blocks/SeoExtLinkSite';


export default ({ match }) => {
  const seoExtDomainId = match.params.seoExtDomainId;

  return (
    <Fieldset>
      <SeoExtLinkSiteList activeFilter={{ ext_link__ext_domain: seoExtDomainId }}/>
    </Fieldset>
  )
};
