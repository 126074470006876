import { init } from '@rematch/core';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import * as models from './models'


export const history = createBrowserHistory();


const store = init({
  redux: {
    reducers: {
      router: connectRouter(history)
    },
    middlewares: [
      routerMiddleware(history)
    ],
    devtoolOptions: {}
  },
  models,
})

export default store;
