import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList } from 'components/common';
import { SiteValue } from 'components/blocks/Site'


const SeoExtLinkSiteList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, seoExtLinkSite }) => {
  const defaultColumnList = ['site'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    site: tableColumn('Сайт', 'site', {
      render: (val, item) => <SiteValue detail={val} asLink={true} isBack={true}/>,
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = seoExtLinkSite.filter.activeFilter
    }
    actions.seoExtLinkSite.listLoad({ query, activeFilter, locationChange: true });
  }, [seoExtLinkSite, actions]);

  return (
    <TableList
      location={router.location}
      model={seoExtLinkSite}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  seoExtLinkSite: state.seoExtLinkSite,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtLinkSite: dispatch.seoExtLinkSite,
  }
});

export default connect(mapState, mapDispatch)(SeoExtLinkSiteList);
