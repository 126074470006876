import React, { useState, useEffect, Fragment } from 'react';
import { Input, Select, Checkbox } from "antd";
import getSlug from 'speakingurl';

import { FormItem } from 'utils';
import { SiteSelect } from 'components/blocks/Site';
import SeoTemplatePageType from '../../SeoTemplatePageType';
import SeoTemplatePartsDropdown, { renderTpl } from '../../SeoTemplatePartsDropdown';


const MainFieldSet = (props) => {
  const { form, seoTemplatePage, ...otherProps } = props;
  const editObject = seoTemplatePage.editObject || {};
  const [extra, setExtra] = useState({});

  const updateExtra = (fieldName, value) => {
    const newExtra = Object.assign({}, extra);
    newExtra[fieldName] = renderTpl(value, editObject.type);
    if (fieldName === 'tpl_path') {
      newExtra[fieldName] = getSlug(newExtra[fieldName]);
    }
    setExtra(newExtra);
  };

  const partAdd = (fieldName, part) => {
    const oldValue = form.getFieldValue(fieldName) || '';
    const newValue = oldValue + part.value;
    form.setFieldsValue({
        [fieldName]: newValue,
    });
    updateExtra(fieldName, newValue);
  };

  useEffect(() => {
    const getHelp = (fieldName, value) => {
      if (!value) return '';
      let help = renderTpl(value, editObject.type);
      if (fieldName === 'tpl_path') {
        help = getSlug(help);
      }
      return help;
    };

    const newExtra = {
      tpl_path: getHelp('tpl_path', editObject.tpl_path),
      tpl_title: getHelp('tpl_title', editObject.tpl_title),
      tpl_description: getHelp('tpl_description', editObject.tpl_description),
      tpl_h1: getHelp('tpl_h1', editObject.tpl_h1),
      tpl_h2: getHelp('tpl_h2', editObject.tpl_h2),
      tpl_text: getHelp('tpl_text', editObject.tpl_text),
      tpl_link_text: getHelp('tpl_link_text', editObject.tpl_link_text),
    }
    
    setExtra(newExtra);
  }, [
    editObject.type, editObject.tpl_path, editObject.tpl_title, 
    editObject.tpl_description, editObject.tpl_h1, editObject.tpl_h2, 
    editObject.tpl_text, editObject.tpl_link_text
  ]);

  return (
    <Fragment>
      <div className="form-block">
        <FormItem {...otherProps} name="is_active" label="Активно" required={false} valuePropName="checked">
          <Checkbox/>
        </FormItem>

        <SiteSelect {...otherProps} name="site" label="сайт" required={true} disabled={true}/>

        <FormItem {...otherProps} name="type" label="Тип" required={true}>
          <Select style={{ width: '100%' }} disabled={true}>
            {Object.keys(SeoTemplatePageType.TYPE).map(key => (
              <Select.Option key={`type${key}`} value={key}>
                {SeoTemplatePageType.TYPE[key]}
              </Select.Option>
            ))}
            
          </Select>
        </FormItem>
        
        <FormItem {...otherProps} name="tpl_path" label="Путь" required={true} extra={extra.tpl_path}>
          <Input 
            size="large"
            onChange={(e) => updateExtra('tpl_path', e.target.value)}
            addonAfter={
              <SeoTemplatePartsDropdown
                  type={editObject.type}
                  onChange={(part) => partAdd('tpl_path', part)}
              />
            }
          />
        </FormItem>
      </div>

      <div className="form-block">
        <FormItem {...otherProps} name="tpl_title" label="Тайтл" required={true} extra={extra.tpl_title}>
          <Input 
            size="large"
            onChange={(e) => updateExtra('tpl_title', e.target.value)}
            addonAfter={
              <SeoTemplatePartsDropdown
                  type={editObject.type}
                  onChange={(part) => partAdd('tpl_title', part)}
              />
            }
          />
        </FormItem>

        <FormItem 
          {...otherProps} 
          name="tpl_description" 
          label={
            <span>
                <span>Дескрипшн</span>
                <span>&nbsp;&nbsp;</span>
                <SeoTemplatePartsDropdown
                    type={editObject.type}
                    onChange={(part) => partAdd('tpl_description', part)}
                />
            </span>
          }
          required={true} 
          extra={extra.tpl_description}
        >
          <Input.TextArea 
            size="large" 
            rows={5} 
            onChange={(e) => updateExtra('tpl_description', e.target.value)}
          />
        </FormItem>
      </div>

      <div className="form-block">
        <FormItem {...otherProps} name="tpl_h1" label="H1" required={true} extra={extra.tpl_h1}>
          <Input 
            size="large"
            onChange={(e) => updateExtra('tpl_h1', e.target.value)}
            addonAfter={
              <SeoTemplatePartsDropdown
                  type={editObject.type}
                  onChange={(part) => partAdd('tpl_h1', part)}
              />
            }
          />
        </FormItem>

        <FormItem {...otherProps} name="tpl_h2" label="H2" required={false} extra={extra.tpl_h2}>
          <Input 
            size="large"
            onChange={(e) => updateExtra('tpl_h2', e.target.value)}
            addonAfter={
              <SeoTemplatePartsDropdown
                  type={editObject.type}
                  onChange={(part) => partAdd('tpl_h2', part)}
              />
            }
          />
        </FormItem>

        <FormItem 
          {...otherProps} 
          name="tpl_text" 
          label={
            <span>
                <span>Текст</span>
                <span>&nbsp;&nbsp;</span>
                <SeoTemplatePartsDropdown
                    type={editObject.type}
                    onChange={(part) => partAdd('tpl_text', part)}
                />
            </span>
          }
          required={false} 
          extra={extra.tpl_text}
        >
          <Input.TextArea 
            size="large" 
            rows={5} 
            onChange={(e) => updateExtra('tpl_text', e.target.value)}
          />
        </FormItem>
      
        <FormItem 
          {...otherProps} 
          name="tpl_link_text" 
          label={
            <span>
                <span>Текст для ссылок</span>
                <span>&nbsp;&nbsp;</span>
                <SeoTemplatePartsDropdown
                    type={editObject.type}
                    onChange={(part) => partAdd('tpl_link_text', part)}
                />
            </span>
          }
          required={false} 
          extra={extra.tpl_link_text}
        >
          <Input.TextArea 
            size="large" 
            rows={5} 
            onChange={(e) => updateExtra('tpl_link_text', e.target.value)}
          />
        </FormItem>

        <FormItem 
          {...otherProps} 
          name="tpl_link_text2" 
          label={
            <span>
                <span>Текст для ссылок2</span>
                <span>&nbsp;&nbsp;</span>
                <SeoTemplatePartsDropdown
                    type={editObject.type}
                    onChange={(part) => partAdd('tpl_link_text2', part)}
                />
            </span>
          }
          required={false} 
          extra={extra.tpl_link_text2}
        >
          <Input.TextArea 
            size="large" 
            rows={5} 
            onChange={(e) => updateExtra('tpl_link_text2', e.target.value)}
          />
        </FormItem>

        <FormItem 
          {...otherProps} 
          name="tpl_link_text3" 
          label={
            <span>
                <span>Текст для ссылок3</span>
                <span>&nbsp;&nbsp;</span>
                <SeoTemplatePartsDropdown
                    type={editObject.type}
                    onChange={(part) => partAdd('tpl_link_text3', part)}
                />
            </span>
          }
          required={false} 
          extra={extra.tpl_link_text3}
        >
          <Input.TextArea 
            size="large" 
            rows={5} 
            onChange={(e) => updateExtra('tpl_link_text3', e.target.value)}
          />
        </FormItem>

        <FormItem 
          {...otherProps} 
          name="tpl_link_text4" 
          label={
            <span>
                <span>Текст для ссылок4</span>
                <span>&nbsp;&nbsp;</span>
                <SeoTemplatePartsDropdown
                    type={editObject.type}
                    onChange={(part) => partAdd('tpl_link_text4', part)}
                />
            </span>
          }
          required={false} 
          extra={extra.tpl_link_text4}
        >
          <Input.TextArea 
            size="large" 
            rows={5} 
            onChange={(e) => updateExtra('tpl_link_text4', e.target.value)}
          />
        </FormItem>

        <FormItem 
          {...otherProps} 
          name="tpl_link_text5" 
          label={
            <span>
                <span>Текст для ссылок5</span>
                <span>&nbsp;&nbsp;</span>
                <SeoTemplatePartsDropdown
                    type={editObject.type}
                    onChange={(part) => partAdd('tpl_link_text5', part)}
                />
            </span>
          }
          required={false} 
          extra={extra.tpl_link_text5}
        >
          <Input.TextArea 
            size="large" 
            rows={5} 
            onChange={(e) => updateExtra('tpl_link_text5', e.target.value)}
          />
        </FormItem>
      </div>
    </Fragment>
  );
};

export default MainFieldSet;
