import React from 'react';
import classNames from "classnames";

import { chunkify } from "utils";
import styles from './props.module.css';


const Props = (props) => {
  const { className, choices, getParametetsValue, info, column } = props;

  const cx = classNames([
    styles.main,
    className,
  ]);

  const col = column || 1;
  const groups = chunkify(choices, col);

  return (
    <div className={cx}>
      {groups.map((choices, idx) => (
        <div key={`group${idx}`} className={styles.group}>
          {choices.map(choice => {
            if (!info[choice.name]) return null;
            return (
              <div key={`${choice.name}`} className={styles.prop}>
                <span className={styles.propLabel}>{choice.label}: </span>
                <span className={styles.propValue}>{getParametetsValue(info, choice.name)}</span>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  );
}

export default Props;
