import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList } from 'components/common';
import { SeoTemplatePageType, SeoTemplatePageValue } from 'components/blocks/SeoTemplatePage'


const SeoExtLinkTemplatePageList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, seoExtLinkTemplatePage }) => {
  const defaultColumnList = ['template_page', 'tpl_title', 'type'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    template_page: tableColumn('Путь', 'template_page', {
      render: (val, item) => <SeoTemplatePageValue detail={item.template_page} asLink={true} isBack={true} />,
    }),
    tpl_title: tableColumn('Тайтл', 'tpl_title', {
      render: (val, item) => item.template_page.tpl_title,
    }),
    type: tableColumn('Тип', 'type', {
      render: (val, item) =>  <SeoTemplatePageType value={item.template_page.type} />
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = seoExtLinkTemplatePage.filter.activeFilter
    }
    actions.seoExtLinkTemplatePage.listLoad({ query, activeFilter, locationChange: true });
  }, [seoExtLinkTemplatePage, actions]);

  return (
    <TableList
      location={router.location}
      model={seoExtLinkTemplatePage}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  seoExtLinkTemplatePage: state.seoExtLinkTemplatePage,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtLinkTemplatePage: dispatch.seoExtLinkTemplatePage,
  }
});

export default connect(mapState, mapDispatch)(SeoExtLinkTemplatePageList);
