import React from 'react';

import { UserTransferField } from 'components/blocks/User';


const FilterFieldSet = (props) => {
  return (
    <div className="form-block">
      <UserTransferField {...props} name="exclude_users" label="пользователи для исключения" required={false}/>
    </div>
  );
};

export default FilterFieldSet;
