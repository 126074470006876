import React  from 'react';
import classNames from "classnames";

import styles from './status.module.css';

const STATUS_NEW = 0;
const STATUS_CHECKING = 1;
const STATUS_CHECKED = 3;
const STATUS_DECLINED = 4;
const STATUS_BANNED = 5;


const STATUS = {
  [STATUS_NEW]: 'новая',
  [STATUS_CHECKING]: 'на проверке',
  [STATUS_CHECKED]: 'проверена',
  [STATUS_DECLINED]: 'отклонена',
  [STATUS_BANNED]: 'забанена',
};

const ProfileStatus = ({ value, status, className, size }) => {
  const _status = value || status;

  const cx = classNames([
    styles.status,
    styles[`s${_status}`],
    size && styles[`size--${size}`],
    className,
  ]);

  return (
    <div className={cx}>{STATUS[_status] || _status}</div>
  );
};

ProfileStatus.STATUS_NEW = STATUS_NEW;
ProfileStatus.STATUS_CHECKING = STATUS_CHECKING;
ProfileStatus.STATUS_CHECKED = STATUS_CHECKED;
ProfileStatus.STATUS_DECLINED = STATUS_DECLINED;
ProfileStatus.STATUS_BANNED = STATUS_BANNED;
ProfileStatus.STATUS = STATUS;

export default ProfileStatus;
