import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SeoExtLinkForm = (props) => {
  const { showEditForm } = props.seoExtLink;

  const onClose = () => {
    props.actions.seoExtLink.showEditForm({ show: false });
  };

  const onSubmit = data => {
    console.log(data);
    props.actions.seoExtLink.update(data);
  };

  return (
    <Form
      width={960}
      title="Редактирование внешней ссылки"
      name="seoExtLinkForm"
      model={props.seoExtLink}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({
        ...editObject, 
      })}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  seoExtLink: state.seoExtLink,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtLink: dispatch.seoExtLink,
  }
});

export default connect(mapState, mapDispatch)(SeoExtLinkForm);

