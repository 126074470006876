import React from 'react';

import { UserDetail } from 'components/blocks/User';


const DetailScreen = (props) => {
  return (
    <UserDetail/>
  )
};

export default DetailScreen;
