import React from 'react';
import { connect } from 'react-redux';
import { PlusOutlined } from "@ant-design/icons";

import { Page, Fieldset } from 'components/layout';
import { ServiceList, ServiceForm, ServiceGroupForm } from 'components/blocks/Service';


const ServiceListScreen = ({ history, service, serviceGroup, actions }) => {
  return (
    <Page
      title="Услуги"
      modelActions={actions.service}
      breadcrumbs={[
        { title: 'Услуги', path: service.urls.list() },
      ]}
      actions={[
        { mode: 'button', name: 'addServiceGroup', type: 'primary' },
        { mode: 'button', name: 'add', type: 'primary', title: 'Добавить услугу' },
      ]}
      actionByName={{
        addServiceGroup: (modelActions, item) => ({
          title: 'Добавить группу услуг',
          icon: <PlusOutlined/>,
          action: () => actions.serviceGroup.showEditForm({show: true}),
        }),
      }}
    >
      <Fieldset>
        <ServiceList/>
      </Fieldset>

      <ServiceForm/>
      <ServiceGroupForm/>
    </Page>
  );
};

const mapState = state => ({
  service: state.service,
  serviceGroup: state.serviceGroup,
});

const mapDispatch = dispatch => ({
  actions: {
    service: dispatch.service,
    serviceGroup: dispatch.serviceGroup,
  }
});

export default connect(mapState, mapDispatch)(ServiceListScreen);
