import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { profileDetailLoad } from "store/models/profile";
import { DetailLoadComponent, Photo } from 'components/common';
import './profileValue.css';


const ProfileValue = (props) => {
  return (
    <DetailLoadComponent
      apiLoad={profileDetailLoad}
      {...props}
      getDetailUrl={(id, item) => props.model.urls.detail(id)}
      render={({ id, item, linkPath }) => {
        const value = (item && item.name) || id;
        const phone = (item && item.phone_clean) || '';
        const userEmail = (item && item.user && item.user.email) || '-';
        const photoUrl = !props.simple && item && item.photos.length && item.photos[0].url;
        const isVerified = (item && item.is_verified) || false;

        return (
          <Fragment>
            {props.asLink && linkPath ?
              <Link className="profileValue" to={linkPath}>
                <span className="profileValueName">{value}</span>
                <span className="profileValuePhone">{phone}</span>
                {!props.simple && <Photo 
                  className="profileValuePhoto" 
                  src={photoUrl} 
                  width={150} 
                  height={150}
                  isVerified={isVerified}
                />}
                <span className="profileValueUser"><span>user:</span> {userEmail}</span>
              </Link> :
              <span>
                <span className="profileValueName">{value}</span>
                <span className="profileValuePhone">{phone}</span>
                {!props.simple && <Photo 
                  className="profileValuePhoto" 
                  src={photoUrl} 
                  width={150} 
                  height={150}
                  isVerified={isVerified}
                />}
                <span className="profileValueUser"><span>user:</span> {userEmail}</span>
              </span>
            }
          </Fragment>
        );
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.profile,
});

export default connect(mapState)(ProfileValue);
