import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { Fieldset } from 'components/layout';
import { Descriptions } from 'components/common';
import { DistrictList } from 'components/blocks/District';


const CityDetail = ({ city, actions }) => {
  const detail = city.detail || {};

  const districtAdd = () => {
    actions.district.showEditForm({ 
      show: true, 
      object: { city: detail.id } 
    });
  };

  return (
    <Row gutter={16}>
      <Col span={16} style={{marginBottom: 30}}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Название" span={24}>{detail.name}</Descriptions.Item>
            <Descriptions.Item label="Путь">{detail.slug}</Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={24}>
        <Fieldset title="Районы">
          <DistrictList
            dataSource={detail.districts}
            activeFilter={{ city: detail.id }}
            topActions={[
              <Button 
                type="primary" 
                icon={<PlusOutlined/>} onClick={districtAdd}
              >
                Добавить район
              </Button>
            ]}
          />
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  city: state.city,
});

const mapDispatch = dispatch => ({
  actions: {
    city: dispatch.city,
    district: dispatch.district,
  }
});

export default connect(mapState, mapDispatch)(CityDetail);
