import React, { Fragment } from 'react';
import { InputNumber } from "antd";

import { FormItem } from 'utils';
import { SiteSelect } from 'components/blocks/Site';


const MainFieldSet = (props) => {
  const { form, seoTemplatePhoto, ...otherProps } = props;

  return (
    <Fragment>
      <div className="form-block">
        <SiteSelect {...otherProps} name="site" label="сайт" required={true} disabled={true}/>

        <FormItem {...otherProps} name="count" label="Количество" required={true}>
          <InputNumber size="large"/>
        </FormItem>
      </div>
    </Fragment>
  );
};

export default MainFieldSet;
