import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import { ServerForm } from 'components/blocks/Server';
import DetailScreen from './DetailScreen';
import SiteListScreen from './SiteListScreen';


const ServerDetailScreen = ({ history, server, actions, match }) => {
  useEffect(() => {
    actions.server.detailLoad({ id: match.params.serverId });
  }, [match.params.serverId, actions.server]);

  const id = match.params.serverId;
  const detail = server.detail || {};

  return (
    <Page
      isLoading={server.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.server}
      breadcrumbs={[
        { title: 'Серверы', path: server.urls.list() },
        { title: detail.name, path: server.urls.detail(id) },
      ]}
      actions={[
        {
          mode: 'group',
          actions: [
            { mode: 'button', name: 'edit' },
            { mode: 'button', name: 'delete' },
          ]
        },
      ]}
      basePath={server.urls.detail(id)}
      tabs={[
        { title: 'Основное', key: 'detail' },
        { title: 'Домены', key: 'domain' },
        { title: 'Сайты', key: 'site' },
      ]}
    >
      <Route exact path="/server/:serverId" component={DetailScreen}/>
      <Route exact path="/server/:serverId/site" component={SiteListScreen}/>

      <ServerForm/>
    </Page>
  );
};

const mapState = state => ({
  server: state.server,
});

const mapDispatch = dispatch => ({
  actions: {
    server: dispatch.server,
  }
});

export default connect(mapState, mapDispatch)(ServerDetailScreen);
