import React, { useEffect, useState } from 'react';

import { Spin } from "antd";


const ListLoadComponent = ({ authToken, apiLoad, filter, render }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  
  const [listFilter, setListFilter] = useState(null);

  useEffect(() => {
    let _isSubscribed = true;
    setListFilter(listFilter);

    const listLoad = () => {
      setLoading(true);
      const activeFilter = Object.assign(filter || {}, { limit: 5000 });
      apiLoad(authToken, { activeFilter })
        .then(({ error, data }) => {
          if (_isSubscribed) {
            setLoading(false);
            if (!error) {
              setItems(data.results);
            }
          }
        })
    }
    listLoad();
    return () => (_isSubscribed = false)
  }, [listFilter, filter, authToken, apiLoad]);

  return (
    <Spin spinning={loading}>
      {render({ items })}
    </Spin>
  );
};

export default ListLoadComponent;
