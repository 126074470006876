import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { getUrlParams } from 'utils/helpers';
import { Error } from "components/common";
import styles from './login.module.css';


const LoginForm = ({ className, actions, auth, locationSearch }) => {
  const onSubmit = data => {
    const next = getUrlParams(locationSearch).next;
    actions.auth.login({ ...data, next });
  };

  const getValidateStatus = (fieldName, errorData) => {
    if (fieldName in errorData) {
      return {
        validateStatus: "error",
        help: errorData[fieldName]
      }
    }
    return {};
  };

  const cx = classNames([
    styles.main,
    className,
  ]);

  const error = auth.loginStatus.error || {};
  const errorData = error.data || {};

  return (
    <Form className={cx} onFinish={onSubmit}>
      <Error errors={errorData.non_field_errors}/>

      <Form.Item
        name="username"
        {...getValidateStatus('username', errorData)}
        rules={[{ required: true, message: 'Введите логин' }]}
      >
        <Input
          prefix={<UserOutlined/>}
          placeholder="Логин"
          size="large"
        />
      </Form.Item>

      <Form.Item
        name="password"
        {...getValidateStatus('password', errorData)}
        rules={[{ required: true, message: 'Введите пароль' }]}
      >
        <Input
          prefix={<LockOutlined/>}
          type="password"
          placeholder="Пароль"
          size="large"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className={styles.button} size="large">
          Войти
        </Button>
      </Form.Item>
    </Form>
  )
};

const mapState = state => ({
  auth: state.auth,
  locationSearch: state.router.location.search,
});

const mapDispatch = dispatch => ({
  actions: {
    auth: dispatch.auth,
  }
});

export default connect(mapState, mapDispatch)(LoginForm);
