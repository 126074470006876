import React, { useState, useEffect, Fragment } from 'react';
import { Input, Checkbox } from "antd";
import getSlug from 'speakingurl';

import { FormItem } from 'utils';
import { SiteSelect } from 'components/blocks/Site';
import SeoTemplatePartsDropdown, { renderTpl } from 'components/blocks/SeoTemplatePage/SeoTemplatePartsDropdown';


const MainFieldSet = (props) => {
  const { form, seoTemplatePhoto, ...otherProps } = props;
  const editObject = seoTemplatePhoto.editObject || {};
  const [extra, setExtra] = useState({});

  const updateExtra = (fieldName, value) => {
    const newExtra = Object.assign({}, extra);
    newExtra[fieldName] = renderTpl(value, editObject.type);
    if (fieldName === 'tpl_path') {
      newExtra[fieldName] = getSlug(newExtra[fieldName]);
    }
    setExtra(newExtra);
  };

  const partAdd = (fieldName, part) => {
    const oldValue = form.getFieldValue(fieldName) || '';
    const newValue = oldValue + part.value;
    form.setFieldsValue({
        [fieldName]: newValue,
    });
    updateExtra(fieldName, newValue);
  };

  useEffect(() => {
    const getHelp = (fieldName, value) => {
      if (!value) return '';
      let help = renderTpl(value, editObject.type);
      if (fieldName === 'tpl_path') {
        help = getSlug(help);
      }
      return help;
    };

    const newExtra = {
      tpl_path: getHelp('tpl_path', editObject.tpl_path),
      tpl_alt: getHelp('tpl_alt', editObject.tpl_alt),
    }
    
    setExtra(newExtra);
  }, [
    editObject.type, editObject.tpl_path, editObject.tpl_alt,
  ]);

  return (
    <Fragment>
      <div className="form-block">
        <FormItem {...otherProps} name="is_active" label="Активно" required={false} valuePropName="checked">
          <Checkbox/>
        </FormItem>

        <SiteSelect {...otherProps} name="site" label="сайт" required={true} disabled={true}/>
        
        <FormItem {...otherProps} name="tpl_path" label="Путь" required={true} extra={extra.tpl_path}>
          <Input 
            size="large"
            onChange={(e) => updateExtra('tpl_path', e.target.value)}
            addonAfter={
              <SeoTemplatePartsDropdown
                  type="profile"
                  onChange={(part) => partAdd('tpl_path', part)}
              />
            }
          />
        </FormItem>
      
        <FormItem {...otherProps} name="tpl_alt" label="Alt" required={true} extra={extra.tpl_alt}>
          <Input 
            size="large"
            onChange={(e) => updateExtra('tpl_alt', e.target.value)}
            addonAfter={
              <SeoTemplatePartsDropdown
                  type="profile"
                  onChange={(part) => partAdd('tpl_alt', part)}
              />
            }
          />
        </FormItem>
      </div>
    </Fragment>
  );
};

export default MainFieldSet;
