import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { cityDetailLoad } from "store/models/city";
import { DetailLoadComponent } from 'components/common';


const CityValue = (props) => {
  return (
    <DetailLoadComponent
      apiLoad={cityDetailLoad}
      {...props}
      getDetailUrl={(id, item) => props.model.urls.detail(id)}
      render={({ id, item, linkPath }) => {
        const value = (item && item.name) || id;

        return (
          <Fragment>
            {props.asLink && linkPath ?
              <Link to={linkPath}>{value}</Link> :
              <span>{value}</span>}
          </Fragment>
        );
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.city,
});

export default connect(mapState)(CityValue);
