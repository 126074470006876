import React from 'react';
import { Input, InputNumber, Row, Col } from "antd";

import { FormItem } from 'utils';


const MainFieldSet = (props) => {
  return (
    <div className="form-block">
      <FormItem {...props} name="name" label="Название" required={true}>
        <Input size="large"/>
      </FormItem>

      <Row gutter={16}>
        <Col span={12}>
          <FormItem {...props} name="ip_address" label="IP адрес" required={true}>
            <Input size="large"/>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem {...props} name="port" label="Порт" required={true}>
            <InputNumber/>
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <FormItem {...props} name="user" label="Пользователь" required={true}>
            <Input size="large"/>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem {...props} name="password" label="Пароль" required={false}>
            <Input.Password/>
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

export default MainFieldSet;
