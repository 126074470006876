import React  from 'react';
import classNames from 'classnames';

import { parseDate } from "utils";


const DateTimeText = ({ className, date, onlyDate }) => {
  const cx = classNames([
    'date',
    className,
  ]);

  let text = '';

  if (!date) {
    text = '-';
  } else {
    if (onlyDate) {
      text = parseDate(date).format('DD.MM.YYYY');
    } else {
      text = parseDate(date).format('DD.MM.YYYY HH:mm');
    }
  }

  return (
    <div className={cx}>{text}</div>
  );
}

export default DateTimeText;
