import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const PageForm = (props) => {
  const { showEditForm } = props.page;

  const onClose = () => {
    props.actions.page.showEditForm({ show: false });
  };

  const onSubmit = data => {
    const editAdditional = props.page.editAdditional
    if (editAdditional && editAdditional.redirectAfterSave) {
      data.redirectAfterSave = `/site/${data.site}/page?search=${data.path}`;
    }
    console.log(data);
    props.actions.page.update(data);
  };
  
  return (
    <Form
      title="Редактирование страницы"
      name="pageForm"
      model={props.page}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  page: state.page,
});

const mapDispatch = dispatch => ({
  actions: {
    page: dispatch.page,
  }
});

export default connect(mapState, mapDispatch)(PageForm);

