import React from 'react';

import { SiteSyncDetail } from 'components/blocks/SiteSync';


const DetailScreen = (props) => {
  return (
    <SiteSyncDetail/>
  )
};

export default DetailScreen;
