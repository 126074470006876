import React, { Fragment } from 'react';
import { Route } from 'react-router';

import DomainListScreen from './DomainListScreen';
import DomainDetailScreen from './DomainDetailScreen';


const Domain = () => {
  return (
    <Fragment>
      <Route exact path="/domain" component={DomainListScreen}/>
      <Route exact path="/domain/:domainId" component={DomainDetailScreen}/>
    </Fragment>
  );
}

export default Domain;
