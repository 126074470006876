import React from 'react';

import { SyncPublicationDetail } from 'components/blocks/SyncPublication';


const DetailScreen = (props) => {
  return (
    <SyncPublicationDetail/>
  )
};

export default DetailScreen;
