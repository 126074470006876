import React from 'react';

import { TemplateDetail } from 'components/blocks/Template';


const DetailScreen = (props) => {
  return (
    <TemplateDetail/>
  )
};

export default DetailScreen;
