import React from 'react';
import { Input, Select, InputNumber } from "antd";

import { FormItem } from 'utils';
import { SiteSelect } from 'components/blocks/Site';
import { PageSelect } from 'components/blocks/Page';
import SiteNavType from '../../SiteNavType';


const MainFieldSet = (props) => {
  const { form, siteNav, ...otherProps } = props;
  const editObject = siteNav.editObject || {};
  
  return (
    <div className="form-block">
      <SiteSelect {...otherProps} name="site" label="сайт" required={true} disabled={true}/>

      <FormItem {...otherProps} name="name" label="Название" required={true}>
        <Input size="large"/>
      </FormItem>

      {editObject.site &&
        <PageSelect {...otherProps} name="page" label="Страница" required={true} filter={{ site: editObject.site }}/>
      }

      <FormItem {...otherProps} name="type" label="Тип" required={true}>
        <Select style={{ width: '100%' }}>
          {Object.keys(SiteNavType.TYPE).map(key => (
            <Select.Option key={`type${key}`} value={key}>
              {SiteNavType.TYPE[key]}
            </Select.Option>
          ))}
          
        </Select>
      </FormItem>

      <FormItem {...otherProps} name="sort" label="Сортировка" required={true}>
        <InputNumber size="large"/>
      </FormItem>
    </div>
  );
};

export default MainFieldSet;
