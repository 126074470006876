import React, { useCallback, useState } from 'react';
import { connect } from "react-redux";
import { CloseCircleFilled, LockOutlined } from "@ant-design/icons";

import { tableColumn } from "utils";
import { TableList, ActionButton, DateTimeText, OnOff } from 'components/common';
import HiddenDomainValue from "../HiddenDomainValue";


const HiddenDomainList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, hiddenDomain, dataSource }) => {
  const defaultColumnList = [
    'id', 'hidden_domain', 'is_main', 'date_created', 'is_blocked', 'actions'
  ];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: false,
      width: 60,
    }),
    hidden_domain: tableColumn('Скрытый домен', 'hidden_domain', {
      render: (val, item) => <HiddenDomainValue detail={item} asLink={false} isBack={true}/>,
      sorter: true,
    }),
    date_created: tableColumn('Дата', 'date_created', {
      render: (val, item) => <DateTimeText date={val} onlyDate={true}/>,
      width: 120,
      sorter: true,
    }),
    is_main: tableColumn('Основной', 'is_main', {
      sorter: false,
      width: 150,
      render: (val, item) => (
        <OnOff
          item={item}
          updateItemId={itemIdForUpdate}
          param="is_main"
          updateStatus={hiddenDomain.updateStatus}
          preventLoadDetail={false}
          onChange={data => {
            setItemIdForUpdate(data.id);
            actions.hiddenDomain.update(data);
          }}
        />
      ),
    }),
    is_blocked: tableColumn('Заблок.', 'is_blocked', {
      render: (val, item) => val && <CloseCircleFilled style={{ color: '#ff0000' }}/>,
      width: 100,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.hiddenDomain}
          actions={[{ name: 'makeMain' }, { name: 'edit' }, { name: 'delete' }]}
          actionByName={{
            makeMain: (modelActions, item) => ({
              title: 'Сделать главным',
              icon: <LockOutlined/>,
              action: () => actions.hiddenDomain.makeMain(item),
            }),
          }}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    let filter = (activeFilter === undefined) ?
      Object.assign({}, hiddenDomain.filter.activeFilter) :
      Object.assign({}, activeFilter);
    actions.hiddenDomain.listLoad({ query, activeFilter: filter, locationChange: true });
  }, [hiddenDomain, actions]);

  return (
    <TableList
      location={router.location}
      model={hiddenDomain}
      dataSource={dataSource}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      childrenColumnName='mirrors'
    />
  );
};

const mapState = state => ({
  router: state.router,
  hiddenDomain: state.hiddenDomain,
});

const mapDispatch = dispatch => ({
  actions: {
    hiddenDomain: dispatch.hiddenDomain,
  }
});

export default connect(mapState, mapDispatch)(HiddenDomainList);
