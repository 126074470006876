import React from 'react';

import { ServiceTransferField } from 'components/blocks/Service';


const ServicesFieldSet = (props) => {
  const { form, errorData } = props;
  const itemProps = { form, errorData };

  return (
    <div className="form-block">
      <ServiceTransferField 
        {...itemProps} 
        name="services" 
        label="Услуги" 
        required={false}
      />
    </div>
  );
};

export default ServicesFieldSet;
