import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { tableColumn } from "utils";
import { TableList, ActionButton } from 'components/common';
import ServiceValue from "../ServiceValue";


const ServiceList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, service, serviceGroup }) => {
  const defaultColumnList = ['expand', 'id', 'name', 'slug', 'sort', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    expand: tableColumn('', 'id', {
      render: (val, item) => '',
      sorter: false,
      width: 80,
    }),
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Название', 'name', {
      render: (val, item) => <ServiceValue detail={item} asLink={true} isBack={true}/>,
      sorter: true,
    }),
    slug: tableColumn('Путь', 'slug', {
      sorter: true,
    }),
    sort: tableColumn('Сортировка', 'sort', {
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.service}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
          actionByName={{
            edit: (modelActions, item) => ({
              title: 'Редактировать',
              icon: <EditOutlined/>,
              action: () => {
                if (item.services) {
                  actions.serviceGroup.showEditForm({show: true, object: item});
                } else {
                  actions.service.showEditForm({show: true, object: item});
                }
              },
            }),
            delete: (modelActions, item) => ({
              title: 'Удалить',
              icon: <DeleteOutlined/>,
              action: () => {
                if (item.services) {
                  actions.serviceGroup.delete({id: item.id});
                } else {
                  actions.service.delete({id: item.id});
                }
              },
            }),
          }}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = serviceGroup.filter.activeFilter
    }
    actions.serviceGroup.listLoad({ query, activeFilter, locationChange: true });
  }, [serviceGroup, actions]);

  return (
    <TableList
      location={router.location}
      model={serviceGroup}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={false}
      childrenColumnName='services'
    />
  );
};

const mapState = state => ({
  router: state.router,
  service: state.service,
  serviceGroup: state.serviceGroup,
});

const mapDispatch = dispatch => ({
  actions: {
    service: dispatch.service,
    serviceGroup: dispatch.serviceGroup,
  }
});

export default connect(mapState, mapDispatch)(ServiceList);
