import React from 'react';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';

import Header from "../Header";
import './styles.css';
import './page_transitions.css';


const Page = (props) => {
  const { children, isLoading, ...headerProps } = props;
  return (
    <Spin className="page" spinning={isLoading || false}>
      <Header {...headerProps}/>

      <div className="page-content">
        <div className="page-content-inner">
          {children}
        </div>
      </div>
    </Spin>
  );
}

export default withRouter(Page);