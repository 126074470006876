import { push } from "connected-react-router";

import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/seo/template/page/`,
  detail: (id) => `${BASE_URL}/api/v1/seo/template/page/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/seo/template/page/${id}/history/`,

  import: `${BASE_URL}/api/v1/seo/template/page/import/`,
};

export const seoTemplatePageListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const seoTemplatePageDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const seoTemplatePageUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const seoTemplatePageDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const seoTemplatePageHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });
export const seoTemplatePageImport = (token, data) => api.Update(endpoints.import, endpoints.import, token, data);


export const seoTemplatePage = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    showImportForm: false,

    title: 'Шаблоны страниц',
    urls: {
      list: (siteId, type) => `/site/${siteId}/seo/${type}`,
      detail: (siteId, id) => `/site/${siteId}/seo/tpl/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,

    showImportForm(state, payload) {
      return {
        ...state,
        showImportForm: payload.show,
        editObject: payload.object,
        editPreventLoadDetail: payload.preventLoadDetail,
      };
    },
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'seoTemplatePage', seoTemplatePageListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'seoTemplatePage', seoTemplatePageDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'seoTemplatePage', seoTemplatePageUpdate,
        async (data) => {
          dispatch['seoTemplatePage'].showEditForm({ show: false });
          const filter = rootState['seoTemplatePage'].filter;
          await dispatch['seoTemplatePage'].listLoad(filter);
        }
      );
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'seoTemplatePage', seoTemplatePageDelete,
        async (data) => {
          dispatch(push(rootState['seoTemplatePage'].urls.list(data.site, data.type)));
          const filter = rootState['seoTemplatePage'].filter;
          await dispatch['seoTemplatePage'].listLoad(filter);
        }
      );
    },

    async import(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'seoTemplatePage', seoTemplatePageImport,
        async (data) => {
          dispatch['seoTemplatePage'].showImportForm({ show: false });
          const filter = rootState['seoTemplatePage'].filter;
          await dispatch['seoTemplatePage'].listLoad(filter);
        }
      );
    },
  }),
}