import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton } from 'components/common';
import SeoExtDomainValue from "../SeoExtDomainValue";


const SeoExtDomainList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, seoExtDomain }) => {
  const defaultColumnList = ['id', 'domain', 'sites_count', 'template_pages_count', 'pages_count', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    domain: tableColumn('Домен', 'domain', {
      render: (val, item) => <SeoExtDomainValue detail={item} asLink={true} isBack={true}/>,
      sorter: true,
    }),
    sites_count: tableColumn('Кол-во сайтов', 'sites_count', {
      sorter: true,
      width: 120,
    }),
    template_pages_count: tableColumn('Кол-во шабл. страниц', 'template_pages_count', {
      sorter: true,
      width: 120,
    }),
    pages_count: tableColumn('Кол-во страниц', 'pages_count', {
      sorter: true,
      width: 120,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.seoExtDomain}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = seoExtDomain.filter.activeFilter
    }
    actions.seoExtDomain.listLoad({ query, activeFilter, locationChange: true });
  }, [seoExtDomain, actions]);

  return (
    <TableList
      location={router.location}
      model={seoExtDomain}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}

    />
  );
};

const mapState = state => ({
  router: state.router,
  seoExtDomain: state.seoExtDomain,
});

const mapDispatch = dispatch => ({
  actions: {
    seoExtDomain: dispatch.seoExtDomain,
  }
});

export default connect(mapState, mapDispatch)(SeoExtDomainList);
