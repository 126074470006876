import React, { Fragment } from 'react';
import { Input, Checkbox, Select } from "antd";

import { FormItem } from 'utils';
import { SiteSelect } from 'components/blocks/Site';
import PageType from '../../PageType';


const MainFieldSet = (props) => {
  const { form, page, ...otherProps } = props;
  
  return (
    <Fragment>
      <div className="form-block">
        <SiteSelect {...otherProps} name="site" label="сайт" required={true} disabled={true}/>

        <FormItem {...otherProps} name="type" label="Тип" required={true}>
          <Select style={{ width: '100%' }} placeholder="Выберите">
            {Object.keys(PageType.TYPE).map(v => (
              <Select.Option key={`type-${v}`} value={v}>{PageType.TYPE[v]}</Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem {...otherProps} name="path" label="Путь" required={true}>
          <Input size="large"/>
        </FormItem>
      
        <FormItem {...otherProps} name="seo_template_off" label="Не использовать шаблон" required={false} valuePropName="checked">
          <Checkbox/>
        </FormItem>

        <FormItem {...otherProps} name="profile_filter" label="Фильтр анкет" required={false}>
          <Input size="large"/>
        </FormItem>
      </div>

      <div className="form-block">
        <FormItem {...otherProps} name="title" label="Тайтл" required={true}>
          <Input size="large"/>
        </FormItem>

        <FormItem {...otherProps} name="description" label="Дескрипшн" required={false}>
          <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
        </FormItem>
      </div>

      <div className="form-block">
        <FormItem {...otherProps} name="h1" label="H1" required={false}>
          <Input size="large"/>
        </FormItem>

        <FormItem {...otherProps} name="h2" label="H2" required={false}>
          <Input size="large"/>
        </FormItem>

        <FormItem {...otherProps} name="text" label="Текст" required={false}>
          <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
        </FormItem>

        <FormItem {...otherProps} name="link_text" label="Текст для ссылок" required={false}>
          <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
        </FormItem>
      </div>
    </Fragment>
  );
};

export default MainFieldSet;
