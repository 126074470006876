import axios from 'axios';
import { getHeaders } from 'utils';


const METHODS = {
  'get': axios.get,
  'post': axios.post,
  'put': axios.put,
  'delete': axios.delete,
};


const Load = (method, url, data, config) => {
  const action = METHODS[method];
  const promise = method === 'get' || method === 'delete' ? action(url, config) : action(url, data, config);
  return promise
    .then(response => {
      return { error: false, data: response.data, statusCode: response.status };
    })
    .catch(error => {
      console.log('Load Error', url);

      let r = { error: true, err: error };
      if (error.response) {
        r.data = error.response.data;
        r.statusCode = error.response.status;
        console.log('Load Error', r.data);
      } else {
        console.log('Load Error', error);
      }
      return r;
    });
};

const ListLoad = (listUrl, token, payload) => {
  let url = listUrl;
  const data = payload || {};
  if (data.query) {
    url += data.query;
  }
  return Load('get', url, null, {
    headers: getHeaders(token),
    params: data.activeFilter,
  });
};

const DetailLoad = (detailUrl, token, { id }) => {
  let url = detailUrl(id);
  return Load('get', url, null, {
    headers: getHeaders(token),
  });
};

const Update = (listUrl, detailUrl, token, data, additionalInfo) => {
  let url = listUrl;
  let method = 'post';
  if (data.id) {
    url = detailUrl(data.id);
    method = 'put';
  }

  if (additionalInfo && additionalInfo.uploadFile) {
    var formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    return Load(method, url, formData, {
      headers: {
        ...getHeaders(token),
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  return Load(method, url, data, {
    headers: {
      ...getHeaders(token),
    },
  });
};

const Delete = (detailUrl, token, { id }) => {
  const url = detailUrl(id);
  return Load('delete', url, null, {
    headers: getHeaders(token),
  });
};

export const api = {
  Load,
  ListLoad,
  DetailLoad,
  Update,
  Delete,
}
