import { getToken } from "utils";

import { authTokenCheck } from './auth';


export const app = {
  state: {
    dataIsLoaded: false,
  },
  reducers: {
    dataLoadComplete(state, payload) {
      return {
        ...state,
        dataIsLoaded: true,
      }
    },
  },
  effects: dispatch => ({
    async init(payload, rootState) {
      const tokenOld = getToken();
      const { error, data } = await authTokenCheck(tokenOld);
      const token = error ? null : data.token;
      await dispatch.app.dataLoad({ token, authorize: true });
    },

    async dataLoad(payload, rootState) {
      const { token, authorize } = payload;

      if (token) {
        if (authorize) {
          dispatch.auth.loginComplete({ token });
        }
        await dispatch.user.currentLoad();
        dispatch.app.dataLoadComplete();
      } else {
        dispatch.app.dataLoadComplete();
      }
    },
  }),
}