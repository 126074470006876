import React, { Fragment } from 'react';
import { Route } from 'react-router';

import CityListScreen from './CityListScreen';
import CityDetailScreen from './CityDetailScreen';


const City = () => {
  return (
    <Fragment>
      <Route exact path="/city" component={CityListScreen}/>
      <Route exact path="/city/:cityId" component={CityDetailScreen}/>
    </Fragment>
  );
}

export default City;
