import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const ServiceForm = (props) => {
  const { showEditForm } = props.service;

  const onClose = () => {
    props.actions.service.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.service.update(data);
  };

  return (
    <Form
      title="Редактирование услуги"
      name="serviceForm"
      model={props.service}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  service: state.service,
});

const mapDispatch = dispatch => ({
  actions: {
    service: dispatch.service,
  }
});

export default connect(mapState, mapDispatch)(ServiceForm);

