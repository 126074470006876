import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { sortArrayOfObjects } from 'utils';
import { Fieldset } from 'components/layout';
import { Descriptions } from 'components/common';
import { DomainValue } from 'components/blocks/Domain';
import { CityValue } from 'components/blocks/City';
import { UserValue } from 'components/blocks/User';
import { TemplateValue } from 'components/blocks/Template';
import SitePublicationStatus from "../SitePublicationStatus"


const SiteDetail = ({ authToken, site, actions }) => {
  const detail = site.detail || {};
  const domain = detail.domain || {};
  const city = detail.city || {};
  const template = detail.template || {};
  const config = detail.config || {};

  const excludeUsers = useMemo(
    () => sortArrayOfObjects(detail.exclude_users || [], 'email'),
    [detail.exclude_users]
  );

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Домен" span={24}>
              <DomainValue detail={domain} asLink={true} isBack={true} />
              {domain.hiddens && domain.hiddens.length > 0 &&
                <div style={{ fontSize: 10 }}>
                  {domain.hiddens[0].hidden_domain}
                </div>}
            </Descriptions.Item>
            <Descriptions.Item label="Город" span={24}>
              <CityValue detail={city} asLink={true} isBack={true} />
            </Descriptions.Item>
            <Descriptions.Item label="Шаблон" span={24}>
              <TemplateValue detail={template} asLink={true} isBack={true} />
            </Descriptions.Item>

            <Descriptions.Item label="Статус публикации">
              <SitePublicationStatus site={detail} />
            </Descriptions.Item>

            <Descriptions.Item label="Пользователи для исключения" span={24}>
              {excludeUsers.map(user => (
                <div key={`user${user.id}`}>
                  <UserValue detail={user} asLink={true} isBack={true} />
                </div>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Название" span={24}>
              {detail.name}
            </Descriptions.Item>
            <Descriptions.Item label="Логотип">
              {config.logo && config.logo[0].url}
            </Descriptions.Item>
            <Descriptions.Item label="Фавикон">
              {config.favicon && config.favicon[0].url}
            </Descriptions.Item>

            <Descriptions.Item label="Meta" span={24}>
              {config.meta}
            </Descriptions.Item>
            <Descriptions.Item label="Robots" span={24}>
              {config.robots}
            </Descriptions.Item>
            <Descriptions.Item label="Метрика" span={24}>
              {config.metrics}
            </Descriptions.Item>
            <Descriptions.Item label="Скрипты header" span={24}>
              {config.scripts_header}
            </Descriptions.Item>
            <Descriptions.Item label="Скрипты footer" span={24}>
              {config.scripts_footer}
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  site: state.site,
  authToken: state.auth.token,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(SiteDetail);
