import React from 'react';

import { SiteDetail } from 'components/blocks/Site';


const DetailScreen = (props) => {
  return (
    <SiteDetail />
  )
};

export default DetailScreen;
