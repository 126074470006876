import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton } from 'components/common';


const SeoTemplatePhotoList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, seoTemplatePhoto }) => {
  const defaultColumnList = ['id', 'tpl_path', 'tpl_alt', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    tpl_path: tableColumn('Путь', 'tpl_path', {
      sorter: true,
    }),
    tpl_alt: tableColumn('Тайтл', 'tpl_alt', {
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.seoTemplatePhoto}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
          buttonActions={[{ name: 'copy', size: 'small' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = seoTemplatePhoto.filter.activeFilter
    }
    actions.seoTemplatePhoto.listLoad({ 
      query, activeFilter, locationChange: true, locationState: router.location.state
    });
  }, [seoTemplatePhoto, actions, router.location.state]);

  return (
    <TableList
      location={router.location}
      model={seoTemplatePhoto}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  seoTemplatePhoto: state.seoTemplatePhoto,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplatePhoto: dispatch.seoTemplatePhoto,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplatePhotoList);
