import { push } from "connected-react-router";

import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/seo/collection/page/`,
  detail: (id) => `${BASE_URL}/api/v1/seo/collection/page/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/seo/collection/page/${id}/history/`,
};

export const seoTemplateCollectionPageListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const seoTemplateCollectionPageDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const seoTemplateCollectionPageUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const seoTemplateCollectionPageDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const seoTemplateCollectionPageHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });


export const seoTemplateCollectionPage = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Коллекция шаблонов',
    urls: {
      list: (type) => `/seocollection/${type}`,
      detail: (type, id) => `/seocollection/${type}/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'seoTemplateCollectionPage', seoTemplateCollectionPageListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'seoTemplateCollectionPage', seoTemplateCollectionPageDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'seoTemplateCollectionPage', seoTemplateCollectionPageUpdate,
        async (data) => {
          dispatch['seoTemplateCollectionPage'].showEditForm({ show: false });
          if (!payload.preventLoadDetail) {
            dispatch(push(rootState['seoTemplateCollectionPage'].urls.detail(data.type, data.id)));
          } else {
            const filter = rootState['seoTemplateCollectionPage'].filter;
            await dispatch['seoTemplateCollectionPage'].listLoad(filter);
          }
        }
      );
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'seoTemplateCollectionPage', seoTemplateCollectionPageDelete,
        async (data) => {
          dispatch(push(rootState['seoTemplateCollectionPage'].urls.list(data.type)));
          const filter = rootState['seoTemplateCollectionPage'].filter;
          await dispatch['seoTemplateCollectionPage'].listLoad(filter);
        }
      );
    },
  }),
}