import React, { Fragment } from 'react';
import { Route } from 'react-router';

import SiteSyncListScreen from './SiteSyncListScreen';
import SiteSyncDetailScreen from './SiteSyncDetailScreen';


const SiteSync = () => {
  return (
    <Fragment>
      <Route exact path="/siteSync" component={SiteSyncListScreen}/>
      <Route exact path="/siteSync/:siteSyncId" component={SiteSyncDetailScreen}/>
      <Route exact path="/siteSync/:siteSyncId/imports" component={SiteSyncDetailScreen}/>
      <Route exact path="/siteSync/:siteSyncId/imports/:syncImportId" component={SiteSyncDetailScreen}/>
    </Fragment>
  );
}

export default SiteSync;
