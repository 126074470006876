import React, { Fragment } from 'react';
import { Route } from 'react-router';

import TemplateListScreen from './TemplateListScreen';
import TemplateDetailScreen from './TemplateDetailScreen';


const Template =() => {
  return (
    <Fragment>
      <Route exact path="/template" component={TemplateListScreen}/>
      <Route exact path="/template/:templateId" component={TemplateDetailScreen}/>
    </Fragment>
  );
}

export default  Template;
