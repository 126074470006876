import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton } from 'components/common';
import SiteSyncValue from "../SiteSyncValue";
import SiteSyncStatus from '../SiteSyncStatus'
import SiteSyncError from '../SiteSyncError'


const SiteSyncList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, siteSync }) => {
  const defaultColumnList = ['name', 'domain', 'sync_sucess_date', 'sync_error_date', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Город', 'name', {
      render: (val, item) => <SiteSyncValue detail={item} asLink={true} isBack={true}/>,
      sorter: false,
    }),
    domain: tableColumn('Домен', 'domain', {
      sorter: false,
    }),
    sync_sucess_date: tableColumn('Импорт', 'sync_sucess_date', {
      render: (val, item) => <SiteSyncStatus detail={item} />,
      sorter: true,
    }),
    sync_error_date: tableColumn('Ошибка импорта', 'sync_error_date', {
      render: (val, item) => <SiteSyncError detail={item} />,
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.siteSync}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = siteSync.filter.activeFilter
    }
    actions.siteSync.listLoad({ query, activeFilter, locationChange: true });
  }, [siteSync, actions]);

  return (
    <TableList
      location={router.location}
      model={siteSync}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  siteSync: state.siteSync,
});

const mapDispatch = dispatch => ({
  actions: {
    siteSync: dispatch.siteSync,
  }
});

export default connect(mapState, mapDispatch)(SiteSyncList);
