import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoginScreen from 'screens/LoginScreen';
import AppInitScreen from 'screens/AppInitScreen';
import MainScreen from 'screens';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    rest.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
        pathname: `/login`,
        state: { from: props.location }
      }}/>
  )}/>
);

const App = ({ appIsLoaded, isAuthenticated, actions }) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    actions.app.init();
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  if (!appIsLoaded) return <AppInitScreen/>;
  return (
    <Switch>
      <Route path="/login" component={LoginScreen}/>
      <PrivateRoute path="/" component={MainScreen} isAuthenticated={isAuthenticated}/>
    </Switch>
  );
};

const mapState = state => ({
  appIsLoaded: state.app.dataIsLoaded,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatch = dispatch => ({
  actions: {
    app: dispatch.app,
  }
});

export default withRouter(connect(mapState, mapDispatch)(App));
