import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import DetailScreen from './DetailScreen';


const SyncPublicationDetailScreen = ({ history, syncPublication, actions, match }) => {
  useEffect(() => {
    actions.syncPublication.detailLoad({ id: match.params.syncPublicationId });
  }, [match.params.syncPublicationId, actions.syncPublication]);

  const id = match.params.syncPublicationId;
  const detail = syncPublication.detail || {};
  const site = detail.site || {};

  return (
    <Page
      isLoading={syncPublication.loadDetailStatus.isLoading}
      detail={detail}
      title={site.domain_domain}
      modelActions={actions.syncPublication}
      breadcrumbs={[
        { title: 'Публикации', path: syncPublication.urls.list() },
        { title: site.domain_domain, path: syncPublication.urls.detail(id) },
      ]}
      basePath={syncPublication.urls.detail(id)}
    >
      <Route exact path="/publication/:syncPublicationId" component={DetailScreen}/>
    </Page>
  );
};

const mapState = state => ({
  syncPublication: state.syncPublication,
});

const mapDispatch = dispatch => ({
  actions: {
    syncPublication: dispatch.syncPublication,
  }
});

export default connect(mapState, mapDispatch)(SyncPublicationDetailScreen);
