import React  from 'react';
import classNames from "classnames";

import styles from './type.module.css';

const TYPE_TOP = 'top';
const TYPE_LEFT = 'left';
const TYPE_RIGHT = 'right';
const TYPE_BOTTOM = 'bottom';


const TYPE = {
  [TYPE_TOP]: 'сверху',
  [TYPE_LEFT]: 'слева',
  [TYPE_RIGHT]: 'справа',
  [TYPE_BOTTOM]: 'внизу',
};

const SiteNavType = ({ value, type, className, size }) => {
  const _type = value || type;

  const cx = classNames([
    styles.type,
    styles[`s${_type}`],
    size && styles[`size--${size}`],
    className,
  ]);

  return (
    <div className={cx}>{TYPE[_type] || _type}</div>
  );
};

SiteNavType.TYPE_TOP = TYPE_TOP;
SiteNavType.TYPE_LEFT = TYPE_LEFT;
SiteNavType.TYPE_RIGHT = TYPE_RIGHT;
SiteNavType.TYPE_BOTTOM = TYPE_BOTTOM;
SiteNavType.TYPE = TYPE;

export default SiteNavType;
