import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText } from 'components/common';


const ServerDetail = ({ server, actions }) => {
  const detail = server.detail || {};

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={3} gutter={[16, 16]}>
            <Descriptions.Item label="Название" span={24}>{detail.name}</Descriptions.Item>
            <Descriptions.Item label="IP адрес">{detail.ip_address}</Descriptions.Item>
            <Descriptions.Item label="Порт">{detail.port}</Descriptions.Item>

            <Descriptions.Item label="Пользователь">{detail.user}</Descriptions.Item>

            <Descriptions.Item label="Дата создания"><DateTimeText date={detail.date_created}/></Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  server: state.server,
});

const mapDispatch = dispatch => ({
  actions: {
    server: dispatch.server,
  }
});

export default connect(mapState, mapDispatch)(ServerDetail);
