import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/template/`,
  detail: (id) => `${BASE_URL}/api/v1/template/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/template/${id}/history/`,
};

export const templateListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const templateDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const templateUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data, {uploadFile: true});
export const templateDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const templateHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });


export const template = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Шаблоны',
    urls: {
      list: () => `/template`,
      detail: (id) => `/template/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'template', templateListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'template', templateDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'template', templateUpdate);
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'template', templateDelete);
    },
  }),
}