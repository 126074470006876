import React from 'react';
import { connect } from 'react-redux';

import { Page, Fieldset } from 'components/layout';
import { UserList, UserForm } from 'components/blocks/User';


const UserListScreen = ({ history, user, actions }) => {
  return (
    <Page
      title="Пользователи"
      modelActions={actions.user}
      breadcrumbs={[
        { title: 'Пользователи', path: user.urls.list() },
      ]}
      actions={[
        { mode: 'button', name: 'add', type: 'primary', title: 'Добавить пользователя' },
      ]}
    >
      <Fieldset>
        <UserList/>
      </Fieldset>

      <UserForm/>
    </Page>
  );
};

const mapState = state => ({
  user: state.user,
});

const mapDispatch = dispatch => ({
  actions: {
    user: dispatch.user,
  }
});

export default connect(mapState, mapDispatch)(UserListScreen);
