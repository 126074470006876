import React, { Fragment } from 'react';
import { Route } from 'react-router';

import SeoTemplateCollectionListScreen from './SeoTemplateCollectionListScreen';
import SeoTemplateCollectionDetailScreen from './SeoTemplateCollectionDetailScreen';


const SeoTemplateCollection = () => {
  return (
    <Fragment>
      <Route exact path="/seocollection" component={SeoTemplateCollectionListScreen}/>
      <Route exact path="/seocollection/:seoType" component={SeoTemplateCollectionListScreen}/>
      <Route exact path="/seocollection/:seoType/:collectionId" component={SeoTemplateCollectionDetailScreen}/>
    </Fragment>
  );
}

export default SeoTemplateCollection;
