import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton } from 'components/common';
import DistrictValue from "../DistrictValue";


const DistrictList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, district }) => {
  const defaultColumnList = ['id', 'name', 'slug', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Название', 'name', {
      render: (val, item) => <DistrictValue detail={item} asLink={false} isBack={true}/>,
      sorter: true,
    }),
    slug: tableColumn('Путь', 'slug', {
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.district}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = district.filter.activeFilter
    }
    actions.district.listLoad({ 
      query, activeFilter, locationChange: true, locationState: router.location.state
    });
  }, [district, actions, router.location.state]);

  return (
    <TableList
      location={router.location}
      model={district}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  district: state.district,
});

const mapDispatch = dispatch => ({
  actions: {
    district: dispatch.district,
  }
});

export default connect(mapState, mapDispatch)(DistrictList);
