import { push } from "connected-react-router";

import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/sync/publication/`,
  detail: (id) => `${BASE_URL}/api/v1/sync/publication/${id}/`,
};

export const syncPublicationListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const syncPublicationDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const syncPublicationDelete = (token, data) => api.Delete(endpoints.detail, token, data);


export const syncPublication = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Публикации',
    urls: {
      list: () => `/publication`,
      detail: (id) => `/publication/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'syncPublication', syncPublicationListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'syncPublication', syncPublicationDetailLoad)
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'syncPublication', syncPublicationDelete,
        async (data) => {
          dispatch(push(rootState['syncPublication'].urls.list()));
          const filter = rootState['syncPublication'].filter;
          await dispatch['syncPublication'].listLoad(filter);
        }
      );
    },
  }),
}