import React, { useCallback, useState } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton, OnOff } from 'components/common';
import { SeoTemplatePageType } from 'components/blocks/SeoTemplatePage';
import SeoTemplateCollectionPageValue from '../SeoTemplateCollectionPageValue';


const SeoTemplateCollectionPageList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, seoTemplateCollectionPage }) => {
  const defaultColumnList = ['id', 'tpl_path', 'tpl_title', 'type', 'is_active', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    tpl_path: tableColumn('Путь', 'tpl_path', {
      render: (val, item) => <SeoTemplateCollectionPageValue detail={item} asLink={true} isBack={true}/>,
      sorter: true,
    }),
    tpl_title: tableColumn('Тайтл', 'tpl_title', {
      sorter: true,
    }),
    type: tableColumn('Тип', 'type', {
      render: (val, item) => <SeoTemplatePageType value={val}/>,
      sorter: true,
    }),
    is_active: tableColumn('Активно', 'is_active', {
      sorter: false,
      width: 150,
      render: (val, item) => (
        <OnOff
          item={item}
          updateItemId={itemIdForUpdate}
          param="is_active"
          updateStatus={seoTemplateCollectionPage.updateStatus}
          preventLoadDetail={true}
          onChange={data => {
            setItemIdForUpdate(data.id);
            actions.seoTemplateCollectionPage.update(data);
          }}
        />
      ),
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.seoTemplateCollectionPage}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
          buttonActions={[{ name: 'copy', size: 'small' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = seoTemplateCollectionPage.filter.activeFilter
    }
    actions.seoTemplateCollectionPage.listLoad({ 
      query, activeFilter, locationChange: true, locationState: router.location.state
    });
  }, [seoTemplateCollectionPage, actions, router.location.state]);

  return (
    <TableList
      location={router.location}
      model={seoTemplateCollectionPage}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  seoTemplateCollectionPage: state.seoTemplateCollectionPage,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplateCollectionPage: dispatch.seoTemplateCollectionPage,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplateCollectionPageList);
