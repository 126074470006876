import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton } from 'components/common';
import TemplateValue from "../TemplateValue";


const TemplateList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, template }) => {
  const defaultColumnList = ['id', 'name', 'tpl_file_name', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Название', 'name', {
      render: (val, item) => <TemplateValue detail={item} asLink={true} isBack={true}/>,
      sorter: true,
    }),
    tpl_file_name: tableColumn('Файл', 'tpl_file_name', {
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.template}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = template.filter.activeFilter
    }
    actions.template.listLoad({ query, activeFilter, locationChange: true });
  }, [template, actions]);

  return (
    <TableList
      location={router.location}
      model={template}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  template: state.template,
});

const mapDispatch = dispatch => ({
  actions: {
    template: dispatch.template,
  }
});

export default connect(mapState, mapDispatch)(TemplateList);
