import { loadStates, loadStatus } from 'utils';


export const DetailState = {
  detail: null,
  loadDetailStatus: loadStatus(loadStates.notAsked),
};

export const DetailReducers = {
  detailLoadRequest(state, payload) {
    return {
      ...state,
      loadDetailStatus: loadStatus(loadStates.loading),
      detail: null,
    };
  },

  detailLoadComplete(state, payload) {
    return {
      ...state,
      loadDetailStatus: loadStatus(loadStates.loaded),
      detail: payload,
    };
  },

  detailLoadError(state, payload) {
    return {
      ...state,
      loadDetailStatus: loadStatus(loadStates.failed, payload),
    }
  },
}
