import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SiteNavForm = (props) => {
  const { showEditForm } = props.siteNav;

  const onClose = () => {
    props.actions.siteNav.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.siteNav.update(data);
  };

  return (
    <Form
      title="Редактирование навигации"
      name="siteNavForm"
      model={props.siteNav}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject, page: editObject.page && editObject.page.id})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  siteNav: state.siteNav,
});

const mapDispatch = dispatch => ({
  actions: {
    siteNav: dispatch.siteNav,
  }
});

export default connect(mapState, mapDispatch)(SiteNavForm);

