import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/seo/extlinkankor/`,
};

export const seoExtLinkAnkorListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);

export const seoExtLinkAnkor = {
  state: {
    ...base.ListState,

    title: 'Внешние ссылки: анкоры',
    urls: {
      list: (seoExtDomain) => `/extlink/${seoExtDomain}/ankors`,
    },
  },
  reducers: {
    ...base.ListReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'seoExtLinkAnkor', seoExtLinkAnkorListLoad)
    },
  }),
}