import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';
import ConfigFieldSet from './ConfigFieldSet';
import FilterFieldSet from './FilterFieldSet';


const SiteForm = (props) => {
  const { showEditForm } = props.site;

  const onClose = () => {
    props.actions.site.showEditForm({ show: false });
  };

  const onSubmit = data => {
    console.log(data);
    props.actions.site.update(data);
  };

  const FormInner = (form, errorData) => (
    <Tabs className="tabs">
      <Tabs.TabPane tab="Основное" key="detail" forceRender={true}>
        <MainFieldSet form={form} errorData={errorData} {...props}/>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Настройки" key="config" forceRender={true}>
        <ConfigFieldSet form={form} errorData={errorData}  {...props}/>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Фильтры анкет" key="filter" forceRender={true}>
        <FilterFieldSet form={form} errorData={errorData}  {...props}/>
      </Tabs.TabPane>
    </Tabs>
  );

  return (
    <Form
      width={960}
      title="Редактирование сайта"
      name="siteForm"
      model={props.site}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({
        ...editObject, 
        city: editObject && editObject.city.id,
        domain: editObject && editObject.domain.id,
        template: editObject && editObject.template.id,
        exclude_users: editObject.exclude_users.map(item => item.id),
      })}
    >
      <FormInner/>
    </Form>
  )
};

const mapState = state => ({
  site: state.site,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(SiteForm);

