import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { tableColumn } from "utils";
import { Fieldset } from 'components/layout';
import { Descriptions, TableListSimple, DateTimeText } from 'components/common';
import SyncPublicationValue from '../SyncPublicationValue';
import SyncPublicationSteps from '../SyncPublicationSteps';


const SyncPublicationDetail = ({ syncPublication, actions }) => {
  const detail = syncPublication.detail || {};
  const logs = detail.logs || [];

  return (
    <Row gutter={16}>
      <Col span={16} style={{marginBottom: 30}}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Статус" span={24}>
              <SyncPublicationValue detail={detail} site_id={detail?.site?.id} asLink={false} isBack={true}/>
            </Descriptions.Item>
            <Descriptions.Item label="Статистика" span={24}>
              <SyncPublicationSteps detail={detail}/>
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8} style={{marginBottom: 30}}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Дата начала" span={24}>
              <DateTimeText date={detail.date_start}/>
            </Descriptions.Item>
            <Descriptions.Item label="Дата завершения" span={24}>
              <DateTimeText date={detail.date_end}/>
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={24}>
        <Fieldset title="Лог ошибок">
          <TableListSimple
            dataSource={logs}
            columnList={['date', 'level', 'message']}
            columnByName={{
              date: tableColumn('Дата', 'date', {
                render: (val, item) => <DateTimeText date={val}/>,
                sorter: true,
              }),
              level: tableColumn('Level', 'level', {
                sorter: true,
              }),
              message: tableColumn('Сообщение', 'message', {
                sorter: false,
              }),
            }}
          />
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  syncPublication: state.syncPublication,
});

const mapDispatch = dispatch => ({
  actions: {
    syncPublication: dispatch.syncPublication,
  }
});

export default connect(mapState, mapDispatch)(SyncPublicationDetail);
