import React from 'react';
import { Row, Col, Input, InputNumber } from "antd";

import { FormItem } from 'utils';


const TariffFieldSet = (props) => {
  const { form, errorData } = props;
  const itemProps = { form, errorData };

  return (
    <Row gutter={16}>
      <Col span={12}>
        <div className="form-block">
          <div className="form-block-title">Апартаменты</div>

          <Row gutter={8}>
            <Col span={8}>
              <FormItem {...itemProps} name="prices.in_hour" label="1 час" required={false}>
                <InputNumber size="large"/>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem {...itemProps} name="prices.in_hour_note" label=" " required={false}>
                <Input size="large" placeholder="комментарий"/>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={8}>
              <FormItem {...itemProps} name="prices.in_hour2" label="2 часа" required={false}>
                <InputNumber size="large"/>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem {...itemProps} name="prices.in_hour2_note" label=" " required={false}>
                <Input size="large" placeholder="комментарий"/>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={8}>
              <FormItem {...itemProps} name="prices.in_night" label="Ночь" required={false}>
                <InputNumber size="large"/>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem {...itemProps} name="prices.in_night_note" label=" " required={false}>
                <Input size="large" placeholder="комментарий"/>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={8}>
              <FormItem {...itemProps} name="prices.in_express" label="Экспресс" required={false}>
                <InputNumber size="large"/>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem {...itemProps} name="prices.in_express_note" label=" " required={false}>
                <Input size="large" placeholder="комментарий"/>
              </FormItem>
            </Col>
          </Row>
        </div>
      </Col>

      <Col span={12}>
        <div className="form-block">
          <div className="form-block-title">Выезд</div>

          <Row gutter={8}>
            <Col span={8}>
              <FormItem {...itemProps} name="prices.out_hour" label="1 час" required={false}>
                <InputNumber size="large"/>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem {...itemProps} name="prices.out_hour_note" label=" " required={false}>
                <Input size="large" placeholder="комментарий"/>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={8}>
              <FormItem {...itemProps} name="prices.out_hour2" label="2 часа" required={false}>
                <InputNumber size="large"/>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem {...itemProps} name="prices.out_hour2_note" label=" " required={false}>
                <Input size="large" placeholder="комментарий"/>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={8}>
              <FormItem {...itemProps} name="prices.out_night" label="Ночь" required={false}>
                <InputNumber size="large"/>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem {...itemProps} name="prices.out_night_note" label=" " required={false}>
                <Input size="large" placeholder="комментарий"/>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={8}>
              <FormItem {...itemProps} name="prices.out_express" label="Экспресс" required={false}>
                <InputNumber size="large"/>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem {...itemProps} name="prices.out_express_note" label=" " required={false}>
                <Input size="large" placeholder="комментарий"/>
              </FormItem>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
    
  );
};

export default TariffFieldSet;
