import React from 'react';

import { Fieldset } from "components/layout";
import { SiteList } from 'components/blocks/Site';


const SiteListScreen = ({ match }) => {
  const serverId = match.params.serverId;

  return (
    <Fieldset title="Бэкапы">
      <SiteList activeFilter={{ server: serverId }}/>
    </Fieldset>
  )
};

export default SiteListScreen;
