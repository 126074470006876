import React from 'react';

import { SeoExtDomainDetail } from 'components/blocks/SeoExtDomain';


export default (props) => {
  return (
    <SeoExtDomainDetail/>
  )
};
