import { push } from "connected-react-router";

import { BASE_URL } from "config";
import { api } from "utils";
import * as base from '../base';


const endpoints = {
  list: `${BASE_URL}/api/v1/seo/extlink/`,
  detail: (id) => `${BASE_URL}/api/v1/seo/extlink/${id}/`,

};

export const seoExtLinkListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const seoExtLinkDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const seoExtLinkUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const seoExtLinkDelete = (token, data) => api.Delete(endpoints.detail, token, data);


export const seoExtLink = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Внешние ссылки',
    urls: {
      list: (seoExtDomain) => `/extlink/${seoExtDomain}`,
      detail: (seoExtDomain, id) => `/extlink/${seoExtDomain}/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'seoExtLink', seoExtLinkListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'seoExtLink', seoExtLinkDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'seoExtLink', seoExtLinkUpdate,
        async (data) => {
          dispatch['seoExtLink'].showEditForm({ show: false });
          const filter = rootState['seoExtLink'].filter;
          await dispatch['seoExtLink'].listLoad(filter);
        }
      );
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'seoExtLink', seoExtLinkDelete,
        async (data) => {
          dispatch(push(rootState['seoExtLink'].urls.list(data.site, data.type)));
          const filter = rootState['seoExtLink'].filter;
          await dispatch['seoExtLink'].listLoad(filter);
        }
      );
    },
  }),
}