import React from 'react';
import { Row, Col, InputNumber, Select } from "antd";

import { FormItem } from 'utils';
import ProfileChoices from '../../ProfileChoices';


const ParametersFieldSet = (props) => {
  const { form, errorData } = props;
  const itemProps = { form, errorData };

  return (
    <div className="form-block">
      <Row gutter={16}>
        <Col span={4}>
          <FormItem {...itemProps} name="parameters.age" label="Возраст" required={false}>
            <InputNumber size="large"/>
          </FormItem>

          <FormItem {...itemProps} name="parameters.weight" label="Вес" required={false}>
            <InputNumber size="large"/>
          </FormItem>

          <FormItem {...itemProps} name="parameters.height" label="Рост" required={false}>
            <InputNumber size="large"/>
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem {...itemProps} name="parameters.gender" label="Пол" required={false}>
            <Select style={{ width: '100%' }} placeholder="Выберите">
              {ProfileChoices.GENDER.map(item => (
                <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem {...itemProps} name="parameters.ethnicity" label="Тип внешности" required={false}>
            <Select style={{ width: '100%' }} placeholder="Выберите">
              {ProfileChoices.ETHNICITY.map(item => (
                <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem {...itemProps} name="parameters.hair_color" label="Цвет волос" required={false}>
            <Select style={{ width: '100%' }} placeholder="Выберите">
              {ProfileChoices.HAIR_COLOR.map(item => (
                <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem {...itemProps} name="parameters.breast_size" label="Размер груди" required={false}>
            <Select style={{ width: '100%' }} placeholder="Выберите">
              {ProfileChoices.BREAST_SIZE.map(item => (
                <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem {...itemProps} name="parameters.breast_type" label="Тип груди" required={false}>
            <Select style={{ width: '100%' }} placeholder="Выберите">
              {ProfileChoices.BREAST_TYPE.map(item => (
                <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

export default ParametersFieldSet;
