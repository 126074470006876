import React, { useEffect } from 'react';
import { Button, Modal, Form, Spin } from 'antd';

import { getEditObject, getSubmitData } from 'utils';
import { Error } from "components/common";


const CustomForm = ({ width, title, name, visible, model, initialValues, convertEditObject, onSubmit, onClose, children }) => {
  const [form] = Form.useForm();

  const onFinish = values => {
    const { editObject, editPreventLoadDetail } = model;

    let data = Object.assign({}, values);
    if (editObject && editObject.id) {
      data.id = editObject.id;
    }
    if (editPreventLoadDetail) {
      data.preventLoadDetail = editPreventLoadDetail;
    }
    data = getSubmitData(data);
    onSubmit && onSubmit(data);
  };

  const { updateStatus, editObject } = model;

  const error = updateStatus.error || {};
  const errorData = error.data || {};
  const isLoading = updateStatus.isLoading;

  useEffect(() => {
    if (visible) {
      form.resetFields();

      if (editObject) {
        let _editObject = convertEditObject ? convertEditObject(editObject): editObject;
        _editObject = getEditObject(_editObject);
        form.setFieldsValue(_editObject);
      }
    }
  });

  const childrenWithExtraProp = React.Children.map(children, child => {
    return child && React.cloneElement(child, {
      errorData,
      form,
    });
  });

  return (
    <Modal
      width={width || 600}
      visible={visible}
      title={title}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose={true}
      forceRender={true}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={isLoading}>Отмена</Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={() => form.submit()}>
          Сохранить
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <Form name={name} form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
          <Error errors={errorData.non_field_errors}/>
          {childrenWithExtraProp}
        </Form>
      </Spin>
    </Modal>
  );
};

export default CustomForm;
