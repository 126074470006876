import React from 'react';
import { Dropdown, Menu } from 'antd';
import { PlusOutlined } from "@ant-design/icons";


const SEO_TPL_PARTS = {
    profile: [
        {
            value: '[name]',
            title: 'имя',
            example: 'Марина',
            variants: [
                {
                    value: '[name2]',
                    title: 'имя в род. подеже',
                    example: 'Марины',
                },
                {
                    value: '[name3]',
                    title: 'имя в дат. подеже',
                    example: 'Марине',
                },
                {
                    value: '[name4]',
                    title: 'имя в вин. подеже',
                    example: 'Марину',
                },
                {
                    value: '[name5]',
                    title: 'имя в твор. подеже',
                    example: 'Мариной',
                },
                {
                    value: '[name6]',
                    title: 'имя в пред. подеже',
                    example: 'Марине',
                },
            ],
        },
        {
            value: '[slug]',
            title: 'путь',
            example: 'marina',
        },
        {
            value: '[text]',
            title: 'текст',
            example: 'текст',
        },
        {
            value: '[contacts.phone]',
            title: 'телефон',
            example: '79999999999',
        },
        {
            value: '[contacts.phone_mask]',
            title: 'телефон c маской',
            example: '799999999**',
        },

        {
            value: '[parameters.age]',
            title: 'возраст',
            example: '20 лет',
        },
        {
            value: '[parameters.weight]',
            title: 'вес',
            example: '55кг',
        },
        {
            value: '[parameters.height]',
            title: 'рост',
            example: '170см',
        },
        {
            value: '[parameters.gender]',
            title: 'пол',
            example: 'женский пол',
            variants: [
                {
                    value: '[parameters.gender2]',
                    title: 'пол в род. подеже',
                    example: 'женского пола',
                },
                {
                    value: '[parameters.gender3]',
                    title: 'пол в дат. подеже',
                    example: 'женскому полу',
                },
                {
                    value: '[parameters.gender4]',
                    title: 'пол в вин. подеже',
                    example: 'женский пол',
                },
                {
                    value: '[parameters.gender5]',
                    title: 'пол в твор. подеже',
                    example: 'женским полом',
                },
                {
                    value: '[parameters.gender6]',
                    title: 'пол в пред. подеже',
                    example: 'женском поле',
                },
            ]
        },
        {
            value: '[parameters.ethnicity]',
            title: 'внешность',
            example: 'славянская внешность',
            variants: [
                {
                    value: '[parameters.ethnicity2]',
                    title: 'внешность в род. подеже',
                    example: 'славянской внешности',
                },
                {
                    value: '[parameters.ethnicity3]',
                    title: 'внешность в дат. подеже',
                    example: 'славянской внешности',
                },
                {
                    value: '[parameters.ethnicity4]',
                    title: 'внешность в вин. подеже',
                    example: 'славянскую внешность',
                },
                {
                    value: '[parameters.ethnicity5]',
                    title: 'внешность в твор. подеже',
                    example: 'славянской внешностью',
                },
                {
                    value: '[parameters.ethnicity6]',
                    title: 'внешность в пред. подеже',
                    example: 'славянской внешности',
                },
            ]
        },
        {
            value: '[parameters.hair_color]',
            title: 'цвет волос',
            example: 'светлые волосы',
            variants: [
                {
                    value: '[parameters.hair_color2]',
                    title: 'цвет волос в род. подеже',
                    example: 'светлых волос',
                },
                {
                    value: '[parameters.hair_color3]',
                    title: 'цвет волос в дат. подеже',
                    example: 'светлым волосам',
                },
                {
                    value: '[parameters.hair_color4]',
                    title: 'цвет волос в вин. подеже',
                    example: 'светлые волосы',
                },
                {
                    value: '[parameters.hair_color5]',
                    title: 'цвет волос в твор. подеже',
                    example: 'с светлыми волосами',
                },
                {
                    value: '[parameters.hair_color6]',
                    title: 'цвет волос в пред. подеже',
                    example: 'светлых волосах'
                },
            ]
        },
        {
            value: '[parameters.breast_size]',
            title: 'размер груди',
            example: '1 размера',
        },
        {
            value: '[parameters.breast_type]',
            title: 'тип груди',
            example: 'натуральная грудь',
            variants: [
                {
                    value: '[parameters.breast_type2]',
                    title: 'пол в род. подеже',
                    example: 'натуральной груди',
                },
                {
                    value: '[parameters.breast_type3]',
                    title: 'пол в дат. подеже',
                    example: 'натуральной груди',
                },
                {
                    value: '[parameters.breast_type4]',
                    title: 'пол в вин. подеже',
                    example: 'натуральную грудь',
                },
                {
                    value: '[parameters.breast_type5]',
                    title: 'пол в твор. подеже',
                    example: 'с натуральной грудью',
                },
                {
                    value: '[parameters.breast_type6]',
                    title: 'пол в пред. подеже',
                    example: 'натуральной груди'
                },
            ]
        },

        {
            value: '[prices.in_hour]',
            title: 'цена 1 час',
            example: '1500руб',
        },

        {
            value: '[city.name]',
            title: 'город',
            example: 'Уфа',
            variants: [
                {
                    value: '[city.name2]',
                    title: 'город в род. подеже',
                    example: 'Уфы',
                },
                {
                    value: '[city.name3]',
                    title: 'город в дат. подеже',
                    example: 'Уфе',
                },
                {
                    value: '[city.name4]',
                    title: 'город в вин. подеже',
                    example: 'Уфу',
                },
                {
                    value: '[city.name5]',
                    title: 'город в твор. подеже',
                    example: 'Уфой',
                },
                {
                    value: '[city.name6]',
                    title: 'город в пред. подеже',
                    example: 'Уфе',
                },
            ],
        },

        {
            value: '[districts.1.name]',
            title: 'район',
            example: 'зеленая роща',
            variants: [
                {
                    value: '[districts.1.name2]',
                    title: 'район в род. подеже',
                    example: 'зеленой рощи',
                },
                {
                    value: '[districts.1.name3]',
                    title: 'район в дат. подеже',
                    example: 'зеленой роще',
                },
                {
                    value: '[districts.1.name4]',
                    title: 'район в вин. подеже',
                    example: 'зеленую рощу',
                },
                {
                    value: '[districts.1.name5]',
                    title: 'район в твор. подеже',
                    example: 'зеленой рощей',
                },
                {
                    value: '[districts.1.name6]',
                    title: 'район в пред. подеже',
                    example: 'зеленой роще',
                },
            ],
        },

        {
            value: '[services.1.name]',
            title: 'услуга',
            example: 'массаж',
            variants: [
                {
                    value: '[services.1.name2]',
                    title: 'услуга в род. подеже',
                    example: 'массажа',
                },
                {
                    value: '[services.1.name3]',
                    title: 'услуга в дат. подеже',
                    example: 'массажу',
                },
                {
                    value: '[services.1.name4]',
                    title: 'услуга в вин. подеже',
                    example: 'массаж',
                },
                {
                    value: '[services.1.name5]',
                    title: 'услуга в твор. подеже',
                    example: 'массажем',
                },
                {
                    value: '[services.1.name6]',
                    title: 'услуга в пред. подеже',
                    example: 'массаже',
                },
            ],
        },
    ],

    service: [
        {
            value: '[name]',
            title: 'название',
            example: 'массаж',
            variants: [
                {
                    value: '[name2]',
                    title: 'название в род. подеже',
                    example: 'массажа',
                },
                {
                    value: '[name3]',
                    title: 'название в дат. подеже',
                    example: 'массажу',
                },
                {
                    value: '[name4]',
                    title: 'название в вин. подеже',
                    example: 'массаж',
                },
                {
                    value: '[name5]',
                    title: 'название в твор. подеже',
                    example: 'массажем',
                },
                {
                    value: '[name6]',
                    title: 'название в пред. подеже',
                    example: 'массаже',
                },
            ],
        },
        {
            value: '[slug]',
            title: 'путь',
            example: 'marina',
        },
        {
            value: '[domain]',
            title: 'домен',
            example: 'site.ru',
        },
        {
            value: '[city.name]',
            title: 'город',
            example: 'Уфа',
            variants: [
                {
                    value: '[city.name2]',
                    title: 'город в род. подеже',
                    example: 'Уфы',
                },
                {
                    value: '[city.name3]',
                    title: 'город в дат. подеже',
                    example: 'Уфе',
                },
                {
                    value: '[city.name4]',
                    title: 'город в вин. подеже',
                    example: 'Уфу',
                },
                {
                    value: '[city.name5]',
                    title: 'город в твор. подеже',
                    example: 'Уфой',
                },
                {
                    value: '[city.name6]',
                    title: 'город в пред. подеже',
                    example: 'Уфе',
                },
            ],
        }
    ],

    district: [
        {
            value: '[name]',
            title: 'название',
            example: 'зеленая роща',
            variants: [
                {
                    value: '[name2]',
                    title: 'название в род. подеже',
                    example: 'зеленой рощи',
                },
                {
                    value: '[name3]',
                    title: 'название в дат. подеже',
                    example: 'зеленой роще',
                },
                {
                    value: '[name4]',
                    title: 'название в вин. подеже',
                    example: 'зеленую рощу',
                },
                {
                    value: '[name5]',
                    title: 'название в твор. подеже',
                    example: 'зеленой рощей',
                },
                {
                    value: '[name6]',
                    title: 'название в пред. подеже',
                    example: 'зеленой роще',
                },
            ],
        },
        {
            value: '[slug]',
            title: 'путь',
            example: 'marina',
        },
        {
            value: '[domain]',
            title: 'домен',
            example: 'site.ru',
        },
        {
            value: '[city.name]',
            title: 'город',
            example: 'Уфа',
            variants: [
                {
                    value: '[city.name2]',
                    title: 'город в род. подеже',
                    example: 'Уфы',
                },
                {
                    value: '[city.name3]',
                    title: 'город в дат. подеже',
                    example: 'Уфе',
                },
                {
                    value: '[city.name4]',
                    title: 'город в вин. подеже',
                    example: 'Уфу',
                },
                {
                    value: '[city.name5]',
                    title: 'город в твор. подеже',
                    example: 'Уфой',
                },
                {
                    value: '[city.name6]',
                    title: 'город в пред. подеже',
                    example: 'Уфе',
                },
            ],
        }
    ],
};

const replaceAll = function (target, search, replacement) {
    return target.split(search).join(replacement);
};

export function renderTpl(tpl, type) {
    let res = tpl;
    const parts = SEO_TPL_PARTS[type] || [];
    parts.forEach(part => {
        res = replaceAll(res, part.value, part.example);
        if (part.variants) {
            part.variants.forEach(subPart => {
                res = replaceAll(res, subPart.value, subPart.example);
            });
        }
    });
    return res;
}

const SeoTemplatePartsDropdown = ({ type, onChange }) => {
    const handleClick = (item) => {
        onChange(item);
    };

    const renderItems = () => {
        const parts = SEO_TPL_PARTS[type] || [];
        return (
            <Menu>
                {parts.map((item, idx) => {
                    if (item.variants) {
                        return (
                            <Menu.SubMenu key={`item-p${idx}`} title={item.title}>
                                <Menu.Item key={item.value} onClick={() => handleClick(item)}>
                                    {item.title}
                                </Menu.Item>
                                {item.variants.map((opt, idx2) => (
                                    <Menu.Item key={opt.value} onClick={() => handleClick(opt)}>
                                        {opt.title}
                                    </Menu.Item>
                                ))}
                            </Menu.SubMenu>
                        )
                    } else {
                        return (
                            <Menu.Item key={item.value} onClick={() => handleClick(item)}>{item.title}</Menu.Item>
                        )
                    }
                })}
            </Menu>
        )
    }

    return (
        <Dropdown 
            overlay={renderItems} 
            trigger={['click']} 
            placement="topLeft"
        >
            <PlusOutlined/>
        </Dropdown>
    );
}

export default SeoTemplatePartsDropdown;
