import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import { SimpleFileField } from 'components/common/fields';
import MainFieldSet from './MainFieldSet';


const TemplateForm = (props) => {
  const { showEditForm } = props.template;

  const onClose = () => {
    props.actions.template.showEditForm({ show: false });
  };

  const onSubmit = data => {
    data.tpl_file = SimpleFileField.getOriginFileObj(data.tpl_file);
    props.actions.template.update(data);
  };

  return (
    <Form
      title="Редактирование шаблона"
      name="templateForm"
      model={props.template}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({
        ...editObject,
        tpl_file: SimpleFileField.getFileList(
          editObject.tpl_file, 
          editObject.tpl_file_name
        ),
      })}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  template: state.template,
});

const mapDispatch = dispatch => ({
  actions: {
    template: dispatch.template,
  }
});

export default connect(mapState, mapDispatch)(TemplateForm);

