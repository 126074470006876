import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SeoTemplatePhotoImportForm = (props) => {
  const { showImportForm } = props.seoTemplatePhoto;

  const onClose = () => {
    props.actions.seoTemplatePhoto.showImportForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.seoTemplatePhoto.import(data);
  };

  return (
    <Form
      title="Копирование шаблонов из коллекции"
      name="seoTemplatePhotoImportForm"
      model={props.seoTemplatePhoto}
      visible={showImportForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({...editObject, count: 40})}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  seoTemplatePhoto: state.seoTemplatePhoto,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplatePhoto: dispatch.seoTemplatePhoto,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplatePhotoImportForm);

