import { BASE_URL } from "config";
import { api, getHeaders } from "utils";
import * as base from '../base';
import { push } from "connected-react-router";


const endpoints = {
  list: `${BASE_URL}/api/v1/domain/`,
  detail: (id) => `${BASE_URL}/api/v1/domain/${id}/`,
  history: (id) => `${BASE_URL}/api/v1/domain/${id}/history/`,
  makeMain: (id) => `${BASE_URL}/api/v1/domain/${id}/make_main/`,
};

export const domainListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const domainDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const domainUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const domainDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const domainHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });
export const domainMakeMain = (token, data) => api.Load('put', endpoints.makeMain(data.id), {}, {
  headers: getHeaders(token)
});


export const domain = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Домены',
    urls: {
      list: () => `/domain`,
      detail: (id) => `/domain/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'domain', domainListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'domain', domainDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncRequest(dispatch, payload, rootState, domainUpdate, {
        request: dispatch['domain'].updateRequest,
        complete: dispatch['domain'].updateComplete,
        error: dispatch['domain'].updateError,
      }, async (data) => {
        dispatch['domain'].showEditForm({ show: false });
        if (!payload.preventLoadDetail) {
          const id = data.parent ? data.parent : data.id;
          await dispatch['domain'].detailLoad({ id });
          dispatch(push(rootState['domain'].urls.detail(id)));
        } else {
          const filter = rootState['domain'].filter;
          await dispatch['domain'].listLoad(filter);
        }
      });
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'domain', domainDelete);
    },

    async makeMain(payload, rootState) {
      await base.asyncRequest(dispatch, payload, rootState, domainMakeMain, {
        request: dispatch['domain'].updateRequest,
        complete: dispatch['domain'].updateComplete,
        error: dispatch['domain'].updateError,
      }, async (data) => {
        dispatch['domain'].showEditForm({ show: false });
        if (!payload.preventLoadDetail) {
          const id = data.parent ? data.parent : data.id;
          await dispatch['domain'].detailLoad({ id });
          dispatch(push(rootState['domain'].urls.detail(id)));
        } else {
          const filter = rootState['domain'].filter;
          await dispatch['domain'].listLoad(filter);
        }
      });
    },
  }),
}