import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';
import ParametersFieldSet from './ParametersFieldSet';
import ContactsFieldSet from './ContactsFieldSet';
import ServicesFieldSet from './ServicesFieldSet';
import TariffFieldSet from './TariffFieldSet';


const ProfileForm = (props) => {
  const { showEditForm } = props.profile;

  const onClose = () => {
    props.actions.profile.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.profile.update(data);
  };

  const FormInner = (form, errorData) => (
    <Tabs className="tabs">
      <Tabs.TabPane tab="Основное" key="detail" forceRender={true}>
        <MainFieldSet form={form} errorData={errorData} {...props}/>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Параметры" key="parameters" forceRender={true}>
        <ParametersFieldSet form={form} errorData={errorData} {...props}/>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Контакты" key="contacts" forceRender={true}>
        <ContactsFieldSet form={form} errorData={errorData} {...props}/>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Услуги" key="service" forceRender={true}>
        <ServicesFieldSet form={form} errorData={errorData} {...props}/>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Тарифы" key="tariff" forceRender={true}>
        <TariffFieldSet form={form} errorData={errorData} {...props}/>
      </Tabs.TabPane>
    </Tabs>
  );

  return (
    <Form
      width={960}
      title="Редактирование анкеты"
      name="profileForm"
      model={props.profile}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({
        ...editObject, 
        city: editObject.city.id,
        districts: editObject.districts.map(item => item.id),
        sites: editObject.sites.map(item => item.id),
      })}
    >
      <FormInner/>
    </Form>
  )
};

const mapState = state => ({
  profile: state.profile,
});

const mapDispatch = dispatch => ({
  actions: {
    profile: dispatch.profile,
  }
});

export default connect(mapState, mapDispatch)(ProfileForm);

