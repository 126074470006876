import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { SeoTemplatePageDetail } from 'components/blocks/SeoTemplatePage';


const SeoTemplatePageDetailScreen = ({ match, actions }) => {
  useEffect(() => {
    actions.seoTemplatePage.detailLoad({ id: match.params.seoTemplatePageId });
  }, [match.params.seoTemplatePageId, actions.seoTemplatePage]);

  return (
    <SeoTemplatePageDetail />
  )
};

const mapState = state => ({
  seoTemplatePage: state.seoTemplatePage,
});

const mapDispatch = dispatch => ({
  actions: {
    seoTemplatePage: dispatch.seoTemplatePage,
  }
});

export default connect(mapState, mapDispatch)(SeoTemplatePageDetailScreen);
