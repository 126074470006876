import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from "antd";
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import { connect } from "react-redux";


const HeaderBreadcrumbs = ({ locationState, breadcrumbs }) => {
  const backUrl = locationState && locationState.backUrl;
  const backSearch = locationState && locationState.backSearch;

  return (
    <div className="header-breadcrumbs">
      {backUrl ?
        <Link
          className="back-btn"
          to={{ pathname: backUrl, search: backSearch, state: { slideOut: true } }}
          replace={true}
        >
          <ArrowLeftOutlined className="icon"/>
          <span className="text">назад</span>
        </Link>
        :
        breadcrumbs &&
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={{ pathname: '/', state: { slideOut: false } }}><HomeOutlined/><span className="arrow"/></Link>
          </Breadcrumb.Item>
          {breadcrumbs.map((item, idx) => (
            <Breadcrumb.Item key={`item${idx}`}>
              <Link to={{ pathname: item.path, state: { slideOut: item.isBack } }}>
                <span>{item.title || '-'}</span>
                <span className="arrow"/>
              </Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      }
    </div>
  );
};

const mapState = state => ({
  locationState: state.router.location.state,
});

export default connect(mapState)(HeaderBreadcrumbs);
