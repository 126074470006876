import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton } from 'components/common';
import { CityValue, CitySelect } from 'components/blocks/City';
import { TemplateValue, TemplateSelect } from 'components/blocks/Template';
import { ServerValue, ServerSelect } from 'components/blocks/Server';
import SiteValue from "../SiteValue";
import SitePublicationStatus from "../SitePublicationStatus"


const SiteList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, site }) => {
  const defaultColumnList = ['id', 'domain', 'city', 'server', 'template', 'publish_sucess_date', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    domain: tableColumn('Домен', 'domain', {
      render: (val, item) => {
        const hiddens = item.domain.hiddens.filter(item => item.is_main);
        return (
          <>
            <SiteValue detail={item} asLink={true} isBack={true} />
            {hiddens.length > 0 && 
              <div style={{ fontSize: '10px', color: 'rgba(255, 255, 255, .4)' }}>{hiddens[0].hidden_domain}</div>}
          </>
        )
      },
      sorter: true,
    }),
    city: tableColumn('Город', 'city', {
      render: (val, item) => <CityValue detail={val} asLink={true} isBack={true} />,
      sorter: false,
    }),
    server: tableColumn('Сервер', 'server', {
      render: (val, item) => (
        <>
          <ServerValue detail={item.domain.server} asLink={true} isBack={true} />
          <div style={{ fontSize: '10px', color: 'rgba(255, 255, 255, .4)' }}>{item.domain.server.ip_address}</div>
        </>
      ),
      sorter: false,
    }),
    template: tableColumn('Шаблон', 'template', {
      render: (val, item) => <TemplateValue detail={val} asLink={true} isBack={true} />,
      sorter: false,
    }),
    publish_sucess_date: tableColumn('Публикация', 'publish_sucess_date', {
      render: (val, item) => (val && <SitePublicationStatus site={item} />),
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.site}
          actions={[{ name: 'edit' }, { name: 'delete' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = site.filter.activeFilter
    }
    actions.site.listLoad({ query, activeFilter, locationChange: true });
  }, [site, actions]);

  return (
    <TableList
      location={router.location}
      model={site}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      filterFields={{
        list: [
          'is_publishing', 'city', 'template', 'server'
        ],
        byName: {
          'is_publishing': {
            label: 'В процессе публикации',
            type: 'bool',
          },
          'city': {
            label: 'Город',
            type: 'select_int',
            component: CitySelect,
            valueComponent: CityValue,
          },
          'template': {
            label: 'Шаблон',
            type: 'select_int',
            component: TemplateSelect,
            valueComponent: TemplateValue,
          },
          'server': {
            label: 'Сервер',
            type: 'select_int',
            component: ServerSelect,
            valueComponent: ServerValue,
          },
        }
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  site: state.site,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(SiteList);
